// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Box,
//   Button,
//   Input,
//   Select,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   Textarea,
//   SimpleGrid,
//   Flex,
//   Link,
//   Image,
// } from "@chakra-ui/react";
// import apiInstance from 'api/api';
// import { useHistory } from "react-router-dom";

// const ProgressTab = () => {
//   const [progressDetails, setProgressDetails] = useState({
//     progress_type: '',
//     remarks: '',
//     attachments: [],
//   });
//   const [progressList, setProgressList] = useState([]);
//   const [attachmentPreviews, setAttachmentPreviews] = useState([]);
//   const history = useHistory();
//  // const //toast = useToast();

//   useEffect(() => {
//     fetchProgressList();
//   }, []);

//   const fetchProgressList = async () => {
//     const orderId=localStorage.getItem("orderId")||1;
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/progress`);
//       const progress = response.data.data.progress || [];
//       setProgressList(progress);
//       console.log("Fetched progress list", progress);
//     } catch (error) {
//       console.error("Error fetching progress list", error);
//      //({ title: "Error fetching progress list.", status: "error" });
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value, files } = e.target;
//     if (name === "attachments") {
//       const fileArray = Array.from(files);
//       setProgressDetails((prevDetails) => ({
//         ...prevDetails,
//         attachments: fileArray,
//       }));

//       const previewUrls = fileArray.map(file => URL.createObjectURL(file));
//       setAttachmentPreviews(previewUrls);
//     } else {
//       setProgressDetails((prevDetails) => ({
//         ...prevDetails,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     for (const key in progressDetails) {
//       if (key === "attachments") {
//         progressDetails.attachments.forEach(file => formData.append("attachments[]", file));
//       } else {
//         formData.append(key, progressDetails[key]);
//       }
//     }
//     const orderId=localStorage.getItem("orderId")||1;
//     try {
//       await apiInstance.post(`/orders/${orderId}/progress`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       ////toast({ title: "Details submitted successfully.", status: "success" });
//       setProgressDetails({
//         progress_type: '',
//         remarks: '',
//         attachments: [],
//       });
//       setAttachmentPreviews([]);
//       fetchProgressList(); // Refresh the progress list after submission
//     } catch (error) {
//       console.error("Error submitting details", error);
//      // //toast({ title: "Error submitting details.", status: "error" });
//     }
//   };

//   const renderAttachmentPreview = () => {
//     return attachmentPreviews.map((preview, index) => {
//       const file = progressDetails.attachments[index];
//       if (!file) return null;
      
//       const fileExtension = file.name.split('.').pop().toLowerCase();
//       if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//         return <Image key={index} src={preview} alt="Attachment Preview" mt={2} />;
//       } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//         return (
//           <Link key={index} href={preview} isExternal mt={2}>
//             Open {file.name}
//           </Link>
//         );
//       } else {
//         return <Text key={index} mt={2}>Unsupported file type: {file.name}</Text>;
//       }
//     });
//   };

//   const renderProgressAttachments = (attachments) => {
//     return attachments.map((attachment) => {
//       const fileExtension = attachment.url.split('.').pop().toLowerCase();
//       if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//         return <Image key={attachment.id} src={attachment.url} alt="Attachment" mt={2} />;
//       } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//         return (
//           <Link key={attachment.id} href={attachment.url} isExternal mt={2}>
//             Open {attachment.url.split('/').pop()}
//           </Link>
//         );
//       } else {
//         return <Text key={attachment.id} mt={2}>Unsupported file type: {attachment.url.split('/').pop()}</Text>;
//       }
//     });
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Progress Tab
//       </Text>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Type</FormLabel>
//             <Select
//               name="progress_type"
//               value={progressDetails.progress_type}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Type</option>
//               <option value="PP SAMPLE">PP SAMPLE</option>
//               <option value="fit sample">fit sample</option>
//             </Select>
//           </FormControl>

//           <FormControl>
//             <FormLabel>Remarks</FormLabel>
//             <Textarea
//               name="remarks"
//               value={progressDetails.remarks}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Attachments</FormLabel>
//             <Input
//               type="file"
//               name="attachments"
//               multiple
//               onChange={handleInputChange}
//             />
//             {renderAttachmentPreview()}
//           </FormControl>
//         </SimpleGrid>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>

//       <Box mt={8}>
//         <Text fontSize="xl" fontWeight="bold" mb={4}>
//           Progress List
//         </Text>
//         <Stack spacing={4}>
//           {progressList.map((progress, index) => (
//             <Flex
//               key={index}
//               p={4}
//               bg="gray.100"
//               borderRadius="md"
//               justifyContent="space-between"
//               alignItems="center"
//             >
//               <Box>
//                 <Text fontWeight="bold">Communication By: {progress.communication_by}</Text>
//                 <Text>Time: {progress.time}</Text>
//                 <Text>Type: {progress.progress_type}</Text>
//                 <Text>Remarks: {progress.remarks}</Text>
//                 {progress.attachments && renderProgressAttachments(progress.attachments)}
//               </Box>
//             </Flex>
//           ))}
//         </Stack>
//       </Box>
//     </Box>
//   );
// };

// export default ProgressTab;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Select,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  SimpleGrid,
  Flex,
  Link,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import apiInstance from 'api/api';
import { useHistory } from "react-router-dom";

const ProgressTab = () => {
  const [progressDetails, setProgressDetails] = useState({
    progress_type: '',
    remarks: '',
    attachments: [],
  });
  const [progressTypes, setProgressTypes] = useState([]); // State for progress types
  const [progressList, setProgressList] = useState([]);
  const [attachmentPreviews, setAttachmentPreviews] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const cancelRef = React.useRef();
  const history = useHistory();

  useEffect(() => {
    const currentOrderId = localStorage.getItem("currentOrderId");
    if (currentOrderId) {
      fetchProgressList(currentOrderId);
    }
    fetchProgressTypes(); // Fetch progress types
  }, []);

  const fetchProgressList = async (orderId) => {
    try {
      const response = await apiInstance.get(`/orders/${orderId}/progress`);
      const progress = response.data.data.progress || [];
      setProgressList(progress);
      console.log("Fetched progress list", progress);
    } catch (error) {
      console.error("Error fetching progress list", error);
      showAlert("Error fetching progress list.");
    }
  };

  const fetchProgressTypes = async () => {
    try {
      const response = await apiInstance.get("/progress-types");
      const types = response.data.data["progress-types"] || [];
      setProgressTypes(types);
      console.log("Fetched progress types", types);
    } catch (error) {
      console.error("Error fetching progress types", error);
      showAlert("Error fetching progress types.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "attachments") {
      const fileArray = Array.from(files);
      setProgressDetails((prevDetails) => ({
        ...prevDetails,
        attachments: fileArray,
      }));

      const previewUrls = fileArray.map(file => URL.createObjectURL(file));
      setAttachmentPreviews(previewUrls);
    } else {
      setProgressDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const currentOrderId = localStorage.getItem("currentOrderId") || 1;

    for (const key in progressDetails) {
      if (key === "attachments") {
        progressDetails.attachments.forEach(file => formData.append("attachments[]", file));
      } else {
        formData.append(key, progressDetails[key]);
      }
    }

    try {
      await apiInstance.post(`/orders/${currentOrderId}/progress`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      showAlert("Details submitted successfully.");
      setProgressDetails({
        progress_type: '',
        remarks: '',
        attachments: [],
      });
      setAttachmentPreviews([]);
      fetchProgressList(currentOrderId); // Refresh the progress list after submission
    } catch (error) {
      console.error("Error submitting details", error);
      showAlert("Error submitting details.");
    }
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertOpen(true);
  };

  const renderAttachmentPreview = () => {
    return attachmentPreviews.map((preview, index) => {
      const file = progressDetails.attachments[index];
      if (!file) return null;

      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
        return <Image key={index} src={preview} alt="Attachment Preview" mt={2} />;
      } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
        return (
          <Link key={index} href={preview} isExternal mt={2}>
            Open {file.name}
          </Link>
        );
      } else {
        return <Text key={index} mt={2}>Unsupported file type: {file.name}</Text>;
      }
    });
  };

  const renderProgressAttachments = (attachments) => {
    return attachments.map((attachment) => {
      const fileExtension = attachment.url.split('.').pop().toLowerCase();
      if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
        return <Image key={attachment.id} src={attachment.url} alt="Attachment" mt={2} />;
      } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
        return (
          <Link key={attachment.id} href={attachment.url} isExternal mt={2}>
            Open {attachment.url.split('/').pop()}
          </Link>
        );
      } else {
        return <Text key={attachment.id} mt={2}>Unsupported file type: {attachment.url.split('/').pop()}</Text>;
      }
    });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "80px" }}>
      {/* <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Progress Tab
      </Text> */}
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <FormControl isRequired>
            <FormLabel>Type</FormLabel>
            <Select
              name="progress_type"
              value={progressDetails.progress_type}
              onChange={handleInputChange}
            >
              <option value="">Select Type</option>
              {progressTypes.map(type => (
                <option key={type.id} value={type.name}>
                  {type.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Remarks</FormLabel>
            <Textarea
              name="remarks"
              value={progressDetails.remarks}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Attachments</FormLabel>
            <Input
              type="file"
              name="attachments"
              multiple
              onChange={handleInputChange}
            />
            {renderAttachmentPreview()}
          </FormControl>
        </SimpleGrid>

        <Box textAlign="center" mt={4}>
          <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
            Submit
          </Button>
        </Box>
      </form>

      <Box mt={8}>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Progress List
        </Text>
        <Stack spacing={4}>
          {progressList.map((progress, index) => (
            <Flex
              key={index}
              p={4}
              bg="gray.100"
              borderRadius="md"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Text fontWeight="bold">Communication By: {progress.communication_by}</Text>
                <Text>Time: {progress.time}</Text>
                <Text>Type: {progress.progress_type}</Text>
                <Text>Remarks: {progress.remarks}</Text>
                {progress.attachments && renderProgressAttachments(progress.attachments)}
              </Box>
            </Flex>
          ))}
        </Stack>
      </Box>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Alert
            </AlertDialogHeader>
            <AlertDialogBody>
              {alertMessage}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ProgressTab;
