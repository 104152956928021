import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Spinner,
} from "@chakra-ui/react";
import apiInstance from "api/api";

const ProgressTypesMaster = () => {
  const [Progresss, setProgresss] = useState([]);
  const [currentProgress, setCurrentProgress] = useState(null);
  const [alertContent, setAlertContent] = useState({ status: "", message: "" });
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef();

  useEffect(() => {
    fetchProgresss();
  }, []);

  const fetchProgresss = async () => {
    setIsLoading(true);
    try {
      const response = await apiInstance.get("/progress-types");
      const Progress = response.data.data["progress-types"] || [];
      setProgresss(Progress);
      console.log("response", Progress);
    } catch (error) {
      console.error("Error fetching Progresss", error);
      showAlert("error", "Error fetching Progresss.");
    } finally {
      setIsLoading(false);
    }
  };

  const showAlert = (status, message) => {
    setAlertContent({ status, message });
    onAlertOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentProgress((prevProgress) => ({
      ...prevProgress,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newProgress = { name: currentProgress.name };

    try {
      if (currentProgress.id) {
        await apiInstance.put(`/progress-types/${currentProgress.id}`, newProgress);
        showAlert("success", "Progress updated successfully.");
      } else {
        await apiInstance.post("/progress-types", newProgress);
        showAlert("success", "Progress added successfully.");
      }
      fetchProgresss();
      setCurrentProgress(null);
      onClose();
    } catch (error) {
      console.error("Error saving Progress", error);
      showAlert("error", "Error saving Progress.");
    }
  };

  const handleAdd = () => {
    setCurrentProgress(null);
    onOpen();
  };

  const handleEdit = (Progress) => {
    setCurrentProgress(Progress);
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      await apiInstance.delete(`/progress-types/${id}`);
      showAlert("success", "Progress deleted successfully.");
      fetchProgresss();
    } catch (error) {
      console.error("Error deleting Progress", error);
      showAlert("error", "Error deleting Progress.");
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="2xl" fontWeight="bold">
            Progress Types Master
          </Text>
          <Button colorScheme="teal" onClick={handleAdd}>
            Add 
          </Button>
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Stack spacing={4}>
            {Array.isArray(Progresss) &&
              Progresss.map((Progress) => (
                <Flex
                  key={Progress.id}
                  justifyContent="space-between"
                  alignItems="center"
                  p={4}
                  bg="gray.100"
                  borderRadius="md"
                >
                  <Box>
                    <Text fontWeight="bold">Name: {Progress.name}</Text>
                  </Box>
                  <Flex>
                    <Button
                      colorScheme="blue"
                      mr={2}
                      onClick={() => handleEdit(Progress)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(Progress.id)}
                    >
                      Delete
                    </Button>
                  </Flex>
                </Flex>
              ))}
          </Stack>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {currentProgress && currentProgress.id
                ? "Edit"
                : "Add"}
            </ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <FormControl isRequired mb={3}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    name="name"
                    value={currentProgress?.name || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  {currentProgress && currentProgress.id ? "Update" : "Submit"}
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {alertContent.status === "error" ? "Error" : "Success"}
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>{alertContent.message}</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default ProgressTypesMaster;
