// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Flex,
//   Input,
//   Stack,
//   Text,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   FormControl,
//   FormLabel,
//   AlertDialog,
//   AlertDialogBody,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogContent,
//   AlertDialogOverlay,
//   AlertDialogCloseButton,
//   Spinner,
// } from "@chakra-ui/react";
// import apiInstance from "api/api";

// const AgentMaster = () => {
//   const [agents, setAgents] = useState([]);
//   const [currentAgent, setCurrentAgent] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const {
//     isOpen: isAlertOpen,
//     onOpen: onAlertOpen,
//     onClose: onAlertClose,
//   } = useDisclosure();
//   const [alertContent, setAlertContent] = useState("");
//   const cancelRef = React.useRef();

//   useEffect(() => {
//     fetchAgents();
//   }, []);

//   const fetchAgents = async () => {
//     setIsLoading(true);
//     try {
//       const response = await apiInstance.get("/agents");
//       const agents = response.data.data.agents || [];
//       setAgents(agents);
//       console.log("response", agents);
//     } catch (error) {
//       console.error("Error fetching agents", error);
//       showAlert("Error fetching agents.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const showAlert = (content) => {
//     setAlertContent(content);
//     onAlertOpen();
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const { name, password, email, code } = e.target.elements;
//     if (password.value.length < 8) {
//       showAlert("Password must be at least 8 characters long.");
//       return;
//     }
//     const newAgent = {
//       name: name.value,
//       password: password.value,
//       email: email.value,
//       agent_code: code.value,
//     };


//     try {
//       if (currentAgent) {
//         await apiInstance.put(`/agents/${currentAgent.id}`, newAgent);
//         showAlert("Agent updated successfully.");
//       } else {
//         await apiInstance.post("/agents", newAgent);
//         showAlert("Agent added successfully.");
//       }
//       fetchAgents();
//       setCurrentAgent(null);
//       onClose();
//     } catch (error) {
//       console.error("Error saving agent", error);
//       showAlert(error.response.data.message);
//     }
//   };

//   const handleEdit = (agent) => {
//     setCurrentAgent(agent);
//     onOpen();
//   };

//   const handleDelete = async (id) => {
//     try {
//       await apiInstance.delete(`/agents/${id}`);
//       showAlert("Agent deleted successfully.");
//       fetchAgents();
//     } catch (error) {
//       console.error("Error deleting agent", error);
//       showAlert("Error deleting agent.");
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <Box p={4}>
//         <Flex justifyContent="space-between" alignItems="center" mb={4}>
//           <Text fontSize="2xl" fontWeight="bold">
//             Agent Master
//           </Text>
//           <Button colorScheme="teal" onClick={onOpen}>
//             Add Agent
//           </Button>
//         </Flex>

//         {isLoading ? (
//           <Flex justifyContent="center" alignItems="center" height="200px">
//             <Spinner size="xl" />
//           </Flex>
//         ) : (
//           <Stack spacing={4}>
//             {Array.isArray(agents) &&
//               agents.map((agent) => (
//                 <Flex
//                   key={agent.id}
//                   justifyContent="space-between"
//                   alignItems="center"
//                   p={4}
//                   bg="gray.100"
//                   borderRadius="md"
//                 >
//                   <Box>
//                     <Text fontWeight="bold">Name: {agent.name}</Text>
//                     <Text>Email: {agent.email}</Text>
//                     <Text>Agent Code: {agent.agent_code}</Text>
//                   </Box>
//                   <Flex>
//                     <Button
//                       colorScheme="blue"
//                       mr={2}
//                       onClick={() => handleEdit(agent)}
//                     >
//                       Edit
//                     </Button>
//                     <Button
//                       colorScheme="red"
//                       onClick={() => handleDelete(agent.id)}
//                     >
//                       Delete
//                     </Button>
//                   </Flex>
//                 </Flex>
//               ))}
//           </Stack>
//         )}

//         <Modal isOpen={isOpen} onClose={onClose}>
//           <ModalOverlay />
//           <ModalContent>
//             <ModalHeader>
//               {currentAgent ? "Edit Agent" : "Add Agent"}
//             </ModalHeader>
//             <ModalCloseButton />
//             <form onSubmit={handleSubmit}>
//               <ModalBody>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Username</FormLabel>
//                   <Input name="name" defaultValue={currentAgent?.name || ""} />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Password</FormLabel>
//                   <Input
//                     type="password"
//                     name="password"
//                     defaultValue={currentAgent?.password || ""}
//                   />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Email</FormLabel>
//                   <Input name="email" defaultValue={currentAgent?.email || ""} />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Code</FormLabel>
//                   <Input name="code" defaultValue={currentAgent?.code || ""} />
//                 </FormControl>
//               </ModalBody>
//               <ModalFooter>
//                 <Button colorScheme="blue" mr={3} type="submit">
//                   {currentAgent ? "Update" : "Submit"}
//                 </Button>
//                 <Button variant="ghost" onClick={onClose}>
//                   Cancel
//                 </Button>
//               </ModalFooter>
//             </form>
//           </ModalContent>
//         </Modal>

//         <AlertDialog
//           isOpen={isAlertOpen}
//           leastDestructiveRef={cancelRef}
//           onClose={onAlertClose}
//         >
//           <AlertDialogOverlay>
//             <AlertDialogContent>
//               <AlertDialogHeader fontSize="lg" fontWeight="bold">
//                 Alert
//               </AlertDialogHeader>
//               <AlertDialogCloseButton />
//               <AlertDialogBody>{alertContent}</AlertDialogBody>
//               <AlertDialogFooter>
//                 <Button ref={cancelRef} onClick={onAlertClose}>
//                   OK
//                 </Button>
//               </AlertDialogFooter>
//             </AlertDialogContent>
//           </AlertDialogOverlay>
//         </AlertDialog>
//       </Box>
//     </Box>
//   );
// };

// export default AgentMaster;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Checkbox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Spinner,
} from "@chakra-ui/react";
import apiInstance from "api/api";

const AgentMaster = () => {
  const [agents, setAgents] = useState([]);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [currentAgentId,setCurrentAgentId]=useState(null);
  const [permissions, setPermissions] = useState(null); // For permission dialog
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const {
    isOpen: isPermissionOpen,
    onOpen: onPermissionOpen,
    onClose: onPermissionClose,
  } = useDisclosure(); // Permission dialog control
  const [alertContent, setAlertContent] = useState("");
  const cancelRef = React.useRef();
  const role = localStorage.getItem("role");
  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    setIsLoading(true);
    try {
      const response = await apiInstance.get("/agents");
      const agents = response.data.data.agents || [];
      setAgents(agents);
    } catch (error) {
      console.error("Error fetching agents", error);
      showAlert("Error fetching agents.");
    } finally {
      setIsLoading(false);
    }
  };

  const showAlert = (content) => {
    setAlertContent(content);
    onAlertOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, password, email, code } = e.target.elements;
    if (password.value.length < 8) {
      showAlert("Password must be at least 8 characters long.");
      return;
    }
    const newAgent = {
      name: name.value,
      password: password.value,
      email: email.value,
      agent_code: code.value,
    };

    try {
      if (currentAgent) {
        await apiInstance.put(`/agents/${currentAgent.id}`, newAgent);
        showAlert("Agent updated successfully.");
      } else {
        await apiInstance.post("/agents", newAgent);
        showAlert("Agent added successfully.");
      }
      fetchAgents();
      setCurrentAgent(null);
      onClose();
    } catch (error) {
      console.error("Error saving agent", error);
      showAlert(error.response.data.message);
    }
  };

  const handleEdit = (agent) => {
    setCurrentAgent(agent);
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      await apiInstance.delete(`/agents/${id}`);
      showAlert("Agent deleted successfully.");
      fetchAgents();
    } catch (error) {
      console.error("Error deleting agent", error);
      showAlert("Error deleting agent.");
    }
  };

  const handleAddPermission = async (agentId) => {
    try {
      setCurrentAgentId(agentId)
      const response = await apiInstance.get(`/permissions?user_id=${agentId}`);
      setPermissions(response.data.data.permissions);
      onPermissionOpen();
    } catch (error) {
      console.error("Error fetching permissions", error);
      showAlert("Error fetching permissions.");
    }
  };

  // const handleSavePermissions = () => {
  //   const checkedPermissions = [];

  //   const findCheckedPermissions = (permissionsObj, parentKey = "") => {
  //     Object.keys(permissionsObj).forEach((category) => {
  //       Object.keys(permissionsObj[category]).forEach((permissionType) => {
  //         const permission = permissionsObj[category][permissionType];
  //         if (permission.has) {
  //           checkedPermissions.push(`${parentKey}${permission.name}`);
  //         }
  //         if (typeof permission === "object" && !Array.isArray(permission)) {
  //           findCheckedPermissions({ [permissionType]: permission }, `${category}.`);
  //         }
  //       });
  //     });
  //   };

  //   findCheckedPermissions(permissions);
  //   console.log("Checked Permissions:", checkedPermissions);
  //   onPermissionClose();
  // };

  // const handleSavePermissions = () => {
  //   const checkedPermissions = [];
  
  //   const findCheckedPermissions = (permissionsObj) => {
  //     Object.values(permissionsObj).forEach((value) => {
  //       if (value && typeof value.has === "boolean") {
  //         if (value.has) {
  //           checkedPermissions.push(value.name);
  //         }
  //       } else if (typeof value === "object") {
  //         findCheckedPermissions(value);
  //       }
  //     });
  //   };
  
  //   findCheckedPermissions(permissions);
  
  //   console.log("Checked Permissions:", checkedPermissions);
  //   onPermissionClose();
  // };

  const handleSavePermissions = async () => {
    const checkedPermissions = [];
    //const userId = currentAgent?.id; // Assuming the user_id is the current agent's id
    const userId=currentAgentId
    console.log("agent",currentAgent)
    if (!userId) {
      showAlert("User ID is missing. Cannot proceed.");
      return;
    }
  
    // Recursive function to collect checked permissions
    const findCheckedPermissions = (permissionsObj, parentKey = "") => {
      Object.entries(permissionsObj).forEach(([key, value]) => {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;
  
        if (value && typeof value.has === "boolean" && value.has) {
          // Push the full path of the permission if it is checked
          checkedPermissions.push(currentKey);
        } else if (typeof value === "object") {
          // Recursively check nested permissions
          findCheckedPermissions(value, currentKey);
        }
      });
    };
  
    // Collect checked permissions
    findCheckedPermissions(permissions);
  
    // Construct payload with user_id and permissions (plain array of strings)
    const payload = {
      user_id: userId,
      permissions: checkedPermissions, // Send an array of strings
    };
  
    console.log("Payload to send:", payload);
  
    try {
      // Send the payload to the server
      await apiInstance.post("/permissions", payload);
      showAlert("Permissions updated successfully.");
    } catch (error) {
      console.error("Error updating permissions", error);
      showAlert("Error updating permissions.");
    }
  
    onPermissionClose();
  };
  
  // const handlePermissionChange = (category, permissionType) => {
  //   setPermissions((prevPermissions) => ({
  //     ...prevPermissions,
  //     [category]: {
  //       ...prevPermissions[category],
  //       [permissionType]: {
  //         ...prevPermissions[category][permissionType],
  //         has: !prevPermissions[category][permissionType].has,
  //       },
  //     },
  //   }));
  // };
  const handlePermissionChange = (fullKey) => {
    setPermissions((prevPermissions) => {
      const keys = fullKey.split(".");
      const updatedPermissions = { ...prevPermissions };
      let currentLevel = updatedPermissions;
  
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (i === keys.length - 1) {
          // Toggle the 'has' value at the deepest level
          currentLevel[key] = {
            ...currentLevel[key],
            has: !currentLevel[key].has,
          };
        } else {
          // Ensure each level is a new object to maintain immutability
          currentLevel[key] = { ...currentLevel[key] };
          currentLevel = currentLevel[key];
        }
      }
  
      return updatedPermissions;
    });
  };
  
  
  
  

  // Recursively render permissions
  // const renderPermissions = (permissionsObj) => {
  //   return Object.keys(permissionsObj).map((category) => (
  //     <Box key={category} mb={4}>
  //       <Text fontWeight="bold">{category}</Text>
  //       {Object.keys(permissionsObj[category]).map((permissionType) => {
  //         const permission = permissionsObj[category][permissionType];
  //         return typeof permission.has !== "undefined" ? (
  //           <FormControl key={permissionType} display="flex" alignItems="center" mb={2}>
  //             <Checkbox
  //               isChecked={permission.has}
  //               onChange={() => handlePermissionChange(category, permissionType)}
  //             >
  //               {permission.name}
  //             </Checkbox>
  //           </FormControl>
  //         ) : (
  //           <Box pl={4} key={permissionType}>
  //             {renderPermissions({ [permissionType]: permission })}
  //           </Box>
  //         );
  //       })}
  //     </Box>
  //   ));
  // };
  // Recursively render permissions
  const renderPermissions = (permissionsObj, parentKey = "") => {
    return Object.entries(permissionsObj).map(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
  
      if (value && typeof value.has === "boolean") {
        // Base case: permission with 'has' property
        return (
          <FormControl key={currentKey} display="flex" alignItems="center" mb={2}>
            <Checkbox
              isChecked={value.has}
              onChange={() => handlePermissionChange(currentKey)}
            >
              {value.name}
            </Checkbox>
          </FormControl>
        );
      } else if (typeof value === "object") {
        // Recursive case: permission category or nested permissions
        return (
          <Box key={currentKey} pl={4} mb={4}>
            <Text fontWeight="bold">{key}</Text>
            {renderPermissions(value, currentKey)}
          </Box>
        );
      } else {
        return null; // In case the structure doesn't match expectations
      }
    });
  };
  
  
  


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="2xl" fontWeight="bold">
            Agent Master
          </Text>
          <Button colorScheme="teal" onClick={onOpen}>
            Add Agent
          </Button>
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Stack spacing={4}>
            {Array.isArray(agents) &&
              agents.map((agent) => (
                <Flex
                  key={agent.id}
                  justifyContent="space-between"
                  alignItems="center"
                  p={4}
                  bg="gray.100"
                  borderRadius="md"
                >
                  <Box>
                    <Text fontWeight="bold">Name: {agent.name}</Text>
                    <Text>Email: {agent.email}</Text>
                    <Text>Agent Code: {agent.agent_code}</Text>
                  </Box>
                  <Flex>
                    <Button
                      colorScheme="blue"
                      mr={2}
                      onClick={() => handleEdit(agent)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme="red"
                      mr={2}
                      onClick={() => handleDelete(agent.id)}
                    >
                      Delete
                    </Button>
                    {role=="SUPERADMIN"&&
                    <Button
                      colorScheme="green"
                      onClick={() => handleAddPermission(agent.id)}
                    >
                      Add Permission
                    </Button>
}
                  </Flex>
                </Flex>
              ))}
          </Stack>
        )}

        {/* Add/Edit Agent Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {currentAgent ? "Edit Agent" : "Add Agent"}
            </ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <FormControl isRequired mb={3}>
                  <FormLabel>Username</FormLabel>
                  <Input name="name" defaultValue={currentAgent?.name || ""} />
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    name="password"
                    defaultValue={currentAgent?.password || ""} // This line should be checked as passwords should not be stored in plain text
                  />
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel>Email</FormLabel>
                  <Input name="email" defaultValue={currentAgent?.email || ""} />
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel>Code</FormLabel>
                  <Input name="code" defaultValue={currentAgent?.agent_code || ""} />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  {currentAgent ? "Update" : "Submit"}
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        {/* Permission Modal */}
        <Modal isOpen={isPermissionOpen} onClose={onPermissionClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Manage Permissions</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {permissions ? renderPermissions(permissions) : <Text>Loading...</Text>}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSavePermissions}>
                Save
              </Button>
              <Button variant="ghost" onClick={onPermissionClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Alert Dialog */}
        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Alert
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>{alertContent}</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default AgentMaster;
