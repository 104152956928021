// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   SimpleGrid,
//   Textarea,
// } from "@chakra-ui/react";

// const CustomerTeckPack = () => {
//   const [techPackDetails, setTechPackDetails] = useState({
//     initialPendingVerification: '',
//     initialVerificationBy: '',
//     printFileVerification: '',
//     printFileVerificationBy: '',
//     designFileVerification: '',
//     designFileVerificationBy: '',
//     sizeGuideVerification: '',
//     sizeGuideVerificationBy: '',
//     remarks: '',
//   });
//   const //toast = useToast();

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setTechPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(techPackDetails);
//     //toast({ title: "Details submitted.", status: "success" });
//     // Add logic to save the details
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Customer Teck Pack
//       </Text>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Customer Tech Pack</FormLabel>
//             <Input
//               type="text"
//               name="initialPendingVerification"
//               value={techPackDetails.initialPendingVerification}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Verified By</FormLabel>
//             <Input
//               type="text"
//               name="initialVerificationBy"
//               value={techPackDetails.initialVerificationBy}
//               onChange={handleInputChange}
//             />
//           </FormControl>



//           <FormControl isRequired>
//             <FormLabel>Print File</FormLabel>
//             <Input
//               type="text"
//               name="printFileVerification"
//               value={techPackDetails.printFileVerification}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Verified By</FormLabel>
//             <Input
//               type="text"
//               name="printFileVerificationBy"
//               value={techPackDetails.printFileVerificationBy}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Design File </FormLabel>
//             <Input
//               type="text"
//               name="designFileVerification"
//               value={techPackDetails.designFileVerification}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Verified By</FormLabel>
//             <Input
//               type="text"
//               name="designFileVerificationBy"
//               value={techPackDetails.designFileVerificationBy}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Size Guide</FormLabel>
//             <Input
//               type="text"
//               name="sizeGuideVerification"
//               value={techPackDetails.sizeGuideVerification}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Verified By</FormLabel>
//             <Input
//               type="text"
//               name="sizeGuideVerificationBy"
//               value={techPackDetails.sizeGuideVerificationBy}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Remarks</FormLabel>
//             <Textarea
//               name="remarks"
//               value={techPackDetails.remarks}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default CustomerTeckPack;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   SimpleGrid,
//   Textarea,
//   Select,
//   Image,
//   AspectRatio,
//   Grid,
//   GridItem,
//   Link,
// } from "@chakra-ui/react";
// import apiInstance from 'api/api';
// import { useHistory } from "react-router-dom";

// const CustomerTeckPack = () => {
//   const [techPackDetails, setTechPackDetails] = useState({
//     customer_tech_pack: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       verified_by_id: '',
//       comments: '',
//     },
//     print: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       verified_by_id: '',
//       comments: '',
//     },
//     design: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       verified_by_id: '',
//       comments: '',
//     },
//     size_guide: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       verified_by_id: '',
//       comments: '',
//     },
//     remarks: '',
//   });
//   const [users, setUsers] = useState([]);
//   //const //toast = useToast();
//   const history = useHistory();
//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = async () => {
//     try {
//       const response = await apiInstance.get("/employees");
//       const agents = response.data.data.employees || [];
//       setUsers(agents);
//       console.log("response", agents);
//     } catch (error) {
//       console.error("Error fetching users", error);
//       // //toast({ title: "Error fetching users.", status: "error" });
//     }
//   };

//   const handleInputChange = (e, section) => {
//     const { name, value } = e.target;
//     setTechPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [section]: {
//         ...prevDetails[section],
//         [name]: value,
//       },
//     }));
//   };

//   const handleFileChange = (e, section) => {
//     const { files } = e.target;
//     const attachment = files[0];
//     const filePreview = attachment ? URL.createObjectURL(attachment) : null;
//     setTechPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [section]: {
//         ...prevDetails[section],
//         attachment,
//         fileName: attachment.name,
//         filePreview,
//       },
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     const orderId=localStorage.getItem("orderId")||1;
//     for (const section in techPackDetails) {
//       if (section === 'remarks') {
//         formData.append(section, techPackDetails[section]);
//       } else {
//         for (const key in techPackDetails[section]) {
//           if (key !== 'filePreview' && key !== 'fileName') {
//             formData.append(`${section}[${key}]`, techPackDetails[section][key]);
//           }
//         }
//       }
//     }

//     try {
//       await apiInstance.post(`/orders/${orderId}/customer-tech-pack`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       // //toast({ title: "Details submitted successfully.", status: "success" });
//       // Reset form after submission
//       setTechPackDetails({
//         customer_tech_pack: {
//           attachment: null,
//           fileName: '',
//           filePreview: null,
//           verified_by_id: '',
//           comments: '',
//         },
//         print: {
//           attachment: null,
//           fileName: '',
//           filePreview: null,
//           verified_by_id: '',
//           comments: '',
//         },
//         design: {
//           attachment: null,
//           fileName: '',
//           filePreview: null,
//           verified_by_id: '',
//           comments: '',
//         },
//         size_guide: {
//           attachment: null,
//           fileName: '',
//           filePreview: null,
//           verified_by_id: '',
//           comments: '',
//         },
//         remarks: '',
//       });
//       history.push("/admin/create-order/samshek-teck-pack");
//     } catch (error) {
//       console.error("Error submitting details", error);
//       // //toast({ title: "Error submitting details.", status: "error" });
//     }
//   };

//   const renderAttachmentPreview = (filePreview, fileName) => {
//     if (!filePreview) return null;

//     const fileExtension = fileName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={filePreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={filePreview} isExternal mt={2}>
//           Open {fileName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported attachment type: {fileName}</Text>;
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Customer Teck Pack
//       </Text>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
//           {['customer_tech_pack', 'print', 'design', 'size_guide'].map(section => (
//             <>
//               <GridItem colSpan={1} key={`${section}-attachment`}>
//                 <FormControl isRequired>
//                   <FormLabel>{section === 'customer_tech_pack' ? 'Customer Tech Pack' : section.replace(/([A-Z])/g, ' $1').trim()}</FormLabel>
//                   <Input
//                     type="file"
//                     name="attachment"
//                     accept=".pdf, .jpg, .jpeg, .png"
//                     onChange={(e) => handleFileChange(e, section)}
//                   />
//                   {renderAttachmentPreview(techPackDetails[section].filePreview, techPackDetails[section].fileName)}
//                 </FormControl>
//               </GridItem>
//               <GridItem colSpan={1} key={`${section}-verified_by_id`}>
//                 <FormControl isRequired>
//                   <FormLabel>Verified By</FormLabel>
//                   <Select
//                     name="verified_by_id"
//                     value={techPackDetails[section].verified_by_id}
//                     onChange={(e) => handleInputChange(e, section)}
//                   >
//                     <option value="">Select User</option>
//                     {users.map(user => (
//                       <option key={user.id} value={user.id}>
//                         {user.name}
//                       </option>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </GridItem>
//               <GridItem colSpan={1} key={`${section}-comments`}>
//                 <FormControl>
//                   <FormLabel>comments</FormLabel>
//                   <Textarea
//                     name="comments"
//                     value={techPackDetails[section].comments}
//                     onChange={(e) => handleInputChange(e, section)}
//                   />
//                 </FormControl>
//               </GridItem>
//             </>
//           ))}
//           <GridItem colSpan={3}>
//             <FormControl>
//               <FormLabel>Remarks</FormLabel>
//               <Textarea
//                 name="remarks"
//                 value={techPackDetails.remarks}
//                 onChange={(e) => handleInputChange(e, 'remarks')}
//               />
//             </FormControl>
//           </GridItem>
//         </SimpleGrid>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default CustomerTeckPack;

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   FormControl,
//   FormLabel,
//   Textarea,
//   SimpleGrid,
//   Image,
//   Link,
//   Grid,
//   GridItem,
//   AlertDialog,
//   AlertDialogBody,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogContent,
//   AlertDialogOverlay,
//   useDisclosure
// } from "@chakra-ui/react";
// import apiInstance from 'api/api';
// import { useHistory } from "react-router-dom";

// const CustomerTeckPack = () => {
//   const [techPackDetails, setTechPackDetails] = useState({
//     customer_tech_pack: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       comments: '',
//     },
//     print: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       comments: '',
//     },
//     design: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       comments: '',
//     },
//     size_guide: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       comments: '',
//     },
//     remarks: '',
//   });

//   const [isAlertOpen, setIsAlertOpen] = useState(false);
//   const [alertMessage, setAlertMessage] = useState("");
//   const cancelRef = React.useRef();
//   const history = useHistory();

//   useEffect(() => {
//     const currentOrderId = localStorage.getItem("currentOrderId");
//     if (currentOrderId) {
//       fetchCustomerTechPack(currentOrderId);
//     }
//   }, []);

//   const fetchCustomerTechPack = async (orderId) => {
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/customer-tech-pack`);
//       const techPackData = response.data.data['customer-tech-pack'] || [];
//       const updatedDetails = { ...techPackDetails };
//       techPackData.forEach(item => {
//         if (updatedDetails[item.title]) {
//           updatedDetails[item.title].attachment = item.attachment;
//           updatedDetails[item.title].fileName = item.attachment ? item.attachment.url.split('/').pop() : '';
//           updatedDetails[item.title].filePreview = item.attachment ? item.attachment.url : '';
//           updatedDetails[item.title].comments = item.commentsss || '';
//         }
//       });
//       setTechPackDetails(updatedDetails);
//     } catch (error) {
//       console.error("Error fetching customer tech pack", error);
//       showAlert("Error fetching customer tech pack.");
//     }
//   };

//   const handleInputChange = (e, section) => {
//     const { name, value } = e.target;
//     setTechPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [section]: {
//         ...prevDetails[section],
//         [name]: value,
//       },
//     }));
//   };

//   const handleFileChange = (e, section) => {
//     const { files } = e.target;
//     const attachment = files[0];
//     const filePreview = attachment ? URL.createObjectURL(attachment) : null;
//     setTechPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [section]: {
//         attachment,
//         fileName: attachment.name,
//         filePreview,
//       },
//     }));
//   };

//   const handleSubmit = async (section) => {
//     const formData = new FormData();
//     const currentOrderId = localStorage.getItem("currentOrderId") || 1;

//     formData.append('title', section);
//     formData.append('comments', techPackDetails[section].comments);
//     formData.append('attachment', techPackDetails[section].attachment);

//     try {
//       await apiInstance.post(`/orders/${currentOrderId}/customer-tech-pack`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       showAlert(`${section.replace('_', ' ')} submitted successfully.`);
//       setTechPackDetails((prevDetails) => ({
//         ...prevDetails,
//         [section]: {
//           attachment: null,
//           fileName: '',
//           filePreview: null,
//           comments: '',
//         },
//       }));
//       fetchCustomerTechPack(currentOrderId); // Refresh the data after submission
//     } catch (error) {
//       console.error(`Error submitting ${section}`, error);
//       showAlert(`Error submitting ${section}.`);
//     }
//   };

//   const showAlert = (message) => {
//     setAlertMessage(message);
//     setIsAlertOpen(true);
//   };

//   const renderAttachmentPreview = (filePreview, fileName) => {
//     if (!filePreview) return null;

//     const fileExtension = fileName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={filePreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={filePreview} isExternal mt={2}>
//           Open {fileName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported attachment type: {fileName}</Text>;
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Customer Teck Pack
//       </Text>
//       <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//         {['customer_tech_pack', 'print', 'design', 'size_guide'].map(section => (
//           <Box key={section}>
//             <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
//               <GridItem colSpan={2}>
//                 <FormControl isRequired>
//                   <FormLabel>{section.replace('_', ' ').toUpperCase()}</FormLabel>
//                   {techPackDetails[section].filePreview ? (
//                     renderAttachmentPreview(techPackDetails[section].filePreview, techPackDetails[section].fileName)
//                   ) : (
//                     <Input
//                       type="file"
//                       name="attachment"
//                       accept=".pdf, .jpg, .jpeg, .png"
//                       onChange={(e) => handleFileChange(e, section)}
//                     />
//                   )}
//                 </FormControl>
//               </GridItem>
//               <GridItem colSpan={2}>
//                 <FormControl>
//                   <FormLabel>comments</FormLabel>
//                   <Textarea
//                     name="comments"
//                     value={techPackDetails[section].comments}
//                     onChange={(e) => handleInputChange(e, section)}
//                   />
//                 </FormControl>
//               </GridItem>
//             </Grid>
//             <Box textAlign="center" mt={4}>
//               <Button colorScheme="teal" onClick={() => handleSubmit(section)} width={{ base: "100%", md: "auto" }}>
//                 Submit {section.replace('_', ' ')}
//               </Button>
//             </Box>
//           </Box>
//         ))}
//       </SimpleGrid>
//       <AlertDialog
//         isOpen={isAlertOpen}
//         leastDestructiveRef={cancelRef}
//         onClose={() => setIsAlertOpen(false)}
//       >
//         <AlertDialogOverlay>
//           <AlertDialogContent>
//             <AlertDialogHeader fontSize="lg" fontWeight="bold">
//               Alert
//             </AlertDialogHeader>
//             <AlertDialogBody>
//               {alertMessage}
//             </AlertDialogBody>
//             <AlertDialogFooter>
//               <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
//                 OK
//               </Button>
//             </AlertDialogFooter>
//           </AlertDialogContent>
//         </AlertDialogOverlay>
//       </AlertDialog>
//     </Box>
//   );
// };


// export default CustomerTeckPack;


// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   FormControl,
//   FormLabel,
//   Textarea,
//   SimpleGrid,
//   Image,
//   Link,
//   Grid,
//   GridItem,
//   AlertDialog,
//   AlertDialogBody,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogContent,
//   AlertDialogOverlay,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Select,
//   useDisclosure,
//   VStack,
//   HStack,
//   IconButton,
// } from "@chakra-ui/react";
// import { AddIcon } from "@chakra-ui/icons";
// import apiInstance from 'api/api';
// import { useHistory } from "react-router-dom";

// const CustomerTeckPack = () => {
//   const [techPackDetails, setTechPackDetails] = useState({
//     customer_tech_pack: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       comment: '',
//     },
//     print: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       comment: '',
//     },
//     design: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       comment: '',
//     },
//     size_guide: {
//       attachment: null,
//       fileName: '',
//       filePreview: null,
//       comment: '',
//     },
//     remarks: '',
//   });

//   const [submittedData, setSubmittedData] = useState([]);
//   const [verifications, setVerifications] = useState([]);
//   const [isAlertOpen, setIsAlertOpen] = useState(false);
//   const [alertMessage, setAlertMessage] = useState("");
//   const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
//   const [selectedTechPack, setSelectedTechPack] = useState('');
//   const [comment, setComment] = useState('');
//   const [viewComments, setViewComments] = useState([]);
//   const [isViewCommentsOpen, setIsViewCommentsOpen] = useState(false);
//   const cancelRef = React.useRef();
//   const history = useHistory();

//   useEffect(() => {
//     const currentOrderId = localStorage.getItem("currentOrderId");
//     if (currentOrderId) {
//       fetchCustomerTechPack(currentOrderId);
//     }
//   }, []);

//   const fetchCustomerTechPack = async (orderId) => {
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/customer-tech-pack`);
//       const techPackData = response.data.data['customer-tech-pack'] || [];
//       const verificationData = response.data.data['verfications'] || [];
//       const updatedDetails = { ...techPackDetails };
//       techPackData.forEach(item => {
//         if (updatedDetails[item.title]) {
//           updatedDetails[item.title].attachment = item.attachment;
//           updatedDetails[item.title].fileName = item.attachment ? item.attachment.url.split('/').pop() : '';
//           updatedDetails[item.title].filePreview = item.attachment ? item.attachment.url : '';
//           updatedDetails[item.title].comment = item.comments || '';
//         }
//       });
//       setTechPackDetails(updatedDetails);
//       setSubmittedData(techPackData);
//       setVerifications(verificationData);
//     } catch (error) {
//       console.error("Error fetching customer tech pack", error);
//       showAlert("Error fetching customer tech pack.");
//     }
//   };

//   const handleInputChange = (e, section) => {
//     const { name, value } = e.target;
//     setTechPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [section]: {
//         ...prevDetails[section],
//         [name]: value,
//       },
//     }));
//   };

//   const handleFileChange = (e, section) => {
//     const { files } = e.target;
//     const attachment = files[0];
//     const filePreview = attachment ? URL.createObjectURL(attachment) : null;
//     setTechPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [section]: {
//         attachment,
//         fileName: attachment.name,
//         filePreview,
//       },
//     }));
//   };

//   const handleSubmit = async (section) => {
//     const formData = new FormData();
//     const currentOrderId = localStorage.getItem("currentOrderId") || 1;

//     formData.append('title', section);
//     formData.append('comment', techPackDetails[section].comment);
//     formData.append('attachment', techPackDetails[section].attachment);

//     try {
//       await apiInstance.post(`/orders/${currentOrderId}/customer-tech-pack`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       showAlert(`${section.replace('_', ' ')} submitted successfully.`);
//       setTechPackDetails((prevDetails) => ({
//         ...prevDetails,
//         [section]: {
//           attachment: null,
//           fileName: '',
//           filePreview: null,
//           comment: '',
//         },
//       }));
//       fetchCustomerTechPack(currentOrderId); // Refresh the data after submission
//     } catch (error) {
//       console.error(`Error submitting ${section}`, error);
//       showAlert(`Error submitting ${section}.`);
//     }
//   };

//   const handleCommentSubmit = async () => {
//     const currentOrderId = localStorage.getItem("currentOrderId") || 1;
//     try {
//       await apiInstance.post(`/orders/${currentOrderId}/customer-tech-pack`, {
//         title: selectedTechPack,
//         comments:comment,
//         _method:"put"
//       });
//       showAlert("Tech Pack Verified Successfully.");
//       setIsCommentDialogOpen(false);
//       setSelectedTechPack('');
//       setComment('');
//       fetchCustomerTechPack(currentOrderId); // Refresh the data after submission
//     } catch (error) {
//       console.error("Error submitting comment", error);
//       showAlert("Error submitting comment.");
//     }
//   };

//   const handleViewComments = (title) => {
//     const comments = verifications.filter(verification => verification.title === title);
//     setViewComments(comments);
//     setIsViewCommentsOpen(true);
//   };

//   const showAlert = (message) => {
//     setAlertMessage(message);
//     setIsAlertOpen(true);
//   };

//   // const renderAttachmentPreview = (filePreview, fileName) => {
//   //   if (!filePreview) return null;

//   //   const fileExtension = fileName.split(".").pop().toLowerCase();
//   //   if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//   //     return <Image src={filePreview} alt="Attachment Preview" mt={2} />;
//   //   } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//   //     return (
//   //       <Link href={filePreview} isExternal mt={2}>
//   //         Open {fileName}
//   //       </Link>
//   //     );
//   //   } else {
//   //     return <Text mt={2}>Unsupported attachment type: {fileName}</Text>;
//   //   }
//   // };

//   const renderAttachmentPreview = (filePreview, fileName) => {
//     if (!filePreview) return null;
  
//     const fileExtension = fileName.split(".").pop().toLowerCase();
//     const styles = {
//       width: '200px',  // Fixed width
//       height: '200px', // Fixed height
//       objectFit: 'cover', // To ensure the image fits within the fixed dimensions
//       marginTop: '8px'
//     };
  
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={filePreview} alt="Attachment Preview" style={styles} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={filePreview} isExternal mt={2} style={styles}>
//           Open {fileName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported attachment type: {fileName}</Text>;
//     }
//   };
  

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Customer Teck Pack
//       </Text>
//       <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//         {['customer_tech_pack', 'print', 'design', 'size_guide'].map(section => (
//           <Box key={section}>
//             <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
//               <GridItem colSpan={2}>
//                 <FormControl isRequired>
//                   <FormLabel>{section.replace('_', ' ').toUpperCase()}</FormLabel>
//                   {techPackDetails[section].filePreview ? (
//                     renderAttachmentPreview(techPackDetails[section].filePreview, techPackDetails[section].fileName)
//                   ) : (
//                     <Input
//                       type="file"
//                       name="attachment"
//                       accept=".pdf, .jpg, .jpeg, .png"
//                       onChange={(e) => handleFileChange(e, section)}
//                     />
//                   )}
//                 </FormControl>
//               </GridItem>
//               <GridItem colSpan={2}>
//                 <FormControl>
//                   <FormLabel>Comment</FormLabel>
//                   <Textarea
//                     name="comment"
//                     value={techPackDetails[section].comment}
//                     onChange={(e) => handleInputChange(e, section)}
//                   />
//                 </FormControl>
//               </GridItem>
//             </Grid>
//             <Box textAlign="center" mt={4}>
//               <Button colorScheme="teal" onClick={() => handleSubmit(section)} width={{ base: "100%", md: "auto" }}>
//                 Submit {section.replace('_', ' ')}
//               </Button>
//             </Box>
//           </Box>
//         ))}
//       </SimpleGrid>
//       <Box display="flex" justifyContent="space-between" alignItems="center" mt={10} mb={4}>
//         <Text fontSize="xl" fontWeight="bold">
//           Submitted Data
//         </Text>
//         {/* <Button colorScheme="teal" onClick={() => setIsCommentDialogOpen(true)}>
//           Add Comment
//         </Button> */}
//         <IconButton icon={<AddIcon />} size="sm" onClick={() => setIsCommentDialogOpen(true)} />
//       </Box>
//       <Table variant="simple">
//         <Thead>
//           <Tr>
//             <Th>Title</Th>
//             <Th>Attachment</Th>
//             <Th>Comments</Th>
//           </Tr>
//         </Thead>
//         <Tbody>
//           {submittedData.map((item, index) => (
//             <Tr key={index}>
//               <Td>{item.title.replace('_', ' ').toUpperCase()}</Td>
//               <Td>
//                 {item.attachment ? (
//                   <Link href={item.attachment.url} isExternal>
//                     {item.attachment.url.split('/').pop()}
//                   </Link>
//                 ) : (
//                   'No Attachment'
//                 )}
//               </Td>
//               <Td>
//                 <Button size="sm" onClick={() => handleViewComments(item.title)}>
//                   View
//                 </Button>
//               </Td>
//             </Tr>
//           ))}
//         </Tbody>
//       </Table>
//       <AlertDialog
//         isOpen={isAlertOpen}
//         leastDestructiveRef={cancelRef}
//         onClose={() => setIsAlertOpen(false)}
//       >
//         <AlertDialogOverlay>
//           <AlertDialogContent>
//             <AlertDialogHeader fontSize="lg" fontWeight="bold">
//               Alert
//             </AlertDialogHeader>
//             <AlertDialogBody>
//               {alertMessage}
//             </AlertDialogBody>
//             <AlertDialogFooter>
//               <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
//                 OK
//               </Button>
//             </AlertDialogFooter>
//           </AlertDialogContent>
//         </AlertDialogOverlay>
//       </AlertDialog>
//       <AlertDialog
//         isOpen={isCommentDialogOpen}
//         leastDestructiveRef={cancelRef}
//         onClose={() => setIsCommentDialogOpen(false)}
//       >
//         <AlertDialogOverlay>
//           <AlertDialogContent>
//             <AlertDialogHeader fontSize="lg" fontWeight="bold" display="flex" justifyContent="space-between" alignItems="center">
//               <Text>Add Comment</Text>
//               {/* <IconButton icon={<AddIcon />} size="sm" onClick={() => setIsCommentDialogOpen(true)} /> */}
//             </AlertDialogHeader>
//             <AlertDialogBody>
//               <FormControl isRequired>
//                 <FormLabel>Tech Pack</FormLabel>
//                 <Select placeholder="Select Tech Pack" value={selectedTechPack} onChange={(e) => setSelectedTechPack(e.target.value)}>
//                   {submittedData.map((item) => (
//                     <option key={item.title} value={item.title}>{item.title.replace('_', ' ').toUpperCase()}</option>
//                   ))}
//                 </Select>
//               </FormControl>
//               <FormControl mt={4}>
//                 <FormLabel>Comment</FormLabel>
//                 <Textarea value={comment} onChange={(e) => setComment(e.target.value)} />
//               </FormControl>
//             </AlertDialogBody>
//             <AlertDialogFooter>
//               <Button onClick={handleCommentSubmit} colorScheme="teal" ml={3}>
//                 Submit
//               </Button>
//               <Button ref={cancelRef} onClick={() => setIsCommentDialogOpen(false)}>
//                 Cancel
//               </Button>
//             </AlertDialogFooter>
//           </AlertDialogContent>
//         </AlertDialogOverlay>
//       </AlertDialog>
//       <AlertDialog
//         isOpen={isViewCommentsOpen}
//         leastDestructiveRef={cancelRef}
//         onClose={() => setIsViewCommentsOpen(false)}
//       >
//         <AlertDialogOverlay>
//           <AlertDialogContent>
//             <AlertDialogHeader fontSize="lg" fontWeight="bold" display="flex" justifyContent="space-between" alignItems="center">
//               <Text>Comments</Text>
//               {/* <IconButton icon={<AddIcon />} size="sm" onClick={() => setIsCommentDialogOpen(true)} /> */}
//             </AlertDialogHeader>
//             <AlertDialogBody>
//               <VStack align="start" spacing={4}>
//                 {viewComments.length > 0 ? viewComments.map((comment, idx) => (
//                   <Box key={idx}>
//                     <Text>{comment.comments || 'No Comments'}</Text>
//                     <Text fontSize="xs" color="gray.500">
//                       {comment.verified_at} by {comment.verified_by} ({comment.verfication_user_role})
//                     </Text>
//                   </Box>
//                 )) : <Text>No Comments</Text>}
//               </VStack>
//             </AlertDialogBody>
//             <AlertDialogFooter>
//               <Button ref={cancelRef} onClick={() => setIsViewCommentsOpen(false)}>
//                 Close
//               </Button>
//             </AlertDialogFooter>
//           </AlertDialogContent>
//         </AlertDialogOverlay>
//       </AlertDialog>
//     </Box>
//   );
// };

// export default CustomerTeckPack;


import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  SimpleGrid,
  Image,
  Link,
  Grid,
  GridItem,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  useDisclosure,
  VStack,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import apiInstance from 'api/api';
import { useHistory } from "react-router-dom";

const CustomerTeckPack = () => {
  const [techPackDetails, setTechPackDetails] = useState({
    customer_tech_pack: {
      attachment: null,
      fileName: '',
      filePreview: null,
      comment: '',
    },
    print: {
      attachment: null,
      fileName: '',
      filePreview: null,
      comment: '',
    },
    design: {
      attachment: null,
      fileName: '',
      filePreview: null,
      comment: '',
    },
    size_guide: {
      attachment: null,
      fileName: '',
      filePreview: null,
      comment: '',
    },
    remarks: '',
  });

  const [submittedData, setSubmittedData] = useState([]);
  const [verifications, setVerifications] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
  const [selectedTechPack, setSelectedTechPack] = useState('');
  const [comment, setComment] = useState('');
  const [viewComments, setViewComments] = useState([]);
  const [isViewCommentsOpen, setIsViewCommentsOpen] = useState(false);
  const cancelRef = React.useRef();
  const history = useHistory();

  useEffect(() => {
    const currentOrderId = localStorage.getItem("currentOrderId");
    if (currentOrderId) {
      fetchCustomerTechPack(currentOrderId);
    }
  }, []);

  const fetchCustomerTechPack = async (orderId) => {
    try {
      const response = await apiInstance.get(`/orders/${orderId}/customer-tech-pack`);
      const techPackData = response.data.data['customer-tech-pack'] || [];
      const verificationData = response.data.data['verfications'] || [];
      const updatedDetails = { ...techPackDetails };
      techPackData.forEach(item => {
        if (updatedDetails[item.title]) {
          updatedDetails[item.title].attachment = item.attachment;
          updatedDetails[item.title].fileName = item.attachment ? item.attachment.url.split('/').pop() : '';
          updatedDetails[item.title].filePreview = item.attachment ? item.attachment.url : '';
          updatedDetails[item.title].comment = item.comments || '';
        }
      });
      setTechPackDetails(updatedDetails);
      setSubmittedData(techPackData);
      setVerifications(verificationData);
    } catch (error) {
      console.error("Error fetching customer tech pack", error);
      showAlert("Error fetching customer tech pack.");
    }
  };

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    setTechPackDetails((prevDetails) => ({
      ...prevDetails,
      [section]: {
        ...prevDetails[section],
        [name]: value,
      },
    }));
  };

  const handleFileChange = (e, section) => {
    const { files } = e.target;
    const attachment = files[0];
    const filePreview = attachment ? URL.createObjectURL(attachment) : null;
    setTechPackDetails((prevDetails) => ({
      ...prevDetails,
      [section]: {
        attachment,
        fileName: attachment.name,
        filePreview,
      },
    }));
  };

  const handleSubmit = async (section) => {
    const formData = new FormData();
    const currentOrderId = localStorage.getItem("currentOrderId") || 1;

    formData.append('title', section);
    formData.append('comment', techPackDetails[section].comment);
    formData.append('attachment', techPackDetails[section].attachment);

    try {
      await apiInstance.post(`/orders/${currentOrderId}/customer-tech-pack`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      showAlert(`${section.replace('_', ' ')} submitted successfully.`);
      setTechPackDetails((prevDetails) => ({
        ...prevDetails,
        [section]: {
          attachment: null,
          fileName: '',
          filePreview: null,
          comment: '',
        },
      }));
      fetchCustomerTechPack(currentOrderId); // Refresh the data after submission
    } catch (error) {
      console.error(`Error submitting ${section}`, error);
      showAlert(`Error submitting ${section}.`);
    }
  };

  const handleCommentSubmit = async () => {
    const currentOrderId = localStorage.getItem("currentOrderId") || 1;
    try {
      await apiInstance.post(`/orders/${currentOrderId}/customer-tech-pack`, {
        title: selectedTechPack,
        comments: comment,
        _method: "put"
      });
      showAlert("Tech Pack Verified Successfully.");
      setIsCommentDialogOpen(false);
      setSelectedTechPack('');
      setComment('');
      fetchCustomerTechPack(currentOrderId); // Refresh the data after submission
    } catch (error) {
      console.error("Error submitting comment", error);
      showAlert("Error submitting comment.");
    }
  };

  const handleViewComments = (title) => {
    const comments = verifications.filter(verification => verification.title === title);
    setViewComments(comments);
    setIsViewCommentsOpen(true);
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertOpen(true);
  };

  const renderAttachmentPreview = (filePreview, fileName) => {
    if (!filePreview) return null;

    const fileExtension = fileName.split(".").pop().toLowerCase();
    const styles = {
      width: '100px',  // Fixed width
      height: '100px', // Fixed height
      objectFit: 'cover', // To ensure the image fits within the fixed dimensions
      marginTop: '8px'
    };

    if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <Image src={filePreview} alt="Attachment Preview" style={styles} />;
    } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
      return (
        <Link href={filePreview} isExternal mt={2} style={styles}>
          Open {fileName}
        </Link>
      );
    } else {
      return <Text mt={2}>Unsupported attachment type: {fileName}</Text>;
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "80px" }}>
      {/* <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Customer Teck Pack
      </Text> */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        {['customer_tech_pack', 'print', 'design', 'size_guide'].map(section => (
          <Box key={section}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={2}>
                <FormControl isRequired>
                  <FormLabel>{section.replace('_', ' ').toUpperCase()}</FormLabel>
                  {techPackDetails[section].filePreview ? (
                    renderAttachmentPreview(techPackDetails[section].filePreview, techPackDetails[section].fileName)
                  ) : (
                    <Input
                      type="file"
                      name="attachment"
                      accept=".pdf, .jpg, .jpeg, .png"
                      onChange={(e) => handleFileChange(e, section)}
                    />
                  )}
                </FormControl>
              </GridItem>
              {/* <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>Comment</FormLabel>
                  <Textarea
                    name="comment"
                    value={techPackDetails[section].comment}
                    onChange={(e) => handleInputChange(e, section)}
                  />
                </FormControl>
              </GridItem> */}
            </Grid>
            {!submittedData.some(data => data.title === section) && (
              <Box textAlign="center" mt={4}>
                <Button colorScheme="teal" onClick={() => handleSubmit(section)} width={{ base: "100%", md: "auto" }}>
                  Submit {section.replace('_', ' ')}
                </Button>
              </Box>
            )}
          </Box>
        ))}
      </SimpleGrid>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={10} mb={4}>
        <Text fontSize="xl" fontWeight="bold">
          Submitted Data
        </Text>
        <Flex> <IconButton icon={<AddIcon />} size="sm" onClick={() => setIsCommentDialogOpen(true)} /><Text ml={1}>Comment</Text></Flex>
      </Box>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Attachment</Th>
            <Th>Comments</Th>
          </Tr>
        </Thead>
        <Tbody>
          {submittedData.map((item, index) => (
            <Tr key={index}>
              <Td>{item.title.replace('_', ' ').toUpperCase()}</Td>
              <Td>
                {item.attachment ? (
                  <Link href={item.attachment.url} isExternal>
                    {item.attachment.url.split('/').pop()}
                  </Link>
                ) : (
                  'No Attachment'
                )}
              </Td>
              <Td>
                <Button size="sm" onClick={() => handleViewComments(item.title)}>
                  View
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Alert
            </AlertDialogHeader>
            <AlertDialogBody>
              {alertMessage}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isCommentDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsCommentDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" display="flex" justifyContent="space-between" alignItems="center">
              <Text>Add Comment</Text>
            </AlertDialogHeader>
            <AlertDialogBody>
              <FormControl isRequired>
                <FormLabel>Tech Pack</FormLabel>
                <Select placeholder="Select Tech Pack" value={selectedTechPack} onChange={(e) => setSelectedTechPack(e.target.value)}>
                  {submittedData.map((item) => (
                    <option key={item.title} value={item.title}>{item.title.replace('_', ' ').toUpperCase()}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Comment</FormLabel>
                <Textarea value={comment} onChange={(e) => setComment(e.target.value)} />
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={handleCommentSubmit} colorScheme="teal" ml={3}mr={2}>
                Submit
              </Button>
              <Button ref={cancelRef} onClick={() => setIsCommentDialogOpen(false)}>
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isViewCommentsOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsViewCommentsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" display="flex" justifyContent="space-between" alignItems="center">
              <Text>Comments</Text>
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack align="start" spacing={4}>
                {viewComments.length > 0 ? viewComments.map((comment, idx) => (
                  <Box key={idx}>
                    <Text>{comment.comments || 'No Comments'}</Text>
                    <Text fontSize="xs" color="gray.500">
                      {comment.verified_at} by {comment.verified_by} ({comment.verfication_user_role})
                    </Text>
                  </Box>
                )) : <Text>No Comments</Text>}
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsViewCommentsOpen(false)}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default CustomerTeckPack;
