// // createOrderRoutes.js
// import React from "react";
// import { Icon } from "@chakra-ui/react";
// import { MdAssignment, MdFolder, MdTimeline, MdLocalShipping } from "react-icons/md";

// import BasicDetails from "views/pages/BasicDetails";
// import CustomerTeckPack from "views/pages/CustomerTeckPack";
// import SamshekTeckPack from "views/pages/SamshekTeckPack";
// import ProgressTab from "views/pages/ProgressTab";
// import DeliveryTab from "views/pages/Deliverytab";

// const createOrderRoutes = [
//   {
//     name: "Basic Details",
//     layout: "/admin",
//     path: "/create-order/basic-details",
//     icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
//     component: BasicDetails,
//   },
//   {
//     name: "Customer Tech Pack",
//     layout: "/admin",
//     path: "/create-order/customer-tech-pack",
//     icon: <Icon as={MdFolder} width="20px" height="20px" color="inherit" />,
//     component: CustomerTeckPack,
//   },
//   {
//     name: "Samshek Teck Pack",
//     layout: "/admin",
//     path: "/create-order/samshek-teck-pack",
//     icon: <Icon as={MdFolder} width="20px" height="20px" color="inherit" />,
//     component: SamshekTeckPack,
//   },
//   {
//     name: "Progress Tab",
//     layout: "/admin",
//     path: "/create-order/progress-tab",
//     icon: <Icon as={MdTimeline} width="20px" height="20px" color="inherit" />,
//     component: ProgressTab,
//   },
//   {
//     name: "Delivery Tab",
//     layout: "/admin",
//     path: "/create-order/delivery-tab",
//     icon: <Icon as={MdLocalShipping} width="20px" height="20px" color="inherit" />,
//     component: DeliveryTab,
//   },
// ];

// export default createOrderRoutes;


import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdAssignment, MdFolder, MdTimeline, MdLocalShipping } from "react-icons/md";

import BasicDetails from "views/pages/BasicDetails";
import CustomerTeckPack from "views/pages/CustomerTeckPack";
import SamshekTeckPack from "views/pages/SamshekTeckPack";
import ProgressTab from "views/pages/ProgressTab";
import DeliveryTab from "views/pages/Deliverytab";

// Retrieve permissions from local storage
const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");

const createOrderRoutes = [
  {
    name: "Basic Details",
    layout: "/admin",
    path: "/create-order/basic-details",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: BasicDetails,
    permission: "orders.basic-details.view",
  },
  {
    name: "Customer Tech Pack",
    layout: "/admin",
    path: "/create-order/customer-tech-pack",
    icon: <Icon as={MdFolder} width="20px" height="20px" color="inherit" />,
    component: CustomerTeckPack,
    permission: "orders.customer-tech-pack.index.view",
  },
  {
    name: "Samshek Teck Pack",
    layout: "/admin",
    path: "/create-order/samshek-teck-pack",
    icon: <Icon as={MdFolder} width="20px" height="20px" color="inherit" />,
    component: SamshekTeckPack,
    permission: "orders.samshek-tech-pack.index.view",
  },
  {
    name: "Progress Tab",
    layout: "/admin",
    path: "/create-order/progress-tab",
    icon: <Icon as={MdTimeline} width="20px" height="20px" color="inherit" />,
    component: ProgressTab,
    permission: "orders.progress-tab.view",
  },
  {
    name: "Delivery Tab",
    layout: "/admin",
    path: "/create-order/delivery-tab",
    icon: <Icon as={MdLocalShipping} width="20px" height="20px" color="inherit" />,
    component: DeliveryTab,
    permission: "orders.delivery-tab.view",
  },
];

// Filter createOrderRoutes based on view permissions
export default createOrderRoutes.filter(route => permissions.includes(route.permission));
