// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Flex,
//   Stack,
//   Text,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   AlertDialog,
//   AlertDialogBody,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogContent,
//   AlertDialogOverlay,
//   AlertDialogCloseButton,
//   Spinner,
// } from "@chakra-ui/react";
// import apiInstance from "api/api";

// const OrderDetails = () => {
//   const [orders, setOrders] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const {
//     isOpen: isAlertOpen,
//     onOpen: onAlertOpen,
//     onClose: onAlertClose,
//   } = useDisclosure();
//   const [alertContent, setAlertContent] = useState("");
//   const cancelRef = React.useRef();

//   useEffect(() => {
//     fetchOrders();
//   }, []);

//   const fetchOrders = async () => {
//     setIsLoading(true);
//     try {
//       const response = await apiInstance.get("/orders");
//       const orders = response.data.data.orders || [];
//       setOrders(orders);
//       console.log("response", orders);
//     } catch (error) {
//       console.error("Error fetching orders", error);
//       showAlert("Error fetching orders.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const showAlert = (content) => {
//     setAlertContent(content);
//     onAlertOpen();
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <Box p={4}>
//         <Flex justifyContent="space-between" alignItems="center" mb={4}>
//           <Text fontSize="2xl" fontWeight="bold">
//             Order Details
//           </Text>
//         </Flex>

//         {isLoading ? (
//           <Flex justifyContent="center" alignItems="center" height="200px">
//             <Spinner size="xl" />
//           </Flex>
//         ) : (
//           <Stack spacing={4}>
//             {Array.isArray(orders) &&
//               orders.map((order) => (
//                 <Flex
//                   key={order.id}
//                   justifyContent="space-between"
//                   alignItems="center"
//                   p={4}
//                   bg="gray.100"
//                   borderRadius="md"
//                 >
//                   <Box>
//                     <Text fontWeight="bold">Order ID: {order.id}</Text>
//                     <Text>Customer Name: {order.customer.name}</Text>
//                     <Text>Customer Email: {order.customer.email}</Text>
//                     <Text>Alpha Code: {order.customer.alpha_code}</Text>
//                     <Text>Agent Code: {order.customer.agent_code}</Text>
//                     <Text>
//                       Basic Details Added:{" "}
//                       {order.status.basic_details_added ? "Yes" : "No"}
//                     </Text>
//                     <Text>
//                       Customer Tech Pack Added:{" "}
//                       {order.status.customer_tech_pack_added ? "Yes" : "No"}
//                     </Text>
//                     <Text>
//                       Samshek Tech Pack Added:{" "}
//                       {order.status.samshek_tech_pack_added ? "Yes" : "No"}
//                     </Text>
//                     <Text>
//                       Progress Added:{" "}
//                       {order.status.progress_added ? "Yes" : "No"}
//                     </Text>
//                     <Text>
//                       Deliveries Added:{" "}
//                       {order.status.deliveries_added ? "Yes" : "No"}
//                     </Text>
//                   </Box>
//                 </Flex>
//               ))}
//           </Stack>
//         )}

//         <AlertDialog
//           isOpen={isAlertOpen}
//           leastDestructiveRef={cancelRef}
//           onClose={onAlertClose}
//         >
//           <AlertDialogOverlay>
//             <AlertDialogContent>
//               <AlertDialogHeader fontSize="lg" fontWeight="bold">
//                 Alert
//               </AlertDialogHeader>
//               <AlertDialogCloseButton />
//               <AlertDialogBody>{alertContent}</AlertDialogBody>
//               <AlertDialogFooter>
//                 <Button ref={cancelRef} onClick={onAlertClose}>
//                   OK
//                 </Button>
//               </AlertDialogFooter>
//             </AlertDialogContent>
//           </AlertDialogOverlay>
//         </AlertDialog>
//       </Box>
//     </Box>
//   );
// };

// export default OrderDetails;


// import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import {
//   Box,
//   Flex,
//   Stack,
//   Text,
//   Spinner,
//   Button,
// } from "@chakra-ui/react";
// import apiInstance from "api/api";

// const OrderDetails = () => {
//   const [orders, setOrders] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const history = useHistory();

//   useEffect(() => {
//     fetchOrders();
//   }, []);

//   const fetchOrders = async () => {
//     setIsLoading(true);
//     try {
//       const response = await apiInstance.get("/orders");
//       const orders = response.data.data.orders || [];
//       setOrders(orders);
//       console.log("response", orders);
//     } catch (error) {
//       console.error("Error fetching orders", error);
//       alert("Error fetching orders.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleOrderClick = (order) => {
//     localStorage.setItem("currentOrderId", order.id);
//     history.push("/admin/create-order/basic-details");
//   };

//   // const handleCreateOrderClick = () => {
//   //   history.push("/admin/create-order/basic-details");
//   // };
//   const handleCreateOrderClick = () => {
//     localStorage.removeItem("currentOrderId"); // Remove the previous currentOrderId
//     history.push("/admin/create-order/basic-details");
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <Box p={4}>
//         <Flex justifyContent="space-between" alignItems="center" mb={4}>
//           <Text fontSize="2xl" fontWeight="bold">
//             Order Details
//           </Text>
//           <Button colorScheme="teal" onClick={handleCreateOrderClick}>
//             Create Order
//           </Button>
//         </Flex>

//         {isLoading ? (
//           <Flex justifyContent="center" alignItems="center" height="200px">
//             <Spinner size="xl" />
//           </Flex>
//         ) : (
//           <Stack spacing={4}>
//             {Array.isArray(orders) &&
//               orders.map((order) => (
//                 <Flex
//                   key={order.id}
//                   justifyContent="space-between"
//                   alignItems="center"
//                   p={4}
//                   bg="gray.100"
//                   borderRadius="md"
//                 >
//                   <Box>
//                     <Text fontWeight="bold">Order ID: {order.id}</Text>
//                     <Text>Customer Name: {order.customer.name}</Text>
//                     <Text>Customer Email: {order.customer.email}</Text>
//                     <Text>Alpha Code: {order.customer.alpha_code}</Text>
//                     <Text>Agent Code: {order.customer.agent_code}</Text>
//                     <Text>
//                       Basic Details Added:{" "}
//                       {order.status.basic_details_added ? "Yes" : "No"}
//                     </Text>
//                     <Text>
//                       Customer Tech Pack Added:{" "}
//                       {order.status.customer_tech_pack_added ? "Yes" : "No"}
//                     </Text>
//                     <Text>
//                       Samshek Tech Pack Added:{" "}
//                       {order.status.samshek_tech_pack_added ? "Yes" : "No"}
//                     </Text>
//                     <Text>
//                       Progress Added:{" "}
//                       {order.status.progress_added ? "Yes" : "No"}
//                     </Text>
//                     <Text>
//                       Deliveries Added:{" "}
//                       {order.status.deliveries_added ? "Yes" : "No"}
//                     </Text>
//                   </Box>
//                   <Button colorScheme="blue" onClick={() => handleOrderClick(order)}>
//                     View Details
//                   </Button>
//                 </Flex>
//               ))}
//           </Stack>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default OrderDetails;

// import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import {
//   Box,
//   Flex,
//   Text,
//   Spinner,
//   Button,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   useBreakpointValue,
//   Stack,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import apiInstance from "api/api";

// const OrderDetails = () => {
//   const [orders, setOrders] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const history = useHistory();
//   const tableVariant = useBreakpointValue({ base: "striped", md: "simple" });
//   const tableSize = useBreakpointValue({ base: "sm", md: "md" });
//   const bgColor = useColorModeValue("gray.100", "gray.700");

//   useEffect(() => {
//     fetchOrders();
//   }, []);

//   const fetchOrders = async () => {
//     setIsLoading(true);
//     try {
//       const response = await apiInstance.get("/orders");
//       const orders = response.data.data.orders || [];
//       setOrders(orders);
//       console.log("response", orders);
//     } catch (error) {
//       console.error("Error fetching orders", error);
//       alert("Error fetching orders.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleOrderClick = (order) => {
//     localStorage.setItem("currentOrderId", order.id);
//     history.push("/admin/create-order/basic-details");
//   };

//   const handleCreateOrderClick = () => {
//     localStorage.removeItem("currentOrderId"); // Remove the previous currentOrderId
//     history.push("/admin/create-order/basic-details");
//   };

//   return (
//     <Box pt={{ base: "120px", md: "80px", xl: "80px" }} px={{ base: 4, md: 10 }}>
//       <Box>
//         <Flex justifyContent="space-between" alignItems="center" mb={6}>
//           {/* <Text fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold">
//             Order Details
//           </Text> */}
//           <Button
//             colorScheme="teal"
//             onClick={handleCreateOrderClick}
//             size={{ base: "sm", md: "md" }}
//             shadow="md"
//             padding={2}
            
//           >
//             Create Order
//           </Button>
//         </Flex>

//         {isLoading ? (
//           <Flex justifyContent="center" alignItems="center" height="300px">
//             <Spinner size="xl" />
//           </Flex>
//         ) : (
//           <Box
//             overflowX="auto"
//             bg={bgColor}
//             p={{ base: 2, md: 4 }}
//             borderRadius="md"
//             shadow="md"
//           >
//             <Table variant={tableVariant} size={tableSize} colorScheme="teal">
//               <Thead>
//                 <Tr>
//                   <Th>Order ID</Th>
//                   <Th>Customer Name</Th>
//                   <Th>Customer Email</Th>
//                   <Th>Alpha Code</Th>
//                   <Th>Agent Code</Th>
//                   <Th>Basic Details</Th>
//                   <Th>Customer Tech Pack</Th>
//                   <Th>Samshek Tech Pack</Th>
//                   <Th>Progress</Th>
//                   <Th>Deliveries</Th>
//                   <Th>Action</Th>
//                 </Tr>
//               </Thead>
//               <Tbody>
//                 {orders.map((order) => (
//                   <Tr key={order.id}>
//                     <Td>{order.id}</Td>
//                     <Td>{order.customer.name}</Td>
//                     <Td>{order.customer.email}</Td>
//                     <Td>{order.customer.alpha_code}</Td>
//                     <Td>{order.customer.agent_code}</Td>
//                     <Td>{order.status.basic_details_added ? "Yes" : "No"}</Td>
//                     <Td>{order.status.customer_tech_pack_added ? "Yes" : "No"}</Td>
//                     <Td>{order.status.samshek_tech_pack_added ? "Yes" : "No"}</Td>
//                     <Td>{order.status.progress_added ? "Yes" : "No"}</Td>
//                     <Td>{order.status.deliveries_added ? "Yes" : "No"}</Td>
//                     <Td>
//                       <Button
//                         colorScheme="blue"
//                         size={{ base: "xs", md: "sm" }}
//                         onClick={() => handleOrderClick(order)}
//                         shadow="sm"
//                         padding={2}
//                       >
//                         View Details
//                       </Button>
//                     </Td>
//                   </Tr>
//                 ))}
//               </Tbody>
//             </Table>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default OrderDetails;


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import apiInstance from "api/api";

const OrderDetails = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const tableVariant = useBreakpointValue({ base: "striped", md: "simple" });
  const tableSize = useBreakpointValue({ base: "sm", md: "md" });
  const bgColor = useColorModeValue("gray.100", "gray.700");

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const response = await apiInstance.get("/orders");
      const orders = response.data.data.orders || [];
      setOrders(orders);
      console.log("response", orders);
    } catch (error) {
      console.error("Error fetching orders", error);
      alert("Error fetching orders.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrderClick = (order) => {
    localStorage.setItem("currentOrderId", order.id);
    history.push("/admin/create-order/basic-details");
  };

  const handleCreateOrderClick = () => {
    localStorage.removeItem("currentOrderId"); // Remove the previous currentOrderId
    history.push("/admin/create-order/basic-details");
  };

  return (
    <Box pt={{ base: "120px", md: "80px", xl: "80px" }} px={{ base: 4, md: 10 }}>
      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          {/* <Text fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold">
            Order Details
          </Text> */}
          <Flex justifyContent="flex-end" width="100%">
            <Button
              colorScheme="teal"
              onClick={handleCreateOrderClick}
              size={{ base: "sm", md: "md" }}
              shadow="md"
              padding={2}
            >
              Create Order
            </Button>
          </Flex>
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height="300px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Box
            overflowX="auto"
            bg={bgColor}
            p={{ base: 2, md: 4 }}
            borderRadius="md"
            shadow="md"
          >
            <Table variant={tableVariant} size={tableSize} colorScheme="teal">
              <Thead>
                <Tr>
                  {/* <Th>Order ID</Th> */}
                  <Th>Serial No</Th>
                  <Th>Customer Name</Th>
                  <Th>Customer Email</Th>
                  <Th>Alpha Code</Th>
                  <Th>Agent Code</Th>
                  <Th>Basic Details</Th>
                  <Th>Customer Tech Pack</Th>
                  <Th>Samshek Tech Pack</Th>
                  <Th>Progress</Th>
                  <Th>Deliveries</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {orders.map((order,index) => (
                  <Tr key={order.id}>
                    {/* <Td>{order.id}</Td> */}
                    <Td>{index+1}</Td>
                    <Td>{order.customer.name}</Td>
                    <Td>{order.customer.email}</Td>
                    <Td>{order.customer.alpha_code}</Td>
                    <Td>{order.customer.agent_code}</Td>
                    <Td>{order.status.basic_details_added ? "Yes" : "No"}</Td>
                    <Td>{order.status.customer_tech_pack_added ? "Yes" : "No"}</Td>
                    <Td>{order.status.samshek_tech_pack_added ? "Yes" : "No"}</Td>
                    <Td>{order.status.progress_added ? "Yes" : "No"}</Td>
                    <Td>{order.status.deliveries_added ? "Yes" : "No"}</Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        size={{ base: "xs", md: "sm" }}
                        onClick={() => handleOrderClick(order)}
                        shadow="sm"
                        padding={2}
                      >
                        View Details
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderDetails;


// import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import {
//   Box,
//   Flex,
//   Text,
//   Spinner,
//   Button,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   useBreakpointValue,
//   Stack,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import apiInstance from "api/api";

// const OrderDetails = () => {
//   const [orders, setOrders] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const history = useHistory();
//   const tableVariant = useBreakpointValue({ base: "striped", md: "simple" });
//   const tableSize = useBreakpointValue({ base: "sm", md: "md" });
//   const bgColor = useColorModeValue("gray.100", "gray.700");

//   const role = localStorage.getItem("role"); // Get role from local storage

//   useEffect(() => {
//     fetchOrders();
//   }, []);

//   const fetchOrders = async () => {
//     setIsLoading(true);
//     try {
//       const response = await apiInstance.get("/orders");
//       const orders = response.data.data.orders || [];
//       setOrders(orders);
//       console.log("response", orders);
//     } catch (error) {
//       console.error("Error fetching orders", error);
//       alert("Error fetching orders.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleOrderClick = (order) => {
//     localStorage.setItem("currentOrderId", order.id);
//     history.push("/admin/create-order/basic-details");
//   };

//   // const handleCreateOrderClick = () => {
//   //   localStorage.removeItem("currentOrderId"); // Remove the previous currentOrderId
//   //   history.push("/admin/create-order/basic-details");
//   // };
//   const handleCreateOrderClick = () => {
//     localStorage.removeItem("currentOrderId"); // Remove the previous currentOrderId
//     history.push("/admin/create-order/basic-details");
//   };

//   const handleEditOrderClick = (order) => {
//     localStorage.setItem("currentOrderId", order.id);
//     history.push("/admin/create-order/basic-details");
//   };

//   return (
//     <Box pt={{ base: "120px", md: "80px", xl: "80px" }} px={{ base: 4, md: 10 }}>
//       <Box>
//         <Flex justifyContent="space-between" alignItems="center" mb={6}>
//           <Flex justifyContent="flex-end" width="100%">
//             <Button
//               colorScheme="teal"
//               onClick={handleCreateOrderClick}
//               size={{ base: "sm", md: "md" }}
//               shadow="md"
//               padding={2}
//             >
//               Create Order
//             </Button>
//           </Flex>
//         </Flex>

//         {isLoading ? (
//           <Flex justifyContent="center" alignItems="center" height="300px">
//             <Spinner size="xl" />
//           </Flex>
//         ) : (
//           <Box
//             overflowX="auto"
//             bg={bgColor}
//             p={{ base: 2, md: 4 }}
//             borderRadius="md"
//             shadow="md"
//           >
//             <Table variant={tableVariant} size={tableSize} colorScheme="teal">
//               <Thead>
//                 <Tr>
//                   <Th>Serial No</Th>
//                   {/* <Th>Order ID</Th> */}
//                   <Th>Customer Name</Th>
//                   <Th>Customer Email</Th>
//                   <Th>Alpha Code</Th>
//                   <Th>Agent Code</Th>
//                   <Th>Basic Details</Th>
//                   <Th>Customer Tech Pack</Th>
//                   <Th>Samshek Tech Pack</Th>
//                   <Th>Progress</Th>
//                   <Th>Deliveries</Th>
//                   <Th>Action</Th>
//                 </Tr>
//               </Thead>
//               <Tbody>
//                 {orders.map((order,index) => (
//                   <Tr key={order.id}>
//                     <Td>{index+1}</Td>
//                     {/* <Td>{order.id}</Td> */}
//                     <Td>{order.customer.name}</Td>
//                     <Td>{order.customer.email}</Td>
//                     <Td>{order.customer.alpha_code}</Td>
//                     <Td>{order.customer.agent_code}</Td>
//                     <Td>{order.status.basic_details_added ? "Yes" : "No"}</Td>
//                     <Td>{order.status.customer_tech_pack_added ? "Yes" : "No"}</Td>
//                     <Td>{order.status.samshek_tech_pack_added ? "Yes" : "No"}</Td>
//                     <Td>{order.status.progress_added ? "Yes" : "No"}</Td>
//                     <Td>{order.status.deliveries_added ? "Yes" : "No"}</Td>
//                     <Td display={"flex"} justifyContent={"space-around"}>
//                       <Button
//                         colorScheme="blue"
//                         size={{ base: "xs", md: "sm" }}
//                         onClick={() => handleOrderClick(order)}
//                         shadow="sm"
//                         padding={2}
//                       >
//                         View Details
//                       </Button>
//                       {role === "SUPERADMIN" && (
//                         <Button
//                           colorScheme="yellow"
//                           size={{ base: "xs", md: "sm" }}
//                           onClick={() => handleEditOrderClick(order)}
//                           shadow="sm"
//                           padding={2}
//                           ml={2}
//                         >
//                           Edit
//                         </Button>
//                       )}
//                     </Td>
//                   </Tr>
//                 ))}
//               </Tbody>
//             </Table>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default OrderDetails;
