// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Input,
//   Select,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   Image,
//   SimpleGrid,
//   Link,
// } from "@chakra-ui/react";

// const BasicDetails = () => {
//   const [clients, setClients] = useState([]);
//   const [attachmentPreview, setAttachmentPreview] = useState(null);
//   const [attachmentName, setAttachmentName] = useState("");
//   const [basicDetails, setBasicDetails] = useState({
//     customerName: "",
//     date: "",
//     order_type: "",
//     invoice_number: "",
//     invoice_attachment: null,
//     invoice_date: "",
//     deadline: "",
//     physical_sample_received: "",
//     product: "",
//     estimated_date_of_shipping: "",
//     estimated_date_of_payment: "",
//     productionStatus: "",
//     deliveryStatus: "undelivered",
//     docId: "",
//   });
//   const toast = useToast();

//   useEffect(() => {
//     const storedClients = JSON.parse(localStorage.getItem("clients")) || [];
//     setClients(storedClients);
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     const file = files[0];
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: file,
//     }));
//     setAttachmentName(file.name);
//     setAttachmentPreview(URL.createObjectURL(file));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(basicDetails);
//     toast({ title: "Details submitted.", status: "success" });
//     // Add logic to save the details
//   };

//   const renderAttachmentPreview = () => {
//     if (!attachmentPreview) return null;

//     const fileExtension = attachmentName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={attachmentPreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={attachmentPreview} isExternal mt={2}>
//           Open {attachmentName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported file type: {attachmentName}</Text>;
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       {/* <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Basic Details
//       </Text> */}
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Customer Name</FormLabel>
//             <Select
//               name="customerName"
//               value={basicDetails.customerName}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Customer</option>
//               {clients.map((client) => (
//                 <option key={client.id} value={client.name}>
//                   {client.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={basicDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Type</FormLabel>
//             <Select
//               name="order_type"
//               value={basicDetails.order_type}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Order Type</option>
//               <option value="SAMPLE">SAMPLE</option>
//               <option value="PRODUCTION">PRODUCTION</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice No</FormLabel>
//             <Input
//               type="text"
//               name="invoice_number"
//               value={basicDetails.invoice_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Invoice Attachment</FormLabel>
//             <Input
//               type="file"
//               name="invoice_attachment"
//               onChange={handleFileChange}
//             />
//             {renderAttachmentPreview()}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice Date</FormLabel>
//             <Input
//               type="date"
//               name="invoice_date"
//               value={basicDetails.invoice_date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Deadline</FormLabel>
//             <Input
//               type="date"
//               name="deadline"
//               value={basicDetails.deadline}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Physical Sample Received</FormLabel>
//             <Input
//               type="text"
//               name="physical_sample_received"
//               value={basicDetails.physical_sample_received}
//               onChange={handleInputChange}
//             />
//           </FormControl>

         

//           <FormControl isRequired>
//             <FormLabel>Estimated Date of Shipping</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_shipping"
//               value={basicDetails.estimated_date_of_shipping}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Estimated Payment Date</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_payment"
//               value={basicDetails.estimated_date_of_payment}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           {/* <FormControl isRequired>
//             <FormLabel>Production Status</FormLabel>
//             <Select
//               name="productionStatus"
//               value={basicDetails.productionStatus}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Production Status</option>
//               <option value="pending">Pending</option>
//               <option value="assigned">Assigned</option>
//               <option value="approved">Approved</option>
            
//             </Select>
//           </FormControl> */}

//           {/* <FormControl>
//             <FormLabel>Delivery Status</FormLabel>
//             <Input
//               type="text"
//               name="deliveryStatus"
//               value={basicDetails.deliveryStatus}
//               onChange={handleInputChange}
//               readOnly
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>DOCID</FormLabel>
//             <Input
//               type="text"
//               name="docId"
//               value={basicDetails.docId}
//               onChange={handleInputChange}
//             />
//           </FormControl> */}
//            <FormControl isRequired>
//             <FormLabel>Product</FormLabel>
//             <Input
//               type="text"
//               name="product"
//               value={basicDetails.product}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box textAlign="right" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default BasicDetails;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Input,
//   Select,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   Image,
//   SimpleGrid,
//   Link,
//   IconButton,
//   HStack,
// } from "@chakra-ui/react";
// import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

// const BasicDetails = () => {
//   const [clients, setClients] = useState([]);
//   const [items, setItems] = useState([]);
//   const [attachmentPreview, setAttachmentPreview] = useState(null);
//   const [attachmentName, setAttachmentName] = useState("");
//   const [basicDetails, setBasicDetails] = useState({
//     customer_id: "",
//     date: "",
//     order_type: "",
//     invoice_number: "",
//     invoice_attachment: null,
//     invoice_date: "",
//     deadline: "",
//     physical_sample_received: "",
//     estimated_date_of_shipping: "",
//     estimated_date_of_payment: "",
//     items: [],
//   });
//   const toast = useToast();

//   useEffect(() => {
//     fetchClients();
//     fetchItems();
//   }, []);

//   const fetchClients = async () => {
//     try {
//       const response = await axios.get("/customers");
//       const clients = response.data.data.customers || [];
//       setClients(clients);
//       console.log("response clients", clients);
//     } catch (error) {
//       console.error("Error fetching clients", error);
//       toast({ title: "Error fetching clients.", status: "error" });
//     }
//   };

//   const fetchItems = async () => {
//     try {
//       const response = await axios.get("/items");
//       const items = response.data.data.items || [];
//       setItems(items);
//       console.log("response items", items);
//     } catch (error) {
//       console.error("Error fetching items", error);
//       toast({ title: "Error fetching items.", status: "error" });
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     const file = files[0];
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: file,
//     }));
//     setAttachmentName(file.name);
//     setAttachmentPreview(URL.createObjectURL(file));
//   };

//   const handleProductChange = (index, e) => {
//     const { name, value } = e.target;
//     const updatedItems = [...basicDetails.items];
//     updatedItems[index][name] = value;
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       items: updatedItems,
//     }));
//   };

//   const handleAddProduct = () => {
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       items: [...prevDetails.items, { id: "", quantity: "", remarks: "" }],
//     }));
//   };

//   const handleRemoveProduct = (index) => {
//     const updatedItems = basicDetails.items.filter((_, i) => i !== index);
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       items: updatedItems,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
  
//     // Append all the fields to the formData
//     for (const key in basicDetails) {
//       if (key === "items") {
//         basicDetails[key].forEach((item, index) => {
//           for (const itemKey in item) {
//             formData.append(`items[${index}][${itemKey}]`, item[itemKey]);
//           }
//         });
//       } else if (key === "physical_sample_received") {
//         formData.append(key, basicDetails[key] === "yes" ? true : false);
//       } else {
//         formData.append(key, basicDetails[key]);
//       }
//     }
  
//     try {
//       await axios.post("/orders", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       toast({ title: "Details submitted successfully.", status: "success" });
//       // Reset form after submission
//       setBasicDetails({
//         customer_id: "",
//         date: "",
//         order_type: "",
//         invoice_number: "",
//         invoice_attachment: null,
//         invoice_date: "",
//         deadline: "",
//         physical_sample_received: "",
//         estimated_date_of_shipping: "",
//         estimated_date_of_payment: "",
//         items: [],
//       });
//       setAttachmentPreview(null);
//       setAttachmentName("");
//     } catch (error) {
//       console.error("Error submitting details", error);
//       toast({ title: "Error submitting details.", status: "error" });
//     }
//   };
  

//   const renderAttachmentPreview = () => {
//     if (!attachmentPreview) return null;

//     const fileExtension = attachmentName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={attachmentPreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={attachmentPreview} isExternal mt={2}>
//           Open {attachmentName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported file type: {attachmentName}</Text>;
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Customer Name</FormLabel>
//             <Select
//               name="customer_id"
//               value={basicDetails.customer_id}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Customer</option>
//               {clients.map((client) => (
//                 <option key={client.id} value={client.id}>
//                   {client.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={basicDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Type</FormLabel>
//             <Select
//               name="order_type"
//               value={basicDetails.order_type}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Order Type</option>
//               <option value="SAMPLE">SAMPLE</option>
//               <option value="PRODUCTION">PRODUCTION</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice No</FormLabel>
//             <Input
//               type="text"
//               name="invoice_number"
//               value={basicDetails.invoice_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Invoice Attachment</FormLabel>
//             <Input
//               type="file"
//               name="invoice_attachment"
//               onChange={handleFileChange}
//             />
//             {renderAttachmentPreview()}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice Date</FormLabel>
//             <Input
//               type="date"
//               name="invoice_date"
//               value={basicDetails.invoice_date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Deadline</FormLabel>
//             <Input
//               type="date"
//               name="deadline"
//               value={basicDetails.deadline}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Physical Sample Received</FormLabel>
//             <Select
//               name="physical_sample_received"
//               value={basicDetails.physical_sample_received}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Option</option>
//               <option value="yes">Yes</option>
//               <option value="no">No</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Estimated Date of Shipping</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_shipping"
//               value={basicDetails.estimated_date_of_shipping}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Estimated Payment Date</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_payment"
//               value={basicDetails.estimated_date_of_payment}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={4}>
//           <Text fontSize="lg" mb={2}>Item Details</Text>
//           {basicDetails.items.map((detail, index) => (
//             <HStack key={index} spacing={4} alignItems="flex-end">
//               <FormControl isRequired>
//                 <FormLabel>Item</FormLabel>
//                 <Select
//                   name="id"
//                   value={detail.id}
//                   onChange={(e) => handleProductChange(index, e)}
//                 >
//                   <option value="">Select Item</option>
//                   {items.map((item) => (
//                     <option key={item.id} value={item.id}>
//                       {item.name}
//                     </option>
//                   ))}
//                 </Select>
//               </FormControl>

//               <FormControl isRequired>
//                 <FormLabel>Quantity</FormLabel>
//                 <Input
//                   type="number"
//                   name="quantity"
//                   value={detail.quantity}
//                   onChange={(e) => handleProductChange(index, e)}
//                 />
//               </FormControl>

//               <FormControl>
//                 <FormLabel>Remarks</FormLabel>
//                 <Input
//                   type="text"
//                   name="remarks"
//                   value={detail.remarks}
//                   onChange={(e) => handleProductChange(index, e)}
//                 />
//               </FormControl>

//               <IconButton
//                 aria-label="Remove item"
//                 icon={<DeleteIcon />}
//                 colorScheme="red"
//                 onClick={() => handleRemoveProduct(index)}
//               />
//             </HStack>
//           ))}
//           <Button
//             mt={4}
//             leftIcon={<AddIcon />}
//             colorScheme="teal"
//             onClick={handleAddProduct}
//           >
//             Add More
//           </Button>
//         </Box>

//         <Box textAlign="right" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default BasicDetails;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Input,
//   Select,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   Image,
//   SimpleGrid,
//   Link,
//   IconButton,
//   HStack,
// } from "@chakra-ui/react";
// import { useHistory } from "react-router-dom";
// import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
// import apiInstance from "api/api";

// const BasicDetails = () => {
//   const [clients, setClients] = useState([]);
//   const [items, setItems] = useState([]);
//   const [attachmentPreview, setAttachmentPreview] = useState(null);
//   const [attachmentName, setAttachmentName] = useState("");
//   const [basicDetails, setBasicDetails] = useState({
//     customer_id: "",
//     date: "",
//     order_type: "",
//     invoice_number: "",
//     invoice_attachment: null,
//     invoice_date: "",
//     deadline: "",
//     physical_sample_received: "",
//     estimated_date_of_shipping: "",
//     estimated_date_of_payment: "",
//     items: [],
//   });
//   const toast = useToast();
//   const history = useHistory();
//   useEffect(() => {
//     fetchClients();
//     fetchItems();
//   }, []);

//   const fetchClients = async () => {
//     try {
//       const response = await apiInstance.get("/customers");
//       const clients = response.data.data.customers || [];
//       setClients(clients);
//       console.log("response clients", clients);
//     } catch (error) {
//       console.error("Error fetching clients", error);
//       toast({ title: "Error fetching clients.", status: "error" });
//     }
//   };

//   const fetchItems = async () => {
//     try {
//       const response = await apiInstance.get("/items");
//       const items = response.data.data.items || [];
//       setItems(items);
//       console.log("response items", items);
//     } catch (error) {
//       console.error("Error fetching items", error);
//       toast({ title: "Error fetching items.", status: "error" });
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     const file = files[0];
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: file,
//     }));
//     setAttachmentName(file.name);
//     setAttachmentPreview(URL.createObjectURL(file));
//   };

//   const handleProductChange = (index, e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => {
//       const updatedItems = [...prevDetails.items];
//       updatedItems[index] = { ...updatedItems[index], [name]: value };
//       return { ...prevDetails, items: updatedItems };
//     });
//   };

//   const handleAddProduct = () => {
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       items: [...prevDetails.items, { id: "", quantity: "", remarks: "" }],
//     }));
//   };

//   const handleRemoveProduct = (index) => {
//     setBasicDetails((prevDetails) => {
//       const updatedItems = prevDetails.items.filter((_, i) => i !== index);
//       return { ...prevDetails, items: updatedItems };
//     });
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   const formData = new FormData();

//   //   // Append all the fields to the formData
//   //   for (const key in basicDetails) {
//   //     if (key === "items") {
//   //       basicDetails[key].forEach((item, index) => {
//   //         for (const itemKey in item) {
//   //           formData.append(`items[${index}][${itemKey}]`, item[itemKey]);
//   //         }
//   //       });
//   //     } else if (key === "physical_sample_received") {
//   //       formData.append(key, basicDetails[key] === "yes" ? true : false);
//   //     } else {
//   //       formData.append(key, basicDetails[key]);
//   //     }
//   //   }

//   //   try {
//   //     await axios.post("/orders", formData, {
//   //       headers: {
//   //         "Content-Type": "multipart/form-data",
//   //       },
//   //     });
//   //     toast({ title: "Details submitted successfully.", status: "success" });
//   //     // Reset form after submission
//   //     setBasicDetails({
//   //       customer_id: "",
//   //       date: "",
//   //       order_type: "",
//   //       invoice_number: "",
//   //       invoice_attachment: null,
//   //       invoice_date: "",
//   //       deadline: "",
//   //       physical_sample_received: "",
//   //       estimated_date_of_shipping: "",
//   //       estimated_date_of_payment: "",
//   //       items: [],
//   //     });
//   //     setAttachmentPreview(null);
//   //     setAttachmentName("");
//   //   } catch (error) {
//   //     console.error("Error submitting details", error);
//   //     toast({ title: "Error submitting details.", status: "error" });
//   //   }
//   // };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
  
//     // Append all the fields to the formData
//     for (const key in basicDetails) {
//       if (key === "items") {
//         basicDetails[key].forEach((item, index) => {
//           for (const itemKey in item) {
//             formData.append(`items[${index}][${itemKey}]`, item[itemKey]);
//           }
//         });
//       } else if (key === "physical_sample_received") {
//         formData.append(key, basicDetails[key] === "yes" ? true : false);
//       } else {
//         formData.append(key, basicDetails[key]);
//       }
//     }
  
//     try {
//       const response = await apiInstance.post("/orders", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
  
//       const orderId = response.data.data.order.id;
  
//       // Store the order id in local storage
//       localStorage.setItem('orderId', orderId);
  
//       //toast({ title: "Details submitted successfully.", status: "success" });
  
//       // Reset form after submission
//       setBasicDetails({
//         customer_id: "",
//         date: "",
//         order_type: "",
//         invoice_number: "",
//         invoice_attachment: null,
//         invoice_date: "",
//         deadline: "",
//         physical_sample_received: "",
//         estimated_date_of_shipping: "",
//         estimated_date_of_payment: "",
//         items: [],
//       });
//       setAttachmentPreview(null);
//       setAttachmentName("");
//       history.push("/admin/create-order/customer-tech-pack");
//     } catch (error) {
//       console.error("Error submitting details", error);
//       //toast({ title: "Error submitting details.", status: "error" });
//     }
//   };
  


//   const renderAttachmentPreview = () => {
//     if (!attachmentPreview) return null;

//     const fileExtension = attachmentName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={attachmentPreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={attachmentPreview} isExternal mt={2}>
//           Open {attachmentName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported file type: {attachmentName}</Text>;
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "120px", xl: "120px" }}>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Customer Name</FormLabel>
//             <Select
//               name="customer_id"
//               value={basicDetails.customer_id}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Customer</option>
//               {clients.map((client) => (
//                 <option key={client.id} value={client.id}>
//                   {client.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={basicDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Type</FormLabel>
//             <Select
//               name="order_type"
//               value={basicDetails.order_type}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Order Type</option>
//               <option value="SAMPLE">SAMPLE</option>
//               <option value="PRODUCTION">PRODUCTION</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice No</FormLabel>
//             <Input
//               type="text"
//               name="invoice_number"
//               value={basicDetails.invoice_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Invoice Attachment</FormLabel>
//             <Input
//               type="file"
//               name="invoice_attachment"
//               onChange={handleFileChange}
//             />
//             {renderAttachmentPreview()}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice Date</FormLabel>
//             <Input
//               type="date"
//               name="invoice_date"
//               value={basicDetails.invoice_date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Deadline</FormLabel>
//             <Input
//               type="date"
//               name="deadline"
//               value={basicDetails.deadline}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Physical Sample Received</FormLabel>
//             <Select
//               name="physical_sample_received"
//               value={basicDetails.physical_sample_received}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Option</option>
//               <option value="yes">Yes</option>
//               <option value="no">No</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Estimated Date of Shipping</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_shipping"
//               value={basicDetails.estimated_date_of_shipping}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Payment Date</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_payment"
//               value={basicDetails.estimated_date_of_payment}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={4}>
//           <Text fontSize="lg" mb={2}>Item Details</Text>
//           {basicDetails.items.map((detail, index) => (
//             <HStack key={index} spacing={4} alignItems="flex-end">
//               <FormControl isRequired>
//                 <FormLabel>Item</FormLabel>
//                 <Select
//                   name="id"
//                   value={detail.id}
//                   onChange={(e) => handleProductChange(index, e)}
//                 >
//                   <option value="">Select Item</option>
//                   {items.map((item) => (
//                     <option key={item.id} value={item.id}>
//                       {item.name}
//                     </option>
//                   ))}
//                 </Select>
//               </FormControl>

//               <FormControl isRequired>
//                 <FormLabel>Quantity</FormLabel>
//                 <Input
//                   type="number"
//                   name="quantity"
//                   value={detail.quantity}
//                   onChange={(e) => handleProductChange(index, e)}
//                 />
//               </FormControl>

//               <FormControl>
//                 <FormLabel>Remarks</FormLabel>
//                 <Input
//                   type="text"
//                   name="remarks"
//                   value={detail.remarks}
//                   onChange={(e) => handleProductChange(index, e)}
//                 />
//               </FormControl>

//               <IconButton
//                 aria-label="Remove item"
//                 icon={<DeleteIcon />}
//                 colorScheme="red"
//                 onClick={() => handleRemoveProduct(index)}
//               />
//             </HStack>
//           ))}
//           <Button
//             mt={4}
//             leftIcon={<AddIcon />}
//             colorScheme="teal"
//             onClick={handleAddProduct}
//           >
//             Add More
//           </Button>
//         </Box>

//         <Box textAlign="right" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default BasicDetails;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Input,
//   Select,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   Image,
//   SimpleGrid,
//   Link,
//   IconButton,
//   HStack,
// } from "@chakra-ui/react";
// import { useHistory } from "react-router-dom";
// import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
// import apiInstance from "api/api";

// const BasicDetails = () => {
//   const [clients, setClients] = useState([]);
//   const [items, setItems] = useState([]);
//   const [attachmentPreview, setAttachmentPreview] = useState(null);
//   const [attachmentName, setAttachmentName] = useState("");
//   const [basicDetails, setBasicDetails] = useState({
//     customer_id: "",
//     date: "",
//     order_type: "",
//     invoice_number: "",
//     invoice_attachment: null,
//     invoice_date: "",
//     deadline: "",
//     physical_sample_received: "",
//     estimated_date_of_shipping: "",
//     estimated_date_of_payment: "",
//     items: [],
//   });
//   const toast = useToast();
//   const history = useHistory();

//   useEffect(() => {
//     fetchClients();
//     fetchItems();
//     const currentOrder = JSON.parse(localStorage.getItem("currentOrder"));
//     //const orderId=JSON.stringify(localStorage.setItem("orderId",currentOrder.))
//     if (currentOrder) {
//       setBasicDetails((prevDetails) => ({
//         ...prevDetails,
//         customer_id: currentOrder.customer.id,
//         date: "",
//         order_type: "",
//         invoice_number: "",
//         invoice_attachment: null,
//         invoice_date: "",
//         deadline: "",
//         physical_sample_received: "",
//         estimated_date_of_shipping: "",
//         estimated_date_of_payment: "",
//         items: [],
//       }));
//     }
//   }, []);

//   const fetchClients = async () => {
//     try {
//       const response = await apiInstance.get("/customers");
//       const clients = response.data.data.customers || [];
//       setClients(clients);
//       console.log("response clients", clients);
//     } catch (error) {
//       console.error("Error fetching clients", error);
//       toast({ title: "Error fetching clients.", status: "error" });
//     }
//   };

//   const fetchItems = async () => {
//     try {
//       const response = await apiInstance.get("/items");
//       const items = response.data.data.items || [];
//       setItems(items);
//       console.log("response items", items);
//     } catch (error) {
//       console.error("Error fetching items", error);
//       toast({ title: "Error fetching items.", status: "error" });
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     const file = files[0];
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: file,
//     }));
//     setAttachmentName(file.name);
//     setAttachmentPreview(URL.createObjectURL(file));
//   };

//   const handleProductChange = (index, e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => {
//       const updatedItems = [...prevDetails.items];
//       updatedItems[index] = { ...updatedItems[index], [name]: value };
//       return { ...prevDetails, items: updatedItems };
//     });
//   };

//   const handleAddProduct = () => {
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       items: [...prevDetails.items, { id: "", quantity: "", remarks: "" }],
//     }));
//   };

//   const handleRemoveProduct = (index) => {
//     setBasicDetails((prevDetails) => {
//       const updatedItems = prevDetails.items.filter((_, i) => i !== index);
//       return { ...prevDetails, items: updatedItems };
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
  
//     // Append all the fields to the formData
//     for (const key in basicDetails) {
//       if (key === "items") {
//         basicDetails[key].forEach((item, index) => {
//           for (const itemKey in item) {
//             formData.append(`items[${index}][${itemKey}]`, item[itemKey]);
//           }
//         });
//       } else if (key === "physical_sample_received") {
//         formData.append(key, basicDetails[key] === "yes" ? true : false);
//       } else {
//         formData.append(key, basicDetails[key]);
//       }
//     }
  
//     try {
//       const response = await apiInstance.post("/orders", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
  
//       const orderId = response.data.data.order.id;
  
//       // Store the order id in local storage
//       localStorage.setItem('orderId', orderId);
  
//       // Reset form after submission
//       setBasicDetails({
//         customer_id: "",
//         date: "",
//         order_type: "",
//         invoice_number: "",
//         invoice_attachment: null,
//         invoice_date: "",
//         deadline: "",
//         physical_sample_received: "",
//         estimated_date_of_shipping: "",
//         estimated_date_of_payment: "",
//         items: [],
//       });
//       setAttachmentPreview(null);
//       setAttachmentName("");
//       history.push("/admin/create-order/customer-tech-pack");
//     } catch (error) {
//       console.error("Error submitting details", error);
//       toast({ title: "Error submitting details.", status: "error" });
//     }
//   };

//   const renderAttachmentPreview = () => {
//     if (!attachmentPreview) return null;

//     const fileExtension = attachmentName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={attachmentPreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={attachmentPreview} isExternal mt={2}>
//           Open {attachmentName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported file type: {attachmentName}</Text>;
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "120px", xl: "120px" }}>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Customer Name</FormLabel>
//             <Select
//               name="customer_id"
//               value={basicDetails.customer_id}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Customer</option>
//               {clients.map((client) => (
//                 <option key={client.id} value={client.id}>
//                   {client.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={basicDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Type</FormLabel>
//             <Select
//               name="order_type"
//               value={basicDetails.order_type}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Order Type</option>
//               <option value="SAMPLE">SAMPLE</option>
//               <option value="PRODUCTION">PRODUCTION</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice No</FormLabel>
//             <Input
//               type="text"
//               name="invoice_number"
//               value={basicDetails.invoice_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Invoice Attachment</FormLabel>
//             <Input
//               type="file"
//               name="invoice_attachment"
//               onChange={handleFileChange}
//             />
//             {renderAttachmentPreview()}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice Date</FormLabel>
//             <Input
//               type="date"
//               name="invoice_date"
//               value={basicDetails.invoice_date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Deadline</FormLabel>
//             <Input
//               type="date"
//               name="deadline"
//               value={basicDetails.deadline}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Physical Sample Received</FormLabel>
//             <Select
//               name="physical_sample_received"
//               value={basicDetails.physical_sample_received}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Option</option>
//               <option value="yes">Yes</option>
//               <option value="no">No</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Estimated Date of Shipping</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_shipping"
//               value={basicDetails.estimated_date_of_shipping}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Payment Date</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_payment"
//               value={basicDetails.estimated_date_of_payment}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={4}>
//           <Text fontSize="lg" mb={2}>Item Details</Text>
//           {basicDetails.items.map((detail, index) => (
//             <HStack key={index} spacing={4} alignItems="flex-end">
//               <FormControl isRequired>
//                 <FormLabel>Item</FormLabel>
//                 <Select
//                   name="id"
//                   value={detail.id}
//                   onChange={(e) => handleProductChange(index, e)}
//                 >
//                   <option value="">Select Item</option>
//                   {items.map((item) => (
//                     <option key={item.id} value={item.id}>
//                       {item.name}
//                     </option>
//                   ))}
//                 </Select>
//               </FormControl>

//               <FormControl isRequired>
//                 <FormLabel>Quantity</FormLabel>
//                 <Input
//                   type="number"
//                   name="quantity"
//                   value={detail.quantity}
//                   onChange={(e) => handleProductChange(index, e)}
//                 />
//               </FormControl>

//               <FormControl>
//                 <FormLabel>Remarks</FormLabel>
//                 <Input
//                   type="text"
//                   name="remarks"
//                   value={detail.remarks}
//                   onChange={(e) => handleProductChange(index, e)}
//                 />
//               </FormControl>

//               <IconButton
//                 aria-label="Remove item"
//                 icon={<DeleteIcon />}
//                 colorScheme="red"
//                 onClick={() => handleRemoveProduct(index)}
//               />
//             </HStack>
//           ))}
//           <Button
//             mt={4}
//             leftIcon={<AddIcon />}
//             colorScheme="teal"
//             onClick={handleAddProduct}
//           >
//             Add More
//           </Button>
//         </Box>

//         <Box textAlign="right" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default BasicDetails;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Input,
//   Select,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   Image,
//   SimpleGrid,
//   Link,
//   IconButton,
//   HStack,
// } from "@chakra-ui/react";
// import { useHistory } from "react-router-dom";
// import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
// import apiInstance from "api/api";

// const BasicDetails = () => {
//   const [clients, setClients] = useState([]);
//   const [items, setItems] = useState([]);
//   const [attachmentPreview, setAttachmentPreview] = useState(null);
//   const [attachmentName, setAttachmentName] = useState("");
//   const [basicDetails, setBasicDetails] = useState({
//     customer_id: "",
//     date: "",
//     order_type: "",
//     invoice_number: "",
//     invoice_attachment: null,
//     invoice_date: "",
//     deadline: "",
//     physical_sample_received: "",
//     estimated_date_of_shipping: "",
//     estimated_date_of_payment: "",
//     items: [],
//   });
//   const [isEditable, setIsEditable] = useState(true);
//   const toast = useToast();
//   const history = useHistory();

//   useEffect(() => {
//     const fetchInitialData = async () => {
//       await fetchClients();
//       await fetchItems();
//     };

//     fetchInitialData();
//   }, []);

//   useEffect(() => {
//     const currentOrderId = localStorage.getItem("currentOrderId");
//     if (currentOrderId && items.length > 0) {
//       fetchOrderDetails(currentOrderId);
//     }
//   }, [items]);

//   const fetchClients = async () => {
//     try {
//       const response = await apiInstance.get("/customers");
//       const clients = response.data.data.customers || [];
//       setClients(clients);
//       console.log("Fetched clients:", clients);
//     } catch (error) {
//       console.error("Error fetching clients", error);
//       toast({ title: "Error fetching clients.", status: "error" });
//     }
//   };

//   const fetchItems = async () => {
//     try {
//       const response = await apiInstance.get("/items");
//       const items = response.data.data.items || [];
//       setItems(items);
//       console.log("Fetched items:", items);
//     } catch (error) {
//       console.error("Error fetching items", error);
//       toast({ title: "Error fetching items.", status: "error" });
//     }
//   };

//   const fetchOrderDetails = async (orderId) => {
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/basic-details`);
//       const basicDetails = response.data.data["basic-details"];

//       // Function to format date to YYYY-MM-DD
//       const formatDate = (dateString) => {
//         const parts = dateString.split(" ");
//         const datePart = parts.slice(-1)[0];
//         const [day, month, year] = datePart.split("-");
//         return `${year}-${month}-${day}`;
//       };

//       // Map item names to IDs
//       const mappedItems = basicDetails.items.map(item => {
//         const matchedItem = items.find(i => i.name === item.name);
//         return {
//           id: matchedItem ? matchedItem.id : "",
//           name: item.name,
//           quantity: item.quantity,
//           remarks: item.remarks,
//         };
//       });

//       setBasicDetails({
//         customer_id: basicDetails.customer.id,
//         date: formatDate(basicDetails.date),
//         order_type: basicDetails.order_type,
//         invoice_number: basicDetails.invoice_number,
//         invoice_attachment: basicDetails.invoice_attachment,
//         invoice_date: formatDate(basicDetails.invoice_date),
//         deadline: formatDate(basicDetails.deadline),
//         physical_sample_received: basicDetails.physical_sample_received ? "yes" : "no",
//         estimated_date_of_shipping: formatDate(basicDetails.estimated_date_of_shipping),
//         estimated_date_of_payment: formatDate(basicDetails.estimated_date_of_payment),
//         items: mappedItems,
//       });

//       console.log("Fetched order details:", basicDetails);
//       console.log("Mapped items:", mappedItems);

//       setAttachmentPreview(basicDetails.invoice_attachment?.url || null);
//       setIsEditable(false);
//     } catch (error) {
//       console.error("Error fetching order details", error);
//       toast({ title: "Error fetching order details.", status: "error" });
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     const file = files[0];
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: file,
//     }));
//     setAttachmentName(file.name);
//     setAttachmentPreview(URL.createObjectURL(file));
//   };

//   const handleProductChange = (index, e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => {
//       const updatedItems = [...prevDetails.items];
//       updatedItems[index] = { ...updatedItems[index], [name]: value };
//       return { ...prevDetails, items: updatedItems };
//     });
//   };

//   const handleAddProduct = () => {
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       items: [...prevDetails.items, { id: "", quantity: "", remarks: "" }],
//     }));
//   };

//   const handleRemoveProduct = (index) => {
//     setBasicDetails((prevDetails) => {
//       const updatedItems = prevDetails.items.filter((_, i) => i !== index);
//       return { ...prevDetails, items: updatedItems };
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
  
//     // Append all the fields to the formData
//     for (const key in basicDetails) {
//       if (key === "items") {
//         basicDetails[key].forEach((item, index) => {
//           for (const itemKey in item) {
//             formData.append(`items[${index}][${itemKey}]`, item[itemKey]);
//           }
//         });
//       } else if (key === "physical_sample_received") {
//         formData.append(key, basicDetails[key] === "yes" ? true : false);
//       } else {
//         formData.append(key, basicDetails[key]);
//       }
//     }
  
//     try {
//       const response = await apiInstance.post("/orders", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
  
//       const orderId = response.data.data.order.id;
  
//       // Store the order id in local storage
//       localStorage.setItem('currentOrderId', orderId);
  
//       // Reset form after submission
//       setBasicDetails({
//         customer_id: "",
//         date: "",
//         order_type: "",
//         invoice_number: "",
//         invoice_attachment: null,
//         invoice_date: "",
//         deadline: "",
//         physical_sample_received: "",
//         estimated_date_of_shipping: "",
//         estimated_date_of_payment: "",
//         items: [],
//       });
//       setAttachmentPreview(null);
//       setAttachmentName("");
//       history.push("/admin/create-order/customer-tech-pack");
//     } catch (error) {
//       console.error("Error submitting details", error);
//       toast({ title: "Error submitting details.", status: "error" });
//     }
//   };

//   const renderAttachmentPreview = () => {
//     if (!attachmentPreview) return null;

//     const fileExtension = attachmentName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={attachmentPreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={attachmentPreview} isExternal mt={2}>
//           Open {attachmentName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported file type: {attachmentName}</Text>;
//     }
//   };

//   // Render a loading state until items are fetched
//   if (items.length === 0) {
//     return <Text>Loading...</Text>;
//   }

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "120px", xl: "80px" }}>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Customer Name</FormLabel>
//             <Select
//               name="customer_id"
//               value={basicDetails.customer_id}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             >
//               <option value="">Select Customer</option>
//               {clients.map((client) => (
//                 <option key={client.id} value={client.id}>
//                   {client.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={basicDetails.date}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Type</FormLabel>
//             <Select
//               name="order_type"
//               value={basicDetails.order_type}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             >
//               <option value="">Select Order Type</option>
//               <option value="SAMPLE">SAMPLE</option>
//               <option value="PRODUCTION">PRODUCTION</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice No</FormLabel>
//             <Input
//               type="text"
//               name="invoice_number"
//               value={basicDetails.invoice_number}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Invoice Attachment</FormLabel>
//             <Input
//               type="file"
//               name="invoice_attachment"
//               onChange={handleFileChange}
//               isDisabled={!isEditable}
//             />
//             {renderAttachmentPreview()}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice Date</FormLabel>
//             <Input
//               type="date"
//               name="invoice_date"
//               value={basicDetails.invoice_date}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Deadline</FormLabel>
//             <Input
//               type="date"
//               name="deadline"
//               value={basicDetails.deadline}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Physical Sample Received</FormLabel>
//             <Select
//               name="physical_sample_received"
//               value={basicDetails.physical_sample_received}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             >
//               <option value="">Select Option</option>
//               <option value="yes">Yes</option>
//               <option value="no">No</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Estimated Date of Shipping</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_shipping"
//               value={basicDetails.estimated_date_of_shipping}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Payment Date</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_payment"
//               value={basicDetails.estimated_date_of_payment}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={4}>
//           <Text fontSize="lg" mb={2}>Item Details</Text>
//           {basicDetails.items.map((detail, index) => (
//             <HStack key={index} spacing={4} alignItems="flex-end">
//               <FormControl isRequired>
//                 <FormLabel>Item</FormLabel>
//                 <Select
//                   name="id"
//                   value={detail.id}
//                   onChange={(e) => handleProductChange(index, e)}
//                   isDisabled={!isEditable}
//                 >
//                   <option value="">Select Item</option>
//                   {items.map((item) => (
//                     <option key={item.id} value={item.id}>
//                       {item.name}
//                     </option>
//                   ))}
//                 </Select>
//               </FormControl>

//               <FormControl isRequired>
//                 <FormLabel>Quantity</FormLabel>
//                 <Input
//                   type="number"
//                   name="quantity"
//                   value={detail.quantity}
//                   onChange={(e) => handleProductChange(index, e)}
//                   isDisabled={!isEditable}
//                 />
//               </FormControl>

//               <FormControl>
//                 <FormLabel>Remarks</FormLabel>
//                 <Input
//                   type="text"
//                   name="remarks"
//                   value={detail.remarks}
//                   onChange={(e) => handleProductChange(index, e)}
//                   isDisabled={!isEditable}
//                 />
//               </FormControl>

//               {isEditable && (
//                 <IconButton
//                   aria-label="Remove item"
//                   icon={<DeleteIcon />}
//                   colorScheme="red"
//                   onClick={() => handleRemoveProduct(index)}
//                 />
//               )}
//             </HStack>
//           ))}
//           {isEditable && (
//             <Button
//               mt={4}
//               leftIcon={<AddIcon />}
//               colorScheme="teal"
//               onClick={handleAddProduct}
//             >
//               Items
//             </Button>
//           )}
//         </Box>

//         {isEditable && (
//           <Box textAlign="right" mt={4}>
//             <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//               Submit
//             </Button>
//           </Box>
//         )}
//       </form>
//     </Box>
//   );
// };

// export default BasicDetails;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Input,
//   Select,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   Image,
//   SimpleGrid,
//   Link,
//   IconButton,
//   HStack,
//   Spinner,
// } from "@chakra-ui/react";
// import { useHistory } from "react-router-dom";
// import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
// import apiInstance from "api/api";

// const BasicDetails = () => {
//   const [clients, setClients] = useState([]);
//   const [items, setItems] = useState([]);
//   const [attachmentPreview, setAttachmentPreview] = useState(null);
//   const [attachmentName, setAttachmentName] = useState("");
//   const [basicDetails, setBasicDetails] = useState({
//     customer_id: "",
//     date: "",
//     order_type: "",
//     invoice_number: "",
//     invoice_attachment: null,
//     invoice_date: "",
//     deadline: "",
//     physical_sample_received: "",
//     estimated_date_of_shipping: "",
//     estimated_date_of_payment: "",
//     items: [],
//   });
//   const [isEditable, setIsEditable] = useState(true);
//   const [isLoading, setIsLoading] = useState(true); // State to manage loading
//   const toast = useToast();
//   const history = useHistory();

//   useEffect(() => {
//     const fetchInitialData = async () => {
//       try {
//         await fetchClients();
//         await fetchItems();
//         setIsLoading(false); // Set loading to false after data is fetched
//       } catch (error) {
//         console.error("Error fetching initial data", error);
//         toast({ title: "Error fetching initial data.", status: "error" });
//         setIsLoading(false);
//       }
//     };

//     fetchInitialData();
//   }, []);

//   useEffect(() => {
//     const currentOrderId = localStorage.getItem("currentOrderId");
//     if (currentOrderId && items.length > 0) {
//       fetchOrderDetails(currentOrderId);
//     }
//   }, [items]);

//   const fetchClients = async () => {
//     try {
//       const response = await apiInstance.get("/customers");
//       const clients = response.data.data.customers || [];
//       setClients(clients);
//       console.log("Fetched clients:", clients);
//     } catch (error) {
//       console.error("Error fetching clients", error);
//       toast({ title: "Error fetching clients.", status: "error" });
//     }
//   };

//   const fetchItems = async () => {
//     try {
//       const response = await apiInstance.get("/items");
//       const items = response.data.data.items || [];
//       setItems(items);
//       console.log("Fetched items:", items);
//     } catch (error) {
//       console.error("Error fetching items", error);
//       toast({ title: "Error fetching items.", status: "error" });
//     }
//   };

//   const fetchOrderDetails = async (orderId) => {
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/basic-details`);
//       const basicDetails = response.data.data["basic-details"];

//       const formatDate = (dateString) => {
//         const parts = dateString.split(" ");
//         const datePart = parts.slice(-1)[0];
//         const [day, month, year] = datePart.split("-");
//         return `${year}-${month}-${day}`;
//       };

//       const mappedItems = basicDetails.items.map(item => {
//         const matchedItem = items.find(i => i.name === item.name);
//         return {
//           id: matchedItem ? matchedItem.id : "",
//           name: item.name,
//           quantity: item.quantity,
//           remarks: item.remarks,
//         };
//       });

//       setBasicDetails({
//         customer_id: basicDetails.customer.id,
//         date: formatDate(basicDetails.date),
//         order_type: basicDetails.order_type,
//         invoice_number: basicDetails.invoice_number,
//         invoice_attachment: basicDetails.invoice_attachment,
//         invoice_date: formatDate(basicDetails.invoice_date),
//         deadline: formatDate(basicDetails.deadline),
//         physical_sample_received: basicDetails.physical_sample_received ? "yes" : "no",
//         estimated_date_of_shipping: formatDate(basicDetails.estimated_date_of_shipping),
//         estimated_date_of_payment: formatDate(basicDetails.estimated_date_of_payment),
//         items: mappedItems,
//       });

//       console.log("Fetched order details:", basicDetails);
//       console.log("Mapped items:", mappedItems);

//       setAttachmentPreview(basicDetails.invoice_attachment?.url || null);
//       setIsEditable(false);
//     } catch (error) {
//       console.error("Error fetching order details", error);
//       toast({ title: "Error fetching order details.", status: "error" });
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     const file = files[0];
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: file,
//     }));
//     setAttachmentName(file.name);
//     setAttachmentPreview(URL.createObjectURL(file));
//   };

//   const handleProductChange = (index, e) => {
//     const { name, value } = e.target;
//     setBasicDetails((prevDetails) => {
//       const updatedItems = [...prevDetails.items];
//       updatedItems[index] = { ...updatedItems[index], [name]: value };
//       return { ...prevDetails, items: updatedItems };
//     });
//   };

//   const handleAddProduct = () => {
//     setBasicDetails((prevDetails) => ({
//       ...prevDetails,
//       items: [...prevDetails.items, { id: "", quantity: "", remarks: "" }],
//     }));
//   };

//   const handleRemoveProduct = (index) => {
//     setBasicDetails((prevDetails) => {
//       const updatedItems = prevDetails.items.filter((_, i) => i !== index);
//       return { ...prevDetails, items: updatedItems };
//     });
//   };


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
  
//     for (const key in basicDetails) {
//       if (key === "items") {
//         basicDetails[key].forEach((item, index) => {
//           for (const itemKey in item) {
//             formData.append(`items[${index}][${itemKey}]`, item[itemKey]);
//           }
//         });
//       } else if (key === "physical_sample_received") {
//         formData.append(key, basicDetails[key] === "yes" ? true : false);
//       } else {
//         formData.append(key, basicDetails[key]);
//       }
//     }
  
//     try {
//       const currentOrderId = localStorage.getItem("currentOrderId");
//       let response;
  
//       if (currentOrderId) {
//         // It's an edit request
//         response = await apiInstance.put(`/orders/${currentOrderId}/basic-details`, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         });
//       } else {
//         // It's a create request
//         response = await apiInstance.post("/orders", formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         });
//         localStorage.setItem('currentOrderId', response.data.data.order.id);
//       }
  
//       history.push("/admin/create-order/customer-tech-pack");
//     } catch (error) {
//       console.error("Error submitting details", error);
//       toast({ title: "Error submitting details.", status: "error" });
//     }
//   };
  

// const renderAttachmentPreview = () => {
//   if (!attachmentPreview) return null;

//   // Extract the file extension from the URL
//   const fileExtension = attachmentPreview.split('.').pop().split('?')[0].toLowerCase();

//   if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//     return <Image src={attachmentPreview} alt="Attachment Preview" mt={2} />;
//   } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//     return (
//       <Link href={attachmentPreview} isExternal mt={2}>
//         Open {attachmentName || "File"}
//       </Link>
//     );
//   } else {
//     return <Text mt={2}>Unsupported file type: {attachmentName || "Unknown"}</Text>;
//   }
// };


//   if (isLoading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <Spinner size="xl" />
//         <Text ml={4}>Loading...</Text>
//       </Box>
//     );
//   }

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "120px", xl: "80px" }}>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Customer Name</FormLabel>
//             <Select
//               name="customer_id"
//               value={basicDetails.customer_id}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             >
//               <option value="">Select Customer</option>
//               {clients.map((client) => (
//                 <option key={client.id} value={client.id}>
//                   {client.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={basicDetails.date}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Order Type</FormLabel>
//             <Select
//               name="order_type"
//               value={basicDetails.order_type}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             >
//               <option value="">Select Order Type</option>
//               <option value="SAMPLE">SAMPLE</option>
//               <option value="PRODUCTION">PRODUCTION</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice No</FormLabel>
//             <Input
//               type="text"
//               name="invoice_number"
//               value={basicDetails.invoice_number}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Invoice Attachment</FormLabel>
//             <Input
//               type="file"
//               name="invoice_attachment"
//               onChange={handleFileChange}
//               isDisabled={!isEditable}
//             />
//             {renderAttachmentPreview()}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Invoice Date</FormLabel>
//             <Input
//               type="date"
//               name="invoice_date"
//               value={basicDetails.invoice_date}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Deadline</FormLabel>
//             <Input
//               type="date"
//               name="deadline"
//               value={basicDetails.deadline}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Physical Sample Received</FormLabel>
//             <Select
//               name="physical_sample_received"
//               value={basicDetails.physical_sample_received}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             >
//               <option value="">Select Option</option>
//               <option value="yes">Yes</option>
//               <option value="no">No</option>
//             </Select>
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Estimated Date of Shipping</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_shipping"
//               value={basicDetails.estimated_date_of_shipping}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Payment Date</FormLabel>
//             <Input
//               type="date"
//               name="estimated_date_of_payment"
//               value={basicDetails.estimated_date_of_payment}
//               onChange={handleInputChange}
//               isDisabled={!isEditable}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={4}>
//           <Text fontSize="lg" mb={2}>Item Details</Text>
//           {basicDetails.items.map((detail, index) => (
//             <HStack key={index} spacing={4} alignItems="flex-end">
//               <FormControl isRequired>
//                 <FormLabel>Item</FormLabel>
//                 <Select
//                   name="id"
//                   value={detail.id}
//                   onChange={(e) => handleProductChange(index, e)}
//                   isDisabled={!isEditable}
//                 >
//                   <option value="">Select Item</option>
//                   {items.map((item) => (
//                     <option key={item.id} value={item.id}>
//                       {item.name}
//                     </option>
//                   ))}
//                 </Select>
//               </FormControl>

//               <FormControl isRequired>
//                 <FormLabel>Quantity</FormLabel>
//                 <Input
//                   type="number"
//                   name="quantity"
//                   value={detail.quantity}
//                   onChange={(e) => handleProductChange(index, e)}
//                   isDisabled={!isEditable}
//                 />
//               </FormControl>

//               <FormControl>
//                 <FormLabel>Remarks</FormLabel>
//                 <Input
//                   type="text"
//                   name="remarks"
//                   value={detail.remarks}
//                   onChange={(e) => handleProductChange(index, e)}
//                   isDisabled={!isEditable}
//                 />
//               </FormControl>

//               {isEditable && (
//                 <IconButton
//                   aria-label="Remove item"
//                   icon={<DeleteIcon />}
//                   colorScheme="red"
//                   onClick={() => handleRemoveProduct(index)}
//                 />
//               )}
//             </HStack>
//           ))}
//           {isEditable && (
//             <Button
//               mt={4}
//               leftIcon={<AddIcon />}
//               colorScheme="teal"
//               onClick={handleAddProduct}
//             >
//               Items
//             </Button>
//           )}
//         </Box>

//         {isEditable && (
//           <Box textAlign="right" mt={4}>
//             <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//               Submit
//             </Button>
//           </Box>
//         )}
//       </form>
//     </Box>
//   );
// };

// export default BasicDetails;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Select,
  Text,
  useToast,
  FormControl,
  FormLabel,
  Image,
  SimpleGrid,
  Link,
  IconButton,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import apiInstance from "api/api";

const BasicDetails = () => {
  const [clients, setClients] = useState([]);
  const [items, setItems] = useState([]);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [attachmentName, setAttachmentName] = useState("");
  const [basicDetails, setBasicDetails] = useState({
    customer_id: "",
    date: "",
    order_type: "",
    invoice_number: "",
    invoice_attachment: null,
    invoice_date: "",
    deadline: "",
    physical_sample_received: "",
    estimated_date_of_shipping: "",
    estimated_date_of_payment: "",
    items: [],
  });
  const [isEditable, setIsEditable] = useState(false); // Default to false
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const history = useHistory();

  // Check for permissions on component mount
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
    // Check if "basicdetails.edit" permission exists
    if (permissions.includes("orders.basic-details.edit")) {
      setIsEditable(true);
    }
  }, []);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
    if (permissions.includes("orders.basic-details.edit")) {
      setIsEditable(true);
    }
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await fetchClients();
        await fetchItems();
        setIsLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching initial data", error);
        toast({ title: "Error fetching initial data.", status: "error" });
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    const currentOrderId = localStorage.getItem("currentOrderId");
    if (currentOrderId && items.length > 0) {
      fetchOrderDetails(currentOrderId);
    }
  }, [items]);

  const fetchClients = async () => {
    try {
      const response = await apiInstance.get("/customers");
      const clients = response.data.data.customers || [];
      setClients(clients);
    } catch (error) {
      console.error("Error fetching clients", error);
      toast({ title: "Error fetching clients.", status: "error" });
    }
  };

  const fetchItems = async () => {
    try {
      const response = await apiInstance.get("/items");
      const items = response.data.data.items || [];
      setItems(items);
    } catch (error) {
      console.error("Error fetching items", error);
      toast({ title: "Error fetching items.", status: "error" });
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await apiInstance.get(`/orders/${orderId}/basic-details`);
      const basicDetails = response.data.data["basic-details"];

      const formatDate = (dateString) => {
        const parts = dateString.split(" ");
        const datePart = parts.slice(-1)[0];
        const [day, month, year] = datePart.split("-");
        return `${year}-${month}-${day}`;
      };

      const mappedItems = basicDetails.items.map(item => {
        const matchedItem = items.find(i => i.name === item.name);
        return {
          id: matchedItem ? matchedItem.id : "",
          name: item.name,
          quantity: item.quantity,
          remarks: item.remarks,
        };
      });

      setBasicDetails({
        customer_id: basicDetails.customer.id,
        date: formatDate(basicDetails.date),
        order_type: basicDetails.order_type,
        invoice_number: basicDetails.invoice_number,
        invoice_attachment: basicDetails.invoice_attachment,
        invoice_date: formatDate(basicDetails.invoice_date),
        deadline: formatDate(basicDetails.deadline),
        physical_sample_received: basicDetails.physical_sample_received ? "yes" : "no",
        estimated_date_of_shipping: formatDate(basicDetails.estimated_date_of_shipping),
        estimated_date_of_payment: formatDate(basicDetails.estimated_date_of_payment),
        items: mappedItems,
      });

      setAttachmentPreview(basicDetails.invoice_attachment?.url || null);
    } catch (error) {
      console.error("Error fetching order details", error);
      toast({ title: "Error fetching order details.", status: "error" });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBasicDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setBasicDetails((prevDetails) => ({
      ...prevDetails,
      [name]: file,
    }));
    setAttachmentName(file.name);
    setAttachmentPreview(URL.createObjectURL(file));
  };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    setBasicDetails((prevDetails) => {
      const updatedItems = [...prevDetails.items];
      updatedItems[index] = { ...updatedItems[index], [name]: value };
      return { ...prevDetails, items: updatedItems };
    });
  };

  const handleAddProduct = () => {
    setBasicDetails((prevDetails) => ({
      ...prevDetails,
      items: [...prevDetails.items, { id: "", quantity: "", remarks: "" }],
    }));
  };

  const handleRemoveProduct = (index) => {
    setBasicDetails((prevDetails) => {
      const updatedItems = prevDetails.items.filter((_, i) => i !== index);
      return { ...prevDetails, items: updatedItems };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const key in basicDetails) {
      if (key === "items") {
        basicDetails[key].forEach((item, index) => {
          for (const itemKey in item) {
            formData.append(`items[${index}][${itemKey}]`, item[itemKey]);
          }
        });
      } else if (key === "physical_sample_received") {
        formData.append(key, basicDetails[key] === "yes" ? true : false);
      } else {
        formData.append(key, basicDetails[key]);
      }
    }

    try {
      const currentOrderId = localStorage.getItem("currentOrderId");
      let response;
      
      if (currentOrderId) {
        formData.append("_method", "put")
        response = await apiInstance.post(`/orders/${currentOrderId}/basic-details`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        response = await apiInstance.post("/orders", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        localStorage.setItem('currentOrderId', response.data.data.order.id);
      }

      history.push("/admin/create-order/customer-tech-pack");
    } catch (error) {
      console.error("Error submitting details", error);
      toast({ title: "Error submitting details.", status: "error" });
    }
  };

  
  const renderAttachmentPreview = () => {
    if (!attachmentPreview) return null;
    const fileExtension = attachmentPreview.split('.').pop().split('?')[0].toLowerCase();

    if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <Image src={attachmentPreview} alt="Attachment Preview" mt={2} width="200px" height="200px" objectFit="cover" />;
    } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
      return (
        <Link href={attachmentPreview} isExternal mt={2}>
          Open {attachmentName || "File"}
        </Link>
      );
    } else {
      return <Text mt={2}>Unsupported file type: {attachmentName || "Unknown"}</Text>;
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
        <Text ml={4}>Loading...</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "120px", xl: "80px" }}>
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <FormControl isRequired>
            <FormLabel>Customer Name</FormLabel>
            <Select
              name="customer_id"
              value={basicDetails.customer_id}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            >
              <option value="">Select Customer</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Order Date</FormLabel>
            <Input
              type="date"
              name="date"
              value={basicDetails.date}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Order Type</FormLabel>
            <Select
              name="order_type"
              value={basicDetails.order_type}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            >
              <option value="">Select Order Type</option>
              <option value="SAMPLE">SAMPLE</option>
              <option value="PRODUCTION">PRODUCTION</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Invoice No</FormLabel>
            <Input
              type="text"
              name="invoice_number"
              value={basicDetails.invoice_number}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Invoice Attachment</FormLabel>
            <Input
              type="file"
              name="invoice_attachment"
              onChange={handleFileChange}
              isDisabled={!isEditable}
            />
            {renderAttachmentPreview()}
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Invoice Date</FormLabel>
            <Input
              type="date"
              name="invoice_date"
              value={basicDetails.invoice_date}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Deadline</FormLabel>
            <Input
              type="date"
              name="deadline"
              value={basicDetails.deadline}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Physical Sample Received</FormLabel>
            <Select
              name="physical_sample_received"
              value={basicDetails.physical_sample_received}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            >
              <option value="">Select Option</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Estimated Date of Shipping</FormLabel>
            <Input
              type="date"
              name="estimated_date_of_shipping"
              value={basicDetails.estimated_date_of_shipping}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Payment Date</FormLabel>
            <Input
              type="date"
              name="estimated_date_of_payment"
              value={basicDetails.estimated_date_of_payment}
              onChange={handleInputChange}
              isDisabled={!isEditable}
            />
          </FormControl>
        </SimpleGrid>

        <Box mt={4}>
          <Text fontSize="lg" mb={2}>Item Details</Text>
          {basicDetails.items.map((detail, index) => (
            <HStack key={index} spacing={4} alignItems="flex-end">
              <FormControl isRequired>
                <FormLabel>Item</FormLabel>
                <Select
                  name="id"
                  value={detail.id}
                  onChange={(e) => handleProductChange(index, e)}
                  isDisabled={!isEditable}
                >
                  <option value="">Select Item</option>
                  {items.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Quantity</FormLabel>
                <Input
                  type="number"
                  name="quantity"
                  value={detail.quantity}
                  onChange={(e) => handleProductChange(index, e)}
                  isDisabled={!isEditable}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Remarks</FormLabel>
                <Input
                  type="text"
                  name="remarks"
                  value={detail.remarks}
                  onChange={(e) => handleProductChange(index, e)}
                  isDisabled={!isEditable}
                />
              </FormControl>

              {isEditable && (
                <IconButton
                  aria-label="Remove item"
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  onClick={() => handleRemoveProduct(index)}
                />
              )}
            </HStack>
          ))}
          {isEditable && (
            <Button
              mt={4}
              leftIcon={<AddIcon />}
              colorScheme="teal"
              onClick={handleAddProduct}
            >
              Items
            </Button>
          )}
        </Box>

        {isEditable && (
          <Box textAlign="right" mt={4}>
            <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
              Submit
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default BasicDetails;