// AccessDeniedDialog.js
import React, { useState, useEffect, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import eventEmitter from "./eventEmitter"; // Adjust the path as needed

const AccessDeniedDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();

  useEffect(() => {
    const handleShowDialog = () => setIsOpen(true);
    eventEmitter.on("show403Dialog", handleShowDialog);

    return () => {
      eventEmitter.off("show403Dialog", handleShowDialog);
    };
  }, []);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Access Denied
          </AlertDialogHeader>
          <AlertDialogBody>
            You do not have access to this page. Please contact the administrator if you want access.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AccessDeniedDialog;
