// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   Textarea,
//   SimpleGrid,
//   Flex,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
// } from "@chakra-ui/react";
// import axios from 'axios';
// import apiInstance from 'api/api';

// const DeliveryTab = () => {
//   const [deliveryDetails, setDeliveryDetails] = useState({
//     date: '',
//     awb_tracking_number: '',
//     remarks: '',
//   });
//   const [deliveryList, setDeliveryList] = useState([]);
//   const [items, setItems] = useState([]);
//   const [deliveryQuantities, setDeliveryQuantities] = useState({});
//   //const //toast = useToast();

//   useEffect(() => {

//     const fetchData = async () => {
//       const orderId=localStorage.getItem("orderId")||1;
//       try {
//         const response = await apiInstance.get(`/orders/${orderId}/items`);
//         setItems(response.data.data.items);
//       } catch (error) {
//         console.error('Error fetching items:', error);
//       }
//     };

//     fetchData();

//     const storedDeliveryList = JSON.parse(localStorage.getItem("deliveryList")) || [];
//     setDeliveryList(storedDeliveryList);
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setDeliveryDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleQuantityChange = (e, sku) => {
//     const { value } = e.target;
//     setDeliveryQuantities((prevQuantities) => ({
//       ...prevQuantities,
//       [sku]: value,
//     }));
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   const filledItems = items
//   //     .map((item, index) => ({
//   //       ...item,
//   //       serialNumber: index + 1,
//   //       delivered_quantity: deliveryQuantities[item.sku] || 0,
//   //     }))
//   //     .filter(item => deliveryQuantities[item.sku] !== undefined && deliveryQuantities[item.sku] !== '');

//   //   if (filledItems.length === 0) {
//   //     //toast({ title: "Please fill at least one item's quantity.", status: "error" });
//   //     return;
//   //   }

//   //   const newDelivery = {
//   //     ...deliveryDetails,
//   //     items: filledItems,
//   //     id: Date.now(),
//   //   };
//   //   const updatedDeliveryList = [...deliveryList, newDelivery];
//   //   setDeliveryList(updatedDeliveryList);
//   //   localStorage.setItem("deliveryList", JSON.stringify(updatedDeliveryList));
//   //   setDeliveryDetails({
//   //     date: '',
//   //     awb_tracking_number: '',
//   //     remarks: '',
//   //   });
//   //   setDeliveryQuantities({});
//   //   //toast({ title: "Details submitted.", status: "success" });

//   //   const payload = {
//   //     date: deliveryDetails.date,
//   //     delivery_id: newDelivery.id,
//   //     items: filledItems.map(item => ({
//   //       quantity: item.delivered_quantity,
//   //       id: item.serialNumber,
//   //     })),
//   //   };

//   //   try {
//   //     const response = await axios.post('/orders/4/delivery', payload);
//   //     if (response.status === 200) {
//   //       //toast({ title: "Delivery submitted successfully.", status: "success" });
//   //     } else {
//   //       //toast({ title: "Failed to submit delivery.", status: "error" });
//   //     }
//   //   } catch (error) {
//   //     console.error('Error submitting delivery:', error);
//   //     //toast({ title: "Failed to submit delivery.", status: "error" });
//   //   }

//   //   console.log('Payload:', payload);
//   // };



//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   const filledItems = items
//   //     .map((item, index) => ({
//   //       ...item,
//   //       serialNumber: index + 1,
//   //       delivered_quantity: deliveryQuantities[item.sku] || 0,
//   //     }))
//   //     .filter(item => deliveryQuantities[item.sku] !== undefined && deliveryQuantities[item.sku] !== '');
  
//   //   if (filledItems.length === 0) {
//   //     //toast({ title: "Please fill at least one item's quantity.", status: "error" });
//   //     return;
//   //   }
  
//   //   const newDelivery = {
//   //     ...deliveryDetails,
//   //     items: filledItems,
//   //     id: Date.now(),
//   //   };
//   //   const updatedDeliveryList = [...deliveryList, newDelivery];
//   //   setDeliveryList(updatedDeliveryList);
//   //   localStorage.setItem("deliveryList", JSON.stringify(updatedDeliveryList));
//   //   setDeliveryDetails({
//   //     date: '',
//   //     awb_tracking_number: '',
//   //     remarks: '',
//   //   });
//   //   setDeliveryQuantities({});
//   //   //toast({ title: "Details submitted.", status: "success" });
  
//   //   const formData = new FormData();
//   //   formData.append("date", deliveryDetails.date);
//   //   formData.append("delivery_id", newDelivery.id);
  
//   //   filledItems.forEach((item, index) => {
//   //     formData.append(`items[${index}][quantity]`, item.delivered_quantity);
//   //     formData.append(`items[${index}][id]`, item.serialNumber);
//   //   });
  
//   //   try {
//   //     const response = await axios.post('/orders/4/delivery', formData, {
//   //       headers: {
//   //         'Content-Type': 'multipart/form-data',
//   //       },
//   //     });
//   //     if (response.status === 200) {
//   //       //toast({ title: "Delivery submitted successfully.", status: "success" });
//   //     } else {
//   //       //toast({ title: "Failed to submit delivery.", status: "error" });
//   //     }
//   //   } catch (error) {
//   //     console.error('Error submitting delivery:', error);
//   //     //toast({ title: "Failed to submit delivery.", status: "error" });
//   //   }
  
//   //   console.log('Payload:', filledItems);
//   // };
  
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const filledItems = items
//       .map((item, index) => ({
//         ...item,
//         serialNumber: index + 1,
//         delivered_quantity: deliveryQuantities[item.sku] || 0,
//       }))
//       .filter(item => deliveryQuantities[item.sku] !== undefined && deliveryQuantities[item.sku] !== '');
  
//     if (filledItems.length === 0) {
//       // //toast({ title: "Please fill at least one item's quantity.", status: "error" });
//       return;
//     }
  
//     const newDelivery = {
//       ...deliveryDetails,
//       items: filledItems,
//       id: Date.now(),
//     };
//     const updatedDeliveryList = [...deliveryList, newDelivery];
//     setDeliveryList(updatedDeliveryList);
//     localStorage.setItem("deliveryList", JSON.stringify(updatedDeliveryList));
//     setDeliveryDetails({
//       date: '',
//       awb_tracking_number: '',
//       remarks: '',
//     });
//     setDeliveryQuantities({});
//     // //toast({ title: "Details submitted.", status: "success" });
  
//     const formData = new FormData();
//     formData.append("date", deliveryDetails.date);
//     formData.append("delivery_id", newDelivery.id);
  
//     filledItems.forEach((item, index) => {
//       formData.append(`items[${index}][quantity]`, item.delivered_quantity);
//       formData.append(`items[${index}][id]`, item.id); // Use item.id instead of serialNumber
//     });
//     const orderId=localStorage.getItem("orderId")||1;
//     try {
//       const response = await apiInstance.post(`/orders/${orderId}/delivery`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       if (response.status === 200) {
//         // //toast({ title: "Delivery submitted successfully.", status: "success" });
//       } else {
//         // //toast({ title: "Failed to submit delivery.", status: "error" });
//       }
//     } catch (error) {
//       console.error('Error submitting delivery:', error);
//       // //toast({ title: "Failed to submit delivery.", status: "error" });
//     }
  
//     console.log('Payload:', filledItems);
//   };
  
//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Delivery Tab
//       </Text>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={deliveryDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>awb_tracking_number</FormLabel>
//             <Input
//               type="text"
//               name="awb_tracking_number"
//               value={deliveryDetails.awb_tracking_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Remarks</FormLabel>
//             <Textarea
//               name="remarks"
//               value={deliveryDetails.remarks}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={8}>
//           <Text fontSize="xl" fontWeight="bold" mb={4}>
//             Items
//           </Text>
//           <Table variant="simple">
//             <Thead>
//               <Tr>
//                 <Th>Serial No.</Th>
//                 <Th>Name</Th>
//                 <Th>Quantity</Th>
//                 <Th>Delivered Quantity</Th>
//                 <Th>Enter Quantity</Th>
//               </Tr>
//             </Thead>
//             <Tbody>
//               {items.map((item, index) => (
//                 <Tr key={item.sku}>
//                   <Td>{index + 1}</Td>
//                   <Td>{item.name}</Td>
//                   <Td>{item.quantity}</Td>
//                   <Td>{item.delivered_quantity}</Td>
//                   <Td>
//                     <Input
//                       type="number"
//                       min="0"
//                       max={item.quantity}
//                       value={deliveryQuantities[item.sku] || ''}
//                       onChange={(e) => handleQuantityChange(e, item.sku)}
//                     />
//                   </Td>
//                 </Tr>
//               ))}
//             </Tbody>
//           </Table>
//         </Box>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>

//       <Box mt={8}>
//         <Text fontSize="xl" fontWeight="bold" mb={4}>
//           Delivery List
//         </Text>
//         <Stack spacing={4}>
//           {deliveryList.map((delivery) => (
//             <Flex
//               key={delivery.id}
//               p={4}
//               bg="gray.100"
//               borderRadius="md"
//               justifyContent="space-between"
//               alignItems="center"
//             >
//               <Box>
//                 <Text fontWeight="bold">Date: {delivery.date}</Text>
//                 <Text>awb_tracking_number: {delivery.awb_tracking_number}</Text>
//                 <Text>Remarks: {delivery.remarks}</Text>
//                 <Box mt={2}>
//                   <Table variant="simple">
//                     <Thead>
//                       <Tr>
//                         <Th>Serial No.</Th>
//                         <Th>Name</Th>
//                         <Th>SKU</Th>
//                         <Th>Quantity</Th>
//                         <Th>Delivered Quantity</Th>
//                         <Th>Remarks</Th>
//                       </Tr>
//                     </Thead>
//                     <Tbody>
//                       {delivery.items.map((item, index) => (
//                         <Tr key={item.sku}>
//                           <Td>{item.serialNumber}</Td>
//                           <Td>{item.name}</Td>
//                           <Td>{item.sku}</Td>
//                           <Td>{item.quantity}</Td>
//                           <Td>{item.delivered_quantity}</Td>
//                           <Td>{item.remarks}</Td>
//                         </Tr>
//                       ))}
//                     </Tbody>
//                   </Table>
//                 </Box>
//               </Box>
//             </Flex>
//           ))}
//         </Stack>
//       </Box>
//     </Box>
//   );
// };

// export default DeliveryTab;


// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   FormControl,
//   FormLabel,
//   Textarea,
//   SimpleGrid,
//   Flex,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
// } from "@chakra-ui/react";
// import axios from 'axios';
// import apiInstance from 'api/api';

// const DeliveryTab = () => {
//   const [deliveryDetails, setDeliveryDetails] = useState({
//     date: '',
//     awb_tracking_number: '',
//     remarks: '',
//   });
//   const [deliveryList, setDeliveryList] = useState([]);
//   const [items, setItems] = useState([]);
//   const [deliveryQuantities, setDeliveryQuantities] = useState({});
//  const fetchData = async () => {
//       const orderId = localStorage.getItem("orderId") || 1;
//       try {
//         const response = await apiInstance.get(`/orders/${orderId}/items`);
//         setItems(response.data.data.items);
//       } catch (error) {
//         console.error('Error fetching items:', error);
//       }
//     };
//   useEffect(() => {
   

//     fetchData();

//     const storedDeliveryList = JSON.parse(localStorage.getItem("deliveryList")) || [];
//     setDeliveryList(storedDeliveryList);
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setDeliveryDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleQuantityChange = (e, sku) => {
//     const { value } = e.target;
//     setDeliveryQuantities((prevQuantities) => ({
//       ...prevQuantities,
//       [sku]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const filledItems = items
//       .map((item, index) => ({
//         ...item,
//         serialNumber: index + 1,
//         delivered_quantity: deliveryQuantities[item.sku] || 0,
//       }))
//       .filter(item => deliveryQuantities[item.sku] !== undefined && deliveryQuantities[item.sku] !== '');

//     if (filledItems.length === 0) {
//       //toast({ title: "Please fill at least one item's quantity.", status: "error" });
//       return;
//     }

//     const newDelivery = {
//       ...deliveryDetails,
//       items: filledItems,
//       id: Date.now(),
//     };
//     const updatedDeliveryList = [...deliveryList, newDelivery];
//     setDeliveryList(updatedDeliveryList);
//     localStorage.setItem("deliveryList", JSON.stringify(updatedDeliveryList));
//     setDeliveryDetails({
//       date: '',
//       awb_tracking_number: '',
//       remarks: '',
//     });
//     setDeliveryQuantities({});
//     //toast({ title: "Details submitted.", status: "success" });

//     const formData = new FormData();
//     formData.append("date", deliveryDetails.date);
//     formData.append("delivery_id", newDelivery.id);

//     filledItems.forEach((item, index) => {
//       formData.append(`items[${index}][quantity]`, item.delivered_quantity);
//       formData.append(`items[${index}][id]`, item.id); // Use item.id instead of serialNumber
//     });
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.post(`/orders/${orderId}/delivery`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       if (response.status === 200) {
//       fetchData()
//         //toast({ title: "Delivery submitted successfully.", status: "success" });
//       } else {
//         //toast({ title: "Failed to submit delivery.", status: "error" });
//       }
//     } catch (error) {
//       console.error('Error submitting delivery:', error);
//       //toast({ title: "Failed to submit delivery.", status: "error" });
//     }

//     console.log('Payload:', filledItems);
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Delivery Tab
//       </Text>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={deliveryDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>AWB Tracking No</FormLabel>
//             <Input
//               type="text"
//               name="awb_tracking_number"
//               value={deliveryDetails.awb_tracking_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Remarks</FormLabel>
//             <Textarea
//               name="remarks"
//               value={deliveryDetails.remarks}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={8}>
//           <Text fontSize="xl" fontWeight="bold" mb={4}>
//             Items
//           </Text>
//           <Table variant="simple">
//             <Thead>
//               <Tr>
//                 <Th>Serial No.</Th>
//                 <Th>Name</Th>
//                 <Th>Quantity</Th>
//                 <Th>Delivered Quantity</Th>
//                 <Th>Enter Quantity</Th>
//               </Tr>
//             </Thead>
//             <Tbody>
//               {items.map((item, index) => (
//                 <Tr key={item.sku}>
//                   <Td>{index + 1}</Td>
//                   <Td>{item.name}</Td>
//                   <Td>{item.quantity}</Td>
//                   <Td>{item.delivered_quantity}</Td>
//                   <Td>
//                     <Input
//                       type="number"
//                       min="0"
//                       max={item.quantity}
//                       value={deliveryQuantities[item.sku] || ''}
//                       onChange={(e) => handleQuantityChange(e, item.sku)}
//                       key={`quantity-${item.sku}`} // Unique key for each input
//                     />
//                   </Td>
//                 </Tr>
//               ))}
//             </Tbody>
//           </Table>
//         </Box>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>

//       <Box mt={8}>
//         <Text fontSize="xl" fontWeight="bold" mb={4}>
//           Delivery List
//         </Text>
//         <Stack spacing={4}>
//           {deliveryList.map((delivery) => (
//             <Flex
//               key={delivery.id}
//               p={4}
//               bg="gray.100"
//               borderRadius="md"
//               justifyContent="space-between"
//               alignItems="center"
//             >
//               <Box>
//                 <Text fontWeight="bold">Date: {delivery.date}</Text>
//                 <Text>AWB Tracking No : {delivery.awb_tracking_number}</Text>
//                 <Text>Remarks: {delivery.remarks}</Text>
//                 <Box mt={2}>
//                   <Table variant="simple">
//                     <Thead>
//                       <Tr>
//                         <Th>Serial No.</Th>
//                         <Th>Name</Th>
//                         <Th>SKU</Th>
//                         <Th>Quantity</Th>
//                         <Th>Delivered Quantity</Th>
//                         <Th>Remarks</Th>
//                       </Tr>
//                     </Thead>
//                     <Tbody>
//                       {delivery.items.map((item, index) => (
//                         <Tr key={index}>
//                           <Td>{item.serialNumber}</Td>
//                           <Td>{item.name}</Td>
//                           <Td>{item.sku}</Td>
//                           <Td>{item.quantity}</Td>
//                           <Td>{item.delivered_quantity}</Td>
//                           <Td>{item.remarks}</Td>
//                         </Tr>
//                       ))}
//                     </Tbody>
//                   </Table>
//                 </Box>
//               </Box>
//             </Flex>
//           ))}
//         </Stack>
//       </Box>
//     </Box>
//   );
// };

// export default DeliveryTab;

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   FormControl,
//   FormLabel,
//   Textarea,
//   SimpleGrid,
//   Flex,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Select,
// } from "@chakra-ui/react";
// import axios from 'axios';
// import apiInstance from 'api/api';

// const DeliveryTab = () => {
//   const [deliveryDetails, setDeliveryDetails] = useState({
//     date: '',
//     awb_tracking_number: '',
//     // remarks: '',
//     delivery_partner_id: '', // Added delivery_partner_id
//   });
//   const [deliveryList, setDeliveryList] = useState([]);
//   const [items, setItems] = useState([]);
//   const [deliveryQuantities, setDeliveryQuantities] = useState({});
//   const [partners, setPartners] = useState([]); // State for delivery partners

//   const fetchData = async () => {
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/items`);
//       setItems(response.data.data.items);
//     } catch (error) {
//       console.error('Error fetching items:', error);
//     }
//   };

//   const fetchPartners = async () => {
//     try {
//       const response = await apiInstance.get("/delivery-partners");
//       const partners = response.data.data["delivery-partners"] || [];
//       setPartners(partners);
//     } catch (error) {
//       console.error('Error fetching delivery partners:', error);
//     }
//   };

//   const fetchDeliveryList = async () => {
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/deliveries`);
//       const deliveryList = response.data.data.deliveries || [];
//       setDeliveryList(deliveryList);
//       console.log("response delivery", deliveryList);
//     } catch (error) {
//       console.log('Error fetching deliveryList');
//     }
//   }

//   useEffect(() => {
//     fetchData();
//     fetchPartners();
//     fetchDeliveryList();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setDeliveryDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleQuantityChange = (e, sku) => {
//     const { value } = e.target;
//     setDeliveryQuantities((prevQuantities) => ({
//       ...prevQuantities,
//       [sku]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const filledItems = items
//       .map((item, index) => ({
//         ...item,
//         serialNumber: index + 1,
//         delivered_quantity: deliveryQuantities[item.sku] || 0,
//       }))
//       .filter(item => deliveryQuantities[item.sku] !== undefined && deliveryQuantities[item.sku] !== '');

//     if (filledItems.length === 0) {
//       //toast({ title: "Please fill at least one item's quantity.", status: "error" });
//       return;
//     }

//     const newDelivery = {
//       ...deliveryDetails,
//       items: filledItems,
//       id: Date.now(),
//     };

//     const updatedDeliveryList = [...deliveryList, newDelivery];
//     setDeliveryList(updatedDeliveryList);
//     setDeliveryDetails({
//       date: '',
//       awb_tracking_number: '',
//       // remarks: '',
//       delivery_partner_id: '',
//     });
//     setDeliveryQuantities({});
//     //toast({ title: "Details submitted.", status: "success" });

//     const formData = new FormData();
//     formData.append("date", deliveryDetails.date);
//     formData.append("awb_tracking_number", newDelivery.id);
//     formData.append("delivery_partner_id", deliveryDetails.delivery_partner_id);

//     filledItems.forEach((item, index) => {
//       formData.append(`items[${index}][quantity]`, item.delivered_quantity);
//       formData.append(`items[${index}][id]`, item.id); // Use item.id instead of serialNumber
//     });
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.post(`/orders/${orderId}/delivery`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       if (response.status === 200) {
//         fetchData();
//         fetchDeliveryList();
//         //toast({ title: "Delivery submitted successfully.", status: "success" });
//       } else {
//         //toast({ title: "Failed to submit delivery.", status: "error" });
//       }
//     } catch (error) {
//       console.error('Error submitting delivery:', error);
//       //toast({ title: "Failed to submit delivery.", status: "error" });
//     }

//     console.log('Payload:', filledItems);
//   };

//   const handleMarkAsDelivered = async (deliveryId) => {
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//   const response = await apiInstance.post(`/orders/${orderId}/deliveries/${deliveryId}`, {_method:"put"});
//       if (response.status === 200) {
//         fetchDeliveryList();
//         //toast({ title: "Marked as delivered successfully.", status: "success" });
//       } else {
//         //toast({ title: "Failed to mark as delivered.", status: "error" });
//       }
//     } catch (error) {
//       console.error('Error marking as delivered:', error);
//       //toast({ title: "Failed to mark as delivered.", status: "error" });
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Delivery Tab
//       </Text>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={deliveryDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>AWB Tracking No</FormLabel>
//             <Input
//               type="text"
//               name="awb_tracking_number"
//               value={deliveryDetails.awb_tracking_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           {/* <FormControl>
//             <FormLabel>Remarks</FormLabel>
//             <Textarea
//               name="remarks"
//               value={deliveryDetails.remarks}
//               onChange={handleInputChange}
//             />
//           </FormControl> */}

//           <FormControl isRequired>
//             <FormLabel>Delivery Partner</FormLabel>
//             <Select
//               name="delivery_partner_id"
//               value={deliveryDetails.delivery_partner_id}
//               onChange={handleInputChange}
//               placeholder="Select Delivery Partner"
//             >
//               {partners.map((partner) => (
//                 <option key={partner.id} value={partner.id}>
//                   {partner.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={8}>
//           <Text fontSize="xl" fontWeight="bold" mb={4}>
//             Items
//           </Text>
//           <Table variant="simple">
//             <Thead>
//               <Tr>
//                 <Th>Serial No.</Th>
//                 <Th>Name</Th>
//                 <Th>Quantity</Th>
//                 <Th>Shipped Quantity</Th>
//                 <Th>Delivered Quantity</Th>
//                 <Th>Enter Quantity</Th>
//               </Tr>
//             </Thead>
//             <Tbody>
//               {items.map((item, index) => (
//                 <Tr key={item.sku}>
//                   <Td>{index + 1}</Td>
//                   <Td>{item.name}</Td>
//                   <Td>{item.quantity}</Td>
//                   <Td>{item.shipped_quantity}</Td>
//                   <Td>{item.delivered_quantity}</Td>
//                   <Td>
//                     <Input
//                       type="number"
//                       min="0"
//                       max={item.quantity}
//                       value={deliveryQuantities[item.sku] || ''}
//                       onChange={(e) => handleQuantityChange(e, item.sku)}
//                       key={`quantity-${item.sku}`} // Unique key for each input
//                     />
//                   </Td>
//                 </Tr>
//               ))}
//             </Tbody>
//           </Table>
//         </Box>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>

//       <Box mt={8}>
//         <Text fontSize="xl" fontWeight="bold" mb={4}>
//           Delivery List
//         </Text>
//         <Stack spacing={4}>
//           {deliveryList.map((delivery) => (
//             <Flex
//               key={delivery.id}
//               p={4}
//               bg="gray.100"
//               borderRadius="md"
//               justifyContent="space-between"
//               alignItems="center"
//             >
//               <Box>
//                 <Text fontWeight="bold">Date: {delivery.date}</Text>
//                 <Text>AWB Tracking No: {delivery.awb_tracking_number}</Text>
//                 <Text>Delivery Partner: {delivery.delivery_partner}</Text>
//                 {/* <Text>Remarks: {delivery.remarks}</Text> */}
//                 <Box mt={2}>
//                   <Table variant="simple">
//                     <Thead>
//                       <Tr>
//                         <Th>Serial No.</Th>
//                         <Th>Name</Th>
//                         <Th>SKU</Th>
//                         <Th>Quantity</Th>
//                       </Tr>
//                     </Thead>
//                     <Tbody>
//                       {delivery.items.map((item, index) => (
//                         <Tr key={index}>
//                           <Td>{index + 1}</Td>
//                           <Td>{item.name}</Td>
//                           <Td>{item.sku}</Td>
//                           <Td>{item.quantity}</Td>
//                         </Tr>
//                       ))}
//                     </Tbody>
//                   </Table>
//                 </Box>
               
//               </Box>
              
//               {!delivery.delivered && (
//                   <Button colorScheme="red" onClick={() => handleMarkAsDelivered(delivery.id)} mt={2}>
//                     Not Delivered
//                   </Button>
//                 )}
//             </Flex>
//           ))}
//         </Stack>
//       </Box>
//     </Box>
//   );
// };

// export default DeliveryTab;

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   FormControl,
//   FormLabel,
//   Textarea,
//   SimpleGrid,
//   Flex,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Select,
//   Alert,
//   AlertIcon,
//   AlertTitle,
//   AlertDescription,
//   CloseButton,
//   useBreakpointValue,
// } from "@chakra-ui/react";
// import axios from 'axios';
// import apiInstance from 'api/api';

// const DeliveryTab = () => {
//   const [deliveryDetails, setDeliveryDetails] = useState({
//     date: '',
//     awb_tracking_number: '',
//    // remarks: '',
//     delivery_partner_id: '', // Added delivery_partner_id
//   });
//   const [deliveryList, setDeliveryList] = useState([]);
//   const [items, setItems] = useState([]);
//   const [deliveryQuantities, setDeliveryQuantities] = useState({});
//   const [partners, setPartners] = useState([]); // State for delivery partners
//   const [alert, setAlert] = useState({ type: '', message: '', visible: false });

//   const fetchData = async () => {
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/items`);
//       setItems(response.data.data.items);
//     } catch (error) {
//       console.error('Error fetching items:', error);
//     }
//   };

//   const fetchPartners = async () => {
//     try {
//       const response = await apiInstance.get("/delivery-partners");
//       const partners = response.data.data["delivery-partners"] || [];
//       setPartners(partners);
//     } catch (error) {
//       console.error('Error fetching delivery partners:', error);
//     }
//   };

//   const fetchDeliveryList = async () => {
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/deliveries`);
//       const deliveryList = response.data.data.deliveries || [];
//       setDeliveryList(deliveryList);
//       console.log("response delivery", deliveryList);
//     } catch (error) {
//       console.log('Error fetching deliveryList');
//     }
//   };

//   useEffect(() => {
//     fetchData();
//     fetchPartners();
//     fetchDeliveryList();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setDeliveryDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleQuantityChange = (e, sku) => {
//     const { value } = e.target;
//     setDeliveryQuantities((prevQuantities) => ({
//       ...prevQuantities,
//       [sku]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const filledItems = items
//       .map((item, index) => ({
//         ...item,
//         serialNumber: index + 1,
//         delivered_quantity: deliveryQuantities[item.sku] || 0,
//       }))
//       .filter(item => deliveryQuantities[item.sku] !== undefined && deliveryQuantities[item.sku] !== '');

//     if (filledItems.length === 0) {
//       setAlert({ type: 'error', message: "Please fill at least one item's quantity.", visible: true });
//       return;
//     }

//     const newDelivery = {
//       ...deliveryDetails,
//       items: filledItems,
//       id: Date.now(),
//     };

//     const updatedDeliveryList = [...deliveryList, newDelivery];
//     setDeliveryList(updatedDeliveryList);
//     setDeliveryDetails({
//       date: '',
//       awb_tracking_number: '',
//      // remarks: '',
//       delivery_partner_id: '',
//     });
//     setDeliveryQuantities({});

//     const formData = new FormData();
//     formData.append("date", deliveryDetails.date);
//     formData.append("awb_tracking_number", newDelivery.id);
//     formData.append("delivery_partner_id", deliveryDetails.delivery_partner_id);

//     filledItems.forEach((item, index) => {
//       formData.append(`items[${index}][quantity]`, item.delivered_quantity);
//       formData.append(`items[${index}][id]`, item.id); // Use item.id instead of serialNumber
//     });
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.post(`/orders/${orderId}/delivery`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       if (response.status === 200) {
//         fetchData();
//         fetchDeliveryList();
//         setAlert({ type: 'success', message: 'Delivery submitted successfully.', visible: true });
//       } else {
//         setAlert({ type: 'error', message: 'Failed to submit delivery.', visible: true });
//       }
//     } catch (error) {
//       console.error('Error submitting delivery:', error);
//       setAlert({ type: 'error', message: 'Failed to submit delivery.', visible: true });
//     }

//     console.log('Payload:', filledItems);
//   };

//   const handleMarkAsDelivered = async (deliveryId) => {
//     const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.post(`/orders/${orderId}/deliveries/${deliveryId}`, { _method: "put" });
//       if (response.status === 200) {
//         fetchDeliveryList();
//         fetchData()
//         setAlert({ type: 'success', message: 'Marked as delivered successfully.', visible: true });
//       } else {
//         setAlert({ type: 'error', message: 'Failed to mark as delivered.', visible: true });
//       }
//     } catch (error) {
//       console.error('Error marking as delivered:', error);
//       setAlert({ type: 'error', message: 'Failed to mark as delivered.', visible: true });
//     }
//   };

//   const closeAlert = () => setAlert({ type: '', message: '', visible: false });

//   return (
//     <Box pt={{ base: "120px", md: "80px", xl: "80px" }} p={4} mt={{ base: "100px", md: "100px", xl: "100px" }}>
//       <Text fontSize={["xl", "2xl", "2xl"]} fontWeight="bold" mb={4}>
//         Delivery Tab
//       </Text>
//       {alert.visible && (
//         <Alert status={alert.type} position="fixed" top="20px" right="20px" zIndex="1000" width="auto" boxShadow="lg">
//           <AlertIcon />
//           <Box flex="1">
//             <AlertTitle>{alert.type === 'error' ? 'Error' : 'Success'}</AlertTitle>
//             <AlertDescription>{alert.message}</AlertDescription>
//           </Box>
//           <CloseButton onClick={closeAlert} position="absolute" right="8px" top="8px" />
//         </Alert>
//       )}
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={deliveryDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>AWB Tracking No</FormLabel>
//             <Input
//               type="text"
//               name="awb_tracking_number"
//               value={deliveryDetails.awb_tracking_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           {/* <FormControl>
//             <FormLabel>Remarks</FormLabel>
//             <Textarea
//               name="remarks"
//               value={deliveryDetails.remarks}
//               onChange={handleInputChange}
//             />
//           </FormControl> */}

//           <FormControl isRequired>
//             <FormLabel>Delivery Partner</FormLabel>
//             <Select
//               name="delivery_partner_id"
//               value={deliveryDetails.delivery_partner_id}
//               onChange={handleInputChange}
//               placeholder="Select Delivery Partner"
//             >
//               {partners.map((partner) => (
//                 <option key={partner.id} value={partner.id}>
//                   {partner.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={8}>
//           <Text fontSize={["lg", "xl", "xl"]} fontWeight="bold" mb={4}>
//             Items
//           </Text>
//           <Table variant="simple" size="sm">
//             <Thead>
//               <Tr>
//                 <Th>Serial No.</Th>
//                 <Th>Name</Th>
//                 <Th>Quantity</Th>
//                 <Th>Shipped Quantity</Th>
//                 <Th>Delivered Quantity</Th>
//                 <Th>Enter Quantity</Th>
//               </Tr>
//             </Thead>
//             <Tbody>
//               {items.map((item, index) => (
//                 <Tr key={item.sku}>
//                   <Td>{index + 1}</Td>
//                   <Td>{item.name}</Td>
//                   <Td>{item.quantity}</Td>
//                   <Td>{item.shipped_quantity}</Td>
//                   <Td>{item.delivered_quantity}</Td>
//                   <Td>
//                     <Input
//                       type="number"
//                       min="0"
//                       max={item.quantity}
//                       value={deliveryQuantities[item.sku] || ''}
//                       onChange={(e) => handleQuantityChange(e, item.sku)}
//                       key={`quantity-${item.sku}`} // Unique key for each input
//                     />
//                   </Td>
//                 </Tr>
//               ))}
//             </Tbody>
//           </Table>
//         </Box>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>

//       <Box mt={8}>
//         <Text fontSize={["lg", "xl", "xl"]} fontWeight="bold" mb={4}>
//           Delivery List
//         </Text>
//         <Stack spacing={4}>
//           {deliveryList.map((delivery) => (
//             <Flex
//               key={delivery.id}
//               p={4}
//               bg="gray.100"
//               borderRadius="md"
//               justifyContent="space-between"
//               alignItems="center"
//               direction={{ base: "column", md: "row" }}
//             >
//               <Box>
//                 <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
//                   <Text fontWeight="bold">Date: {delivery.date}</Text>
//                   <Text fontWeight="bold">AWB Tracking No: {delivery.awb_tracking_number}</Text>
//                   <Text fontWeight="bold">Delivery Partner: {delivery.delivery_partner}</Text>
//                   {/* <Text>Remarks: {delivery.remarks}</Text> */}
//                 </SimpleGrid>
//                 <Box mt={2}>
//                   <Table variant="simple" size="sm">
//                     <Thead>
//                       <Tr>
//                         <Th>Serial No.</Th>
//                         <Th>Name</Th>
//                         <Th>SKU</Th>
//                         <Th>Quantity</Th>
//                       </Tr>
//                     </Thead>
//                     <Tbody>
//                       {delivery.items.map((item, index) => (
//                         <Tr key={index}>
//                           <Td>{index + 1}</Td>
//                           <Td>{item.name}</Td>
//                           <Td>{item.sku}</Td>
//                           <Td>{item.quantity}</Td>
//                         </Tr>
//                       ))}
//                     </Tbody>
//                   </Table>
//                 </Box>
                
//               </Box>
//               {!delivery.delivered && (
//                   <Button colorScheme="red" onClick={() => handleMarkAsDelivered(delivery.id)} mt={2}>
//                     Not Delivered
//                   </Button>
//                 )}
//             </Flex>
//           ))}
//         </Stack>
//       </Box>
//     </Box>
//   );
// };

// export default DeliveryTab;

// import React, { useState, useEffect, useRef } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   FormControl,
//   FormLabel,
//   Textarea,
//   SimpleGrid,
//   Flex,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Select,
//   Alert,
//   AlertIcon,
//   AlertTitle,
//   AlertDescription,
//   CloseButton,
//   AlertDialog,
//   AlertDialogBody,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogContent,
//   AlertDialogOverlay,
//   useDisclosure,
//   useBreakpointValue,
// } from "@chakra-ui/react";
// import axios from 'axios';
// import apiInstance from 'api/api';

// const DeliveryTab = () => {
//   const [deliveryDetails, setDeliveryDetails] = useState({
//     date: '',
//     awb_tracking_number: '',
//     delivery_partner_id: '', // Added delivery_partner_id
//   });
//   const [deliveryList, setDeliveryList] = useState([]);
//   const [items, setItems] = useState([]);
//   const [deliveryQuantities, setDeliveryQuantities] = useState({});
//   const [partners, setPartners] = useState([]); // State for delivery partners
//   const [alert, setAlert] = useState({ type: '', message: '', visible: false });
//   const [selectedDeliveryId, setSelectedDeliveryId] = useState(null);

//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const cancelRef = useRef();

//   const fetchData = async () => {
//     const orderString = localStorage.getItem("currentOrder");
  
//     // Ensure that orderString is not null or undefined
//     if (orderString) {
//       try {
//         // Parse the JSON string to an object
//         const order = JSON.parse(orderString);
  
//         // Safely access the id property
//         const orderId = order ? order.id : 1;
  
//         console.log("issi", order, orderId);
  
//         // const orderId = localStorage.getItem("orderId") || 1;
//         const response = await apiInstance.get(`/orders/${orderId}/items`);
//         setItems(response.data.data.items);
//       } catch (error) {
//         console.error("Error parsing JSON or fetching items:", error);
//       }
//     } else {
//       console.error("currentOrder is not found in local storage.");
//     }
//   };

//   const fetchPartners = async () => {
//     try {
//       const response = await apiInstance.get("/delivery-partners");
//       const partners = response.data.data["delivery-partners"] || [];
//       setPartners(partners);
//     } catch (error) {
//       console.error('Error fetching delivery partners:', error);
//     }
//   };

//   const fetchDeliveryList = async () => {
//     const order=localStorage.getItem("currentOrder");
//     const orderId=order.id || 1;
//     // const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/deliveries`);
//       const deliveryList = response.data.data.deliveries || [];
//       setDeliveryList(deliveryList);
//       console.log("response delivery", deliveryList);
//     } catch (error) {
//       console.log('Error fetching deliveryList');
//     }
//   };

//   useEffect(() => {
//     fetchData();
//     fetchPartners();
//     fetchDeliveryList();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setDeliveryDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleQuantityChange = (e, sku) => {
//     const { value } = e.target;
//     setDeliveryQuantities((prevQuantities) => ({
//       ...prevQuantities,
//       [sku]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const filledItems = items
//       .map((item, index) => ({
//         ...item,
//         serialNumber: index + 1,
//         delivered_quantity: deliveryQuantities[item.sku] || 0,
//       }))
//       .filter(item => deliveryQuantities[item.sku] !== undefined && deliveryQuantities[item.sku] !== '');

//     if (filledItems.length === 0) {
//       setAlert({ type: 'error', message: "Please fill at least one item's quantity.", visible: true });
//       return;
//     }

//     const newDelivery = {
//       ...deliveryDetails,
//       items: filledItems,
//       id: Date.now(),
//     };

//     const updatedDeliveryList = [...deliveryList, newDelivery];
//     setDeliveryList(updatedDeliveryList);
//     setDeliveryDetails({
//       date: '',
//       awb_tracking_number: '',
//       delivery_partner_id: '',
//     });
//     setDeliveryQuantities({});

//     const formData = new FormData();
//     formData.append("date", deliveryDetails.date);
//     formData.append("awb_tracking_number", newDelivery.id);
//     formData.append("delivery_partner_id", deliveryDetails.delivery_partner_id);

//     filledItems.forEach((item, index) => {
//       formData.append(`items[${index}][quantity]`, item.delivered_quantity);
//       formData.append(`items[${index}][id]`, item.id); // Use item.id instead of serialNumber
//     });
//     const order=localStorage.getItem("currentOrder");
//     const orderId=order.id || 1;
//     // const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.post(`/orders/${orderId}/delivery`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       if (response.status === 200) {
//         fetchData();
//         fetchDeliveryList();
//         setAlert({ type: 'success', message: 'Delivery submitted successfully.', visible: true });
//       } else {
//         setAlert({ type: 'error', message: 'Failed to submit delivery.', visible: true });
//       }
//     } catch (error) {
//       console.error('Error submitting delivery:', error);
//       setAlert({ type: 'error', message: 'Failed to submit delivery.', visible: true });
//     }

//     console.log('Payload:', filledItems);
//   };

//   const handleMarkAsDelivered = async () => {
//     const order=localStorage.getItem("currentOrder");
//     const orderId=order.id || 1;
//     //const orderId = localStorage.getItem("orderId") || 1;
//     try {
//       const response = await apiInstance.post(`/orders/${orderId}/deliveries/${selectedDeliveryId}`, { _method: "put" });
//       if (response.status === 200) {
//         fetchDeliveryList();
//         fetchData();
//         setAlert({ type: 'success', message: 'Marked as delivered successfully.', visible: true });
//       } else {
//         setAlert({ type: 'error', message: 'Failed to mark as delivered.', visible: true });
//       }
//     } catch (error) {
//       console.error('Error marking as delivered:', error);
//       setAlert({ type: 'error', message: 'Failed to mark as delivered.', visible: true });
//     }
//     onClose();
//   };

//   const openConfirmationDialog = (deliveryId) => {
//     setSelectedDeliveryId(deliveryId);
//     onOpen();
//   };

//   const closeAlert = () => setAlert({ type: '', message: '', visible: false });

//   return (
//     <Box pt={{ base: "120px", md: "80px", xl: "80px" }} p={4} mt={{ base: "100px", md: "100px", xl: "100px" }}>
//       <Text fontSize={["xl", "2xl", "2xl"]} fontWeight="bold" mb={4}>
//         Delivery Tab
//       </Text>
//       {alert.visible && (
//         <Alert status={alert.type} position="fixed" top="20px" right="20px" zIndex="1000" width="auto" boxShadow="lg">
//           <AlertIcon />
//           <Box flex="1">
//             <AlertTitle>{alert.type === 'error' ? 'Error' : 'Success'}</AlertTitle>
//             <AlertDescription>{alert.message}</AlertDescription>
//           </Box>
//           <CloseButton onClick={closeAlert} position="absolute" right="8px" top="8px" />
//         </Alert>
//       )}
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Date</FormLabel>
//             <Input
//               type="date"
//               name="date"
//               value={deliveryDetails.date}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>AWB Tracking No</FormLabel>
//             <Input
//               type="text"
//               name="awb_tracking_number"
//               value={deliveryDetails.awb_tracking_number}
//               onChange={handleInputChange}
//             />
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Delivery Partner</FormLabel>
//             <Select
//               name="delivery_partner_id"
//               value={deliveryDetails.delivery_partner_id}
//               onChange={handleInputChange}
//               placeholder="Select Delivery Partner"
//             >
//               {partners.map((partner) => (
//                 <option key={partner.id} value={partner.id}>
//                   {partner.name}
//                 </option>
//               ))}
//             </Select>
//           </FormControl>
//         </SimpleGrid>

//         <Box mt={8}>
//           <Text fontSize={["lg", "xl", "xl"]} fontWeight="bold" mb={4}>
//             Items
//           </Text>
//           <Table variant="simple" size="sm">
//             <Thead>
//               <Tr>
//                 <Th>Serial No.</Th>
//                 <Th>Name</Th>
//                 <Th>Quantity</Th>
//                 <Th>Shipped Quantity</Th>
//                 <Th>Delivered Quantity</Th>
//                 <Th>Enter Quantity</Th>
//               </Tr>
//             </Thead>
//             <Tbody>
//               {items.map((item, index) => (
//                 <Tr key={item.sku}>
//                   <Td>{index + 1}</Td>
//                   <Td>{item.name}</Td>
//                   <Td>{item.quantity}</Td>
//                   <Td>{item.shipped_quantity}</Td>
//                   <Td>{item.delivered_quantity}</Td>
//                   <Td>
//                     <Input
//                       type="number"
//                       min="0"
//                       max={item.quantity}
//                       value={deliveryQuantities[item.sku] || ''}
//                       onChange={(e) => handleQuantityChange(e, item.sku)}
//                       key={`quantity-${item.sku}`} // Unique key for each input
//                     />
//                   </Td>
//                 </Tr>
//               ))}
//             </Tbody>
//           </Table>
//         </Box>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>

//       <Box mt={8}>
//         <Text fontSize={["lg", "xl", "xl"]} fontWeight="bold" mb={4}>
//           Delivery List
//         </Text>
//         <Stack spacing={4}>
//           {deliveryList.map((delivery) => (
//             <Flex
//               key={delivery.id}
//               p={4}
//               bg="gray.100"
//               borderRadius="md"
//               justifyContent="space-between"
//               alignItems="center"
//               position="relative" // Add this to enable absolute positioning of the button
//               direction={{ base: "column", md: "row" }}
//             >
//               <Box>
//                 <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
//                   <Text fontWeight="bold">Date: {delivery.date}</Text>
//                   <Text fontWeight="bold">AWB Tracking No: {delivery.awb_tracking_number}</Text>
//                   <Text fontWeight="bold">Delivery Partner: {delivery.delivery_partner}</Text>
//                 </SimpleGrid>
//                 <Box mt={2}>
//                   <Table variant="simple" size="sm">
//                     <Thead>
//                       <Tr>
//                         <Th>Serial No.</Th>
//                         <Th>Name</Th>
//                         <Th>SKU</Th>
//                         <Th>Quantity</Th>
//                       </Tr>
//                     </Thead>
//                     <Tbody>
//                       {delivery.items.map((item, index) => (
//                         <Tr key={index}>
//                           <Td>{index + 1}</Td>
//                           <Td>{item.name}</Td>
//                           <Td>{item.sku}</Td>
//                           <Td>{item.quantity}</Td>
//                         </Tr>
//                       ))}
//                     </Tbody>
//                   </Table>
//                 </Box>
//               </Box>
//               {!delivery.delivered && (
//                 <Button
//                   colorScheme="red"
//                   onClick={() => openConfirmationDialog(delivery.id)}
//                   position="absolute"
//                   top="4px"
//                   right="4px"
//                   size="sm" // Adjust button size
//                 >
//                   Not Delivered
//                 </Button>
//               )}
//             </Flex>
//           ))}
//         </Stack>
//       </Box>

//       <AlertDialog
//         isOpen={isOpen}
//         leastDestructiveRef={cancelRef}
//         onClose={onClose}
//       >
//         <AlertDialogOverlay>
//           <AlertDialogContent>
//             <AlertDialogHeader fontSize="lg" fontWeight="bold">
//               Confirm Action
//             </AlertDialogHeader>

//             <AlertDialogBody>
//               Are you sure you want to mark this delivered?
//             </AlertDialogBody>

//             <AlertDialogFooter>
//               <Button ref={cancelRef} onClick={onClose}>
//                 Cancel
//               </Button>
//               <Button colorScheme="red" onClick={handleMarkAsDelivered} ml={3}>
//                 Confirm
//               </Button>
//             </AlertDialogFooter>
//           </AlertDialogContent>
//         </AlertDialogOverlay>
//       </AlertDialog>
//     </Box>
//   );
// };

// export default DeliveryTab;
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Input,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  SimpleGrid,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import apiInstance from 'api/api';

const DeliveryTab = () => {
  const [deliveryDetails, setDeliveryDetails] = useState({
    date: '',
    awb_tracking_number: '',
    delivery_partner_id: '',
  });
  const [deliveryList, setDeliveryList] = useState([]);
  const [items, setItems] = useState([]);
  const [deliveryQuantities, setDeliveryQuantities] = useState({});
  const [partners, setPartners] = useState([]);
  const [alert, setAlert] = useState({ type: '', message: '', visible: false });
  const [selectedDeliveryId, setSelectedDeliveryId] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const fetchData = async () => {
    const currentOrderId = localStorage.getItem("currentOrderId");
    if (currentOrderId) {
      try {
        const response = await apiInstance.get(`/orders/${currentOrderId}/items`);
        setItems(response.data.data.items);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    } else {
      console.error("currentOrderId is not found in local storage.");
    }
  };

  const fetchPartners = async () => {
    try {
      const response = await apiInstance.get("/delivery-partners");
      const partners = response.data.data["delivery-partners"] || [];
      setPartners(partners);
    } catch (error) {
      console.error('Error fetching delivery partners:', error);
    }
  };

  const fetchDeliveryList = async () => {
    const currentOrderId = localStorage.getItem("currentOrderId");
    if (currentOrderId) {
      try {
        const response = await apiInstance.get(`/orders/${currentOrderId}/deliveries`);
        const deliveryList = response.data.data.deliveries || [];
        setDeliveryList(deliveryList);
        console.log("response delivery", deliveryList);
      } catch (error) {
        console.log('Error fetching deliveryList');
      }
    } else {
      console.error("currentOrderId is not found in local storage.");
    }
  };

  useEffect(() => {
    fetchData();
    fetchPartners();
    fetchDeliveryList();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDeliveryDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleQuantityChange = (e, sku) => {
    const { value } = e.target;
    setDeliveryQuantities((prevQuantities) => ({
      ...prevQuantities,
      [sku]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filledItems = items
      .map((item, index) => ({
        ...item,
        serialNumber: index + 1,
        delivered_quantity: deliveryQuantities[item.sku] || 0,
      }))
      .filter(item => deliveryQuantities[item.sku] !== undefined && deliveryQuantities[item.sku] !== '');

    if (filledItems.length === 0) {
      setAlert({ type: 'error', message: "Please fill at least one item's quantity.", visible: true });
      return;
    }

    const newDelivery = {
      ...deliveryDetails,
      items: filledItems,
      id: Date.now(),
    };

    const formData = new FormData();
    formData.append("date", deliveryDetails.date);
    formData.append("awb_tracking_number", newDelivery.id);
    formData.append("delivery_partner_id", deliveryDetails.delivery_partner_id);

    filledItems.forEach((item, index) => {
      formData.append(`items[${index}][quantity]`, item.delivered_quantity);
      formData.append(`items[${index}][id]`, item.id);
    });

    const currentOrderId = localStorage.getItem("currentOrderId") || 1;
    try {
      const response = await apiInstance.post(`/orders/${currentOrderId}/delivery`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        fetchData();
        fetchDeliveryList();
        setAlert({ type: 'success', message: 'Delivery submitted successfully.', visible: true });
      } else {
        setAlert({ type: 'error', message: 'Failed to submit delivery.', visible: true });
      }
    } catch (error) {
      console.error('Error submitting delivery:', error);
      setAlert({ type: 'error', message: 'Failed to submit delivery.', visible: true });
    }

    setDeliveryDetails({
      date: '',
      awb_tracking_number: '',
      delivery_partner_id: '',
    });
    setDeliveryQuantities({});
  };

  const handleMarkAsDelivered = async () => {
    const currentOrderId = localStorage.getItem("currentOrderId") || 1;
    try {
      const response = await apiInstance.post(`/orders/${currentOrderId}/deliveries/${selectedDeliveryId}`, { _method: "put" });
      if (response.status === 200) {
        fetchDeliveryList();
        fetchData();
        setAlert({ type: 'success', message: 'Marked as delivered successfully.', visible: true });
      } else {
        setAlert({ type: 'error', message: 'Failed to mark as delivered.', visible: true });
      }
    } catch (error) {
      console.error('Error marking as delivered:', error);
      setAlert({ type: 'error', message: 'Failed to mark as delivered.', visible: true });
    }
    onClose();
  };

  const openConfirmationDialog = (deliveryId) => {
    setSelectedDeliveryId(deliveryId);
    onOpen();
  };

  const closeAlert = () => setAlert({ type: '', message: '', visible: false });

  return (
    <Box pt={{ base: "120px", md: "80px", xl: "80px" }} p={4} mt={{ base: "100px", md: "100px", xl: "80px" }}>
      {/* <Text fontSize={["xl", "2xl", "2xl"]} fontWeight="bold" mb={4}>
        Delivery Tab
      </Text> */}
      {alert.visible && (
        <Alert status={alert.type} position="fixed" top="20px" right="20px" zIndex="1000" width="auto" boxShadow="lg">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>{alert.type === 'error' ? 'Error' : 'Success'}</AlertTitle>
            <AlertDescription>{alert.message}</AlertDescription>
          </Box>
          <CloseButton onClick={closeAlert} position="absolute" right="8px" top="8px" />
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <FormControl isRequired>
            <FormLabel>Date</FormLabel>
            <Input
              type="date"
              name="date"
              value={deliveryDetails.date}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>AWB Tracking No</FormLabel>
            <Input
              type="text"
              name="awb_tracking_number"
              value={deliveryDetails.awb_tracking_number}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Delivery Partner</FormLabel>
            <Select
              name="delivery_partner_id"
              value={deliveryDetails.delivery_partner_id}
              onChange={handleInputChange}
              placeholder="Select Delivery Partner"
            >
              {partners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </SimpleGrid>

        <Box mt={8}>
          <Text fontSize={["lg", "xl", "xl"]} fontWeight="bold" mb={4}>
            Items
          </Text>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Serial No.</Th>
                <Th>Name</Th>
                <Th>Quantity</Th>
                <Th>Shipped Quantity</Th>
                <Th>Delivered Quantity</Th>
                <Th>Enter Quantity</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((item, index) => (
                <Tr key={item.sku}>
                  <Td>{index + 1}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.quantity}</Td>
                  <Td>{item.shipped_quantity}</Td>
                  <Td>{item.delivered_quantity}</Td>
                  <Td>
                    <Input
                      type="number"
                      min="0"
                      max={item.quantity}
                      value={deliveryQuantities[item.sku] || ''}
                      onChange={(e) => handleQuantityChange(e, item.sku)}
                      key={`quantity-${item.sku}`}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Box textAlign="center" mt={4}>
          <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
            Submit
          </Button>
        </Box>
      </form>

      <Box mt={8}>
        <Text fontSize={["lg", "xl", "xl"]} fontWeight="bold" mb={4}>
          Delivery List
        </Text>
        <Stack spacing={4}>
          {deliveryList.map((delivery) => (
            <Flex
              key={delivery.id}
              p={4}
              bg="gray.100"
              borderRadius="md"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
              direction={{ base: "column", md: "row" }}
            >
              <Box>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                  <Text fontWeight="bold">Date: {delivery.date}</Text>
                  <Text fontWeight="bold">AWB Tracking No: {delivery.awb_tracking_number}</Text>
                  <Text fontWeight="bold">Delivery Partner: {delivery.delivery_partner}</Text>
                </SimpleGrid>
                <Box mt={2}>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Serial No.</Th>
                        <Th>Name</Th>
                        <Th>SKU</Th>
                        <Th>Quantity</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {delivery.items.map((item, index) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>{item.name}</Td>
                          <Td>{item.sku}</Td>
                          <Td>{item.quantity}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </Box>
              {!delivery.delivered && (
                <Box
                  position="absolute"
                  padding={4}
                  top="4px"
                  right="4px"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  // border="1px solid red"
                  // height={"100%"}
                >
                  <Text mb={6} color="green.500" fontWeight={500}>Delivery in Process</Text>
                  <Button
                    colorScheme="red"
                    onClick={() => openConfirmationDialog(delivery.id)}
                    size="sm"
                  >
                    Confirm & Deliver
                  </Button>
                </Box>
              )}
            </Flex>
          ))}
        </Stack>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Action
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to mark this delivered?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleMarkAsDelivered} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default DeliveryTab;
