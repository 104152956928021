// import React from "react";

// import { Icon } from "@chakra-ui/react";
// import {
//   MdBarChart,
//   MdPerson,
//   MdHome,
//   MdLock,
//   MdOutlineShoppingCart,
// } from "react-icons/md";

// // Admin Imports
// import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
// import DataTables from "views/admin/dataTables";
// import RTL from "views/admin/rtl";

// // Auth Imports
// import SignInCentered from "views/auth/signIn";

// const routes = [
//   {
//     name: "Main Dashboard",
//     layout: "/admin",
//     path: "/default",
//     icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
//     component: MainDashboard,
//   },
//   {
//     name: "NFT Marketplace",
//     layout: "/admin",
//     path: "/nft-marketplace",
//     icon: (
//       <Icon
//         as={MdOutlineShoppingCart}
//         width='20px'
//         height='20px'
//         color='inherit'
//       />
//     ),
//     component: NFTMarketplace,
//     secondary: true,
//   },
//   {
//     name: "Data Tables",
//     layout: "/admin",
//     icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
//     path: "/data-tables",
//     component: DataTables,
//   },
//   {
//     name: "Profile",
//     layout: "/admin",
//     path: "/profile",
//     icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
//     component: Profile,
//   },
//   {
//     name: "Sign In",
//     layout: "/auth",
//     path: "/sign-in",
//     icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
//     component: SignInCentered,
//   },
//   // {
//   //   name: "RTL Admin",
//   //   layout: "/rtl",
//   //   path: "/rtl-default",
//   //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
//   //   component: RTL,
//   // },
// ];

// export default routes;
// import React from "react";
// import { Icon } from "@chakra-ui/react";
// import {
//   MdBarChart,
//   MdPerson,
//   MdHome,
//   MdLock,
//   MdOutlineShoppingCart,
//   MdFolderOpen,
//   MdAccountCircle,
//   MdPeople,
//   MdAssignment,
//   MdFolder,
// } from "react-icons/md";

// // Admin Imports
// import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
// import DataTables from "views/admin/dataTables";
// import RTL from "views/admin/rtl";

// // Auth Imports
// import SignInCentered from "views/auth/signIn";
// import ClientMaster from "views/pages/ClientMaster";
// import UserMaster from "views/pages/UserMaster";
// import AgentMaster from "views/pages/AgentMaster";
// import BasicDetails from "views/pages/BasicDetails"; // Import the BasicDetails component
// import CustomerTeckPack from "views/pages/CustomerTeckPack";


// // Masters Imports

// const routes = [
//   {
//     name: "Main Dashboard",
//     layout: "/admin",
//     path: "/default",
//     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
//     component: MainDashboard,
//   },
//   // {
//   //   name: "NFT Marketplace",
//   //   layout: "/admin",
//   //   path: "/nft-marketplace",
//   //   icon: (
//   //     <Icon
//   //       as={MdOutlineShoppingCart}
//   //       width="20px"
//   //       height="20px"
//   //       color="inherit"
//   //     />
//   //   ),
//   //   component: NFTMarketplace,
//   //   secondary: true,
//   // },
//   {
//     name: "Masters",
//     layout: "/admin",
//     icon: <Icon as={MdFolderOpen} width="20px" height="20px" color="inherit" />,
//     path: "/masters",
//     children: [
//       {
//         name: "Client Master",
//         layout: "/admin",
//         path: "/masters/client-master",
//         icon: <Icon as={MdAccountCircle} width="20px" height="20px" color="inherit" />,
//         component: ClientMaster,
//       },
//       {
//         name: "User Master",
//         layout: "/admin",
//         path: "/masters/user-master",
//         icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
//         component: UserMaster,
//       },
//       {
//         name: "Agent Master",
//         layout: "/admin",
//         path: "/masters/agent-master",
//         icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
//         component: AgentMaster,
//       },
//     ],
//   },
//   {
//     name: "Basic Details",
//     layout: "/admin",
//     path: "/basic-details",
//     icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
//     component: BasicDetails,
//   },
//   {
//     name: "Customer Tech Pack",
//     layout: "/admin",
//     path: "/customer-tech-pack",
//     icon: <Icon as={MdFolder} width="20px" height="20px" color="inherit" />,
//     component: CustomerTeckPack,
//   },
//   {
//     name: "Data Tables",
//     layout: "/admin",
//     icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
//     path: "/data-tables",
//     component: DataTables,
//   },
//   {
//     name: "Profile",
//     layout: "/admin",
//     path: "/profile",
//     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
//     component: Profile,
//   },
//   {
//     name: "Sign In",
//     layout: "/auth",
//     path: "/sign-in",
//     icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
//     component: SignInCentered,
//   },
// ];

// export default routes;

// import React from "react";
// import { Icon } from "@chakra-ui/react";
// import {
//   MdBarChart,
//   MdPerson,
//   MdHome,
//   MdLock,
//   MdOutlineShoppingCart,
//   MdFolderOpen,
//   MdAccountCircle,
//   MdPeople,
//   MdAssignment,
//   MdFolder,MdTimeline,MdLocalShipping
// } from "react-icons/md";

// // Admin Imports
// import MainDashboard from "views/admin/default";

// import Profile from "views/admin/profile";
// // Auth Imports
// import SignInCentered from "views/auth/signIn";
// import ClientMaster from "views/pages/ClientMaster";
// import UserMaster from "views/pages/UserMaster";
// import AgentMaster from "views/pages/AgentMaster";
// import BasicDetails from "views/pages/BasicDetails";
// import CustomerTeckPack from "views/pages/CustomerTeckPack";
// import SamshekTeckPack from "views/pages/SamshekTeckPack";
// import ProgressTab from "views/pages/ProgressTab";
// import DeliveryTab from "views/pages/Deliverytab";
// import ItemMaster from "views/pages/ItemMaster";

// // Masters Imports

// const routes = [
//   {
//     name: "Main Dashboard",
//     layout: "/admin",
//     path: "/default",
//     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
//     component: MainDashboard,
//   },
//   {
//     name: "Masters",
//     layout: "/admin",
//     icon: <Icon as={MdFolderOpen} width="20px" height="20px" color="inherit" />,
//     path: "/masters",
//     children: [
//       {
//         name: "Client Master",
//         layout: "/admin",
//         path: "/masters/client-master",
//         icon: <Icon as={MdAccountCircle} width="20px" height="20px" color="inherit" />,
//         component: ClientMaster,
//       },
//       {
//         name: "Employee Master",
//         layout: "/admin",
//         path: "/masters/user-master",
//         icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
//         component: UserMaster,
//       },
//       {
//         name: "Agent Master",
//         layout: "/admin",
//         path: "/masters/agent-master",
//         icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
//         component: AgentMaster,
//       },
//       {
//         name: "Items Master",
//         layout: "/admin",
//         path: "/masters/item-master",
//         icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
//         component: ItemMaster,
//       },
//     ],
//   },
//   {
//     name: "Create Order",
//     layout: "/admin",
//     path: "/create-order",
//     icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
//     component: BasicDetails,
//     children: [
//       {
//         name: "Basic Details",
//         layout: "/admin",
//         path: "/create-order/basic-details",
//         icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
//         component: BasicDetails,
//       },
//       {
//         name: "Customer Tech Pack",
//         layout: "/admin",
//         path: "/create-order/customer-tech-pack",
//         icon: <Icon as={MdFolder} width="20px" height="20px" color="inherit" />,
//         component: CustomerTeckPack,
//       },
//       {
//         name: "Samshek Teck Pack",
//         layout: "/admin",
//         path: "/create-order/samshek-teck-pack",
//         icon: <Icon as={MdFolder} width="20px" height="20px" color="inherit" />,
//         component: SamshekTeckPack,
//       },
//       {
//         name: "Progress Tab",
//         layout: "/admin",
//         path: "/create-order/progress-tab",
//         icon: <Icon as={MdTimeline} width="20px" height="20px" color="inherit" />,
//         component: ProgressTab,
//       },
//       {
//         name: "Delivery Tab",
//         layout: "/admin",
//         path: "/create-order/delivery-tab",
//         icon: <Icon as={MdLocalShipping} width="20px" height="20px" color="inherit" />,
//         component: DeliveryTab,
//       },
//     ],
//   },
//   // {
//   //   name: "Data Tables",
//   //   layout: "/admin",
//   //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
//   //   path: "/data-tables",
//   //   component: DataTables,
//   // },
//   {
//     name: "Profile",
//     layout: "/admin",
//     path: "/profile",
//     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
//     component: Profile,
//   },
//   {
//     name: "Sign In",
//     layout: "/auth",
//     path: "/sign-in",
//     icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
//     component: SignInCentered,
//   },
// ];

// export default routes;


// routes.js

// import React from "react";
// import { Icon } from "@chakra-ui/react";
// import {
//   MdBarChart,
//   MdPerson,
//   MdHome,
//   MdLock,
//   MdOutlineShoppingCart,
//   MdFolderOpen,
//   MdAccountCircle,
//   MdPeople,
//   MdAssignment,
// } from "react-icons/md";

// // Admin Imports
// import MainDashboard from "views/admin/default";
// import Profile from "views/admin/profile";
// // Auth Imports
// import SignInCentered from "views/auth/signIn";
// import ClientMaster from "views/pages/ClientMaster";
// import UserMaster from "views/pages/UserMaster";
// import AgentMaster from "views/pages/AgentMaster";
// import ItemMaster from "views/pages/ItemMaster";
// import BasicDetails from "views/pages/BasicDetails";
// import DeliveryPartnerMaster from "views/pages/DeliveryPartnerMaster";
// import OrderDetails from "views/pages/OrderDetails"; // Import the OrderDetails component

// // Import Create Order Routes
// import createOrderRoutes from "./createOrderRoutes";
// import ProgressTypesMaster from "views/pages/ProgressTypesMaster";
// const role = localStorage.getItem("role");
// const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
// console.log("User Permissions:", permissions);
// const currentOrderId = localStorage.getItem("currentOrderId");
// const routes = [
//   {
//     name: "Main Dashboard",
//     layout: "/admin",
//     path: "/default",
//     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
//     component: MainDashboard,
//   },
//   role!=="CUSTOMER"&&{
//     name: "Masters",
//     layout: "/admin",
//     icon: <Icon as={MdFolderOpen} width="20px" height="20px" color="inherit" />,
//     path: "/masters",
//     children: [
//       role!== "AGENT"&&{
//         name: "Agent ",
//         layout: "/admin",
//         path: "/masters/agent-master",
//         icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
//         component: AgentMaster,
//       },
//      {
//         name: "Customer ",
//         layout: "/admin",
//         path: "/masters/client-master",
//         icon: <Icon as={MdAccountCircle} width="20px" height="20px" color="inherit" />,
//         component: ClientMaster,
//       },
//       !role!=="AGENT"&&{
//         name: "Employee ",
//         layout: "/admin",
//         path: "/masters/user-master",
//         icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
//         component: UserMaster,
//       },
//       // ...(currentOrderId ? [
//       //   {
//       //     name: "Employee ",
//       //     layout: "/admin",
//       //     path: "/masters/user-master",
//       //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
//       //     component: UserMaster,
//       //   },
//       // ] : []),
//       role!== "AGENT"&&{
//         name: "Items ",
//         layout: "/admin",
//         path: "/masters/item-master",
//         icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
//         component: ItemMaster,
//       },
//       role!== "AGENT"&&{
//         name: "Delivery Partner ",
//         layout: "/admin",
//         path: "/masters/delivery-partner-master",
//         icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
//         component: DeliveryPartnerMaster,
//       },
//       role!=="AGENT"&&{
//         name: "Progress Types",
//         layout: "/admin",
//         path: "/masters/progress-types",
//         icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
//         component: ProgressTypesMaster,
//       },
//     ],
//   },
//   // Spread the Create Order Routes here
//   ...createOrderRoutes,
//   {
//     name: "Create Order",
//     layout: "/admin",
//     // path: "/create-order",
//     path: "/create-order/basic-details",
//     icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
//     component: BasicDetails,
//   },
//   {
//     name: "Order Details",
//     layout: "/admin",
//     path: "/orders",
//     icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
//     component: OrderDetails,
//   },
//   // {
//   //   name: "Profile",
//   //   layout: "/admin",
//   //   path: "/profile",
//   //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
//   //   component: Profile,
//   // },
//   {
//     name: "Sign In",
//     layout: "/auth",
//     path: "/sign-in",
//     icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
//     component: SignInCentered,
//   },
// ];

// export default routes;

import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdBarChart, MdPerson, MdHome, MdLock, MdOutlineShoppingCart, MdFolderOpen, MdAccountCircle, MdPeople } from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import ClientMaster from "views/pages/ClientMaster";
import UserMaster from "views/pages/UserMaster";
import AgentMaster from "views/pages/AgentMaster";
import ItemMaster from "views/pages/ItemMaster";
import DeliveryPartnerMaster from "views/pages/DeliveryPartnerMaster";
import OrderDetails from "views/pages/OrderDetails";
import ProgressTypesMaster from "views/pages/ProgressTypesMaster";
import createOrderRoutes from "./createOrderRoutes";

// Import the SignInCentered component
import SignInCentered from "views/auth/signIn"; // <-- Make sure this path is correct based on your project structure

// Retrieve permissions from local storage
const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Masters",
    layout: "/admin",
    icon: <Icon as={MdFolderOpen} width="20px" height="20px" color="inherit" />,
    path: "/masters",
    children: [
      permissions.includes("agents.view") && {
        name: "Agent",
        layout: "/admin",
        path: "/masters/agent-master",
        icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
        component: AgentMaster,
      },
      permissions.includes("customers.view") && {
        name: "Customer",
        layout: "/admin",
        path: "/masters/client-master",
        icon: <Icon as={MdAccountCircle} width="20px" height="20px" color="inherit" />,
        component: ClientMaster,
      },
      permissions.includes("employees.view") && {
        name: "Employee",
        layout: "/admin",
        path: "/masters/user-master",
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
        component: UserMaster,
      },
      permissions.includes("items.view") && {
        name: "Items",
        layout: "/admin",
        path: "/masters/item-master",
        icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
        component: ItemMaster,
      },
      permissions.includes("delivery-partners.view") && {
        name: "Delivery Partner",
        layout: "/admin",
        path: "/masters/delivery-partner-master",
        icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
        component: DeliveryPartnerMaster,
      },
      permissions.includes("progress-types.view") && {
        name: "Progress Types",
        layout: "/admin",
        path: "/masters/progress-types",
        icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
        component: ProgressTypesMaster,
      },
    ].filter(Boolean),
  },
  ...createOrderRoutes, // Include the filtered createOrderRoutes
  {
    name: "Order Details",
    layout: "/admin",
    path: "/orders",
    icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
    component: OrderDetails,
    permission: "orders.index.view", // Assuming this is the permission needed to view Order Details
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered, // Now correctly defined
  },
].filter(route => !route.permission || permissions.includes(route.permission)); // Filter by permission

export default routes;
