import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Spinner,
} from "@chakra-ui/react";
import apiInstance from "api/api";

const DeliveryPartnerMaster = () => {
  const [partners, setPartners] = useState([]);
  const [currentPartner, setCurrentPartner] = useState(null);
  const [alertContent, setAlertContent] = useState({ status: "", message: "" });
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef();

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    setIsLoading(true);
    try {
      const response = await apiInstance.get("/delivery-partners");
      const partners = response.data.data["delivery-partners"] || [];
      setPartners(partners);
      console.log("response", partners);
    } catch (error) {
      console.error("Error fetching partners", error);
      showAlert("error", "Error fetching partners.");
    } finally {
      setIsLoading(false);
    }
  };

  const showAlert = (status, message) => {
    setAlertContent({ status, message });
    onAlertOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentPartner((prevPartner) => ({
      ...prevPartner,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPartner = { name: currentPartner.name };

    try {
      if (currentPartner.id) {
        await apiInstance.put(`/delivery-partners/${currentPartner.id}`, newPartner);
        showAlert("success", "Partner updated successfully.");
      } else {
        await apiInstance.post("/delivery-partners", newPartner);
        showAlert("success", "Partner added successfully.");
      }
      fetchPartners();
      setCurrentPartner(null);
      onClose();
    } catch (error) {
      console.error("Error saving partner", error);
      showAlert("error", "Error saving partner.");
    }
  };

  const handleAdd = () => {
    setCurrentPartner(null);
    onOpen();
  };

  const handleEdit = (partner) => {
    setCurrentPartner(partner);
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      await apiInstance.delete(`/delivery-partners/${id}`);
      showAlert("success", "Partner deleted successfully.");
      fetchPartners();
    } catch (error) {
      console.error("Error deleting partner", error);
      showAlert("error", "Error deleting partner.");
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="2xl" fontWeight="bold">
            Delivery Partner Master
          </Text>
          <Button colorScheme="teal" onClick={handleAdd}>
            Add Partner
          </Button>
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Stack spacing={4}>
            {Array.isArray(partners) &&
              partners.map((partner) => (
                <Flex
                  key={partner.id}
                  justifyContent="space-between"
                  alignItems="center"
                  p={4}
                  bg="gray.100"
                  borderRadius="md"
                >
                  <Box>
                    <Text fontWeight="bold">Name: {partner.name}</Text>
                  </Box>
                  <Flex>
                    <Button
                      colorScheme="blue"
                      mr={2}
                      onClick={() => handleEdit(partner)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(partner.id)}
                    >
                      Delete
                    </Button>
                  </Flex>
                </Flex>
              ))}
          </Stack>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {currentPartner && currentPartner.id
                ? "Edit Partner"
                : "Add Partner"}
            </ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <FormControl isRequired mb={3}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    name="name"
                    value={currentPartner?.name || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  {currentPartner && currentPartner.id ? "Update" : "Submit"}
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {alertContent.status === "error" ? "Error" : "Success"}
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>{alertContent.message}</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default DeliveryPartnerMaster;
