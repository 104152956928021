

// // Chakra imports
// import {
//   Avatar,
//   Box,
//   Flex,
//   FormLabel,
//   Icon,
//   Select,
//   SimpleGrid,
//   useColorModeValue,
// } from "@chakra-ui/react";
// // Assets
// import Usa from "assets/img/dashboards/usa.png";
// // Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
// import MiniStatistics from "components/card/MiniStatistics";
// import IconBox from "components/icons/IconBox";
// import React from "react";
// import {
//   MdAddTask,
//   MdAttachMoney,
//   MdBarChart,
//   MdFileCopy,
// } from "react-icons/md";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import PieCard from "views/admin/default/components/PieCard";
// import Tasks from "views/admin/default/components/Tasks";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import {
//   columnsDataCheck,
//   columnsDataComplex,
// } from "views/admin/default/variables/columnsData";
// import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
// import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

// export default function UserReports() {
//   // Chakra Color Mode
//   const brandColor = useColorModeValue("brand.500", "white");
//   const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <SimpleGrid
//         columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
//         gap='20px'
//         mb='20px'>
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
//               }
//             />
//           }
//           name='Earnings'
//           value='$350.4'
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
//               }
//             />
//           }
//           name='Spend this month'
//           value='$642.39'
//         />
//         <MiniStatistics growth='+23%' name='Sales' value='$574.34' />
//         <MiniStatistics
//           endContent={
//             <Flex me='-16px' mt='10px'>
//               <FormLabel htmlFor='balance'>
//                 <Avatar src={Usa} />
//               </FormLabel>
//               <Select
//                 id='balance'
//                 variant='mini'
//                 mt='5px'
//                 me='0px'
//                 defaultValue='usd'>
//                 <option value='usd'>USD</option>
//                 <option value='eur'>EUR</option>
//                 <option value='gba'>GBA</option>
//               </Select>
//             </Flex>
//           }
//           name='Your balance'
//           value='$1,000'
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
//               icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
//             />
//           }
//           name='New Tasks'
//           value='154'
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
//               }
//             />
//           }
//           name='Total Projects'
//           value='2935'
//         />
//       </SimpleGrid>

//       <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
//         <TotalSpent />
//         <WeeklyRevenue />
//       </SimpleGrid>
//       <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
//         <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
//         <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
//           <DailyTraffic />
//           <PieCard />
//         </SimpleGrid>
//       </SimpleGrid>
//       <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
//         <ComplexTable
//           columnsData={columnsDataComplex}
//           tableData={tableDataComplex}
//         />
//         <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
//           <Tasks />
//           <MiniCalendar h='100%' minW='100%' selectRange={false} />
//         </SimpleGrid>
//       </SimpleGrid>
//     </Box>
//   );
// }



// import {
//   Avatar,
//   Box,
//   Flex,
//   FormLabel,
//   Icon,
//   Select,
//   SimpleGrid,
//   useColorModeValue,
// } from "@chakra-ui/react";
// // Assets
// import Usa from "assets/img/dashboards/usa.png";
// // Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
// import MiniStatistics from "components/card/MiniStatistics";
// import IconBox from "components/icons/IconBox";
// import React from "react";
// import {
//   MdAddTask,
//   MdAreaChart,
//   MdAttachMoney,
//   MdBarChart,
//   MdFileCopy,
//   MdLocalShipping,
//   MdShoppingCart,
// } from "react-icons/md";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import PieCard from "views/admin/default/components/PieCard";
// import Tasks from "views/admin/default/components/Tasks";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import {
//   columnsDataCheck,
//   columnsDataComplex,
// } from "views/admin/default/variables/columnsData";
// import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
// import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

// export default function UserReports() {
//   // Chakra Color Mode
//   const brandColor = useColorModeValue("brand.500", "white");
//   const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <SimpleGrid
//         columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
//         gap='20px'
//         mb='20px'>
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
//               }
//             />
//           }
//           name="Total Samples"
//           value='50'
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdAreaChart} color={brandColor} />
//               }
//             />
//           }
//           name="Samples On Time"
//           value='30'
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
//               }
//             />
//           }
//           name="Sample Growth"
//           value='+23%'
//         />
//       </SimpleGrid>

//       <SimpleGrid
//         columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
//         gap='20px'
//         mb='20px'>
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdShoppingCart} color={brandColor} />
//               }
//             />
//           }
//           name="Total Production Orders"
//           value='150'
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdLocalShipping} color={brandColor} />
//               }
//             />
//           }
//           name="Production Orders Pending"
//           value='45'
//         />
//         {/* <MiniStatistics
//           startContent={
//             <IconBox
//               w='56px'
//               h='56px'
//               bg={boxBg}
//               icon={
//                 <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
//               }
//             />
//           }
//           name="Sample Expenses"
//           value='$574.34'
//         /> */}
//       </SimpleGrid>

//       <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
//         {/* <TotalSpent /> */}
//         <WeeklyRevenue />
//       </SimpleGrid>
//       {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
//         <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
//         <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
//           <DailyTraffic />
//           <PieCard />
//         </SimpleGrid>
//       </SimpleGrid> */}
//       {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
//         <ComplexTable
//           columnsData={columnsDataComplex}
//           tableData={tableDataComplex}
//         />
//         <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
//           <Tasks />
//           <MiniCalendar h='100%' minW='100%' selectRange={false} />
//         </SimpleGrid>
//       </SimpleGrid> */}
//     </Box>
//   );
// }


// import {
//   Avatar,
//   Box,
//   Flex,
//   FormLabel,
//   Icon,
//   Select,
//   SimpleGrid,
//   useColorModeValue,
//   Text,
// } from "@chakra-ui/react";
// // Assets
// import Usa from "assets/img/dashboards/usa.png";
// // Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
// import MiniStatistics from "components/card/MiniStatistics";
// import IconBox from "components/icons/IconBox";
// import React, { useState } from "react";
// import {
//   MdAddTask,
//   MdAreaChart,
//   MdAttachMoney,
//   MdBarChart,
//   MdFileCopy,
//   MdLocalShipping,
//   MdShoppingCart,
// } from "react-icons/md";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import PieCard from "views/admin/default/components/PieCard";
// import Tasks from "views/admin/default/components/Tasks";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import {
//   columnsDataCheck,
//   columnsDataComplex,
// } from "views/admin/default/variables/columnsData";
// import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
// import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

// export default function UserReports() {
//   // Chakra Color Mode
//   const brandColor = useColorModeValue("brand.500", "white");
//   const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

//   // State to manage selected time range
//   const [timeRange, setTimeRange] = useState("Last 7 Days");

//   // Handler for changing time range
//   const handleTimeRangeChange = (event) => {
//     setTimeRange(event.target.value);
//     // Logic to fetch and display data according to the selected time range
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <Flex justify="space-between" align="center" mb="20px">
//         <Text fontSize="2xl" fontWeight="bold">
//          Reports
//         </Text>
//         <Select
//           w={{ base: "60%", md: "30%", lg: "20%" }}
//           bg={boxBg}
//           value={timeRange}
//           onChange={handleTimeRangeChange}
//           placeholder="Select Time Range"
//         >
//           <option value="Last 7 Days">Last 7 Days</option>
//           <option value="Last 30 Days">Last 30 Days</option>
//           <option value="Six Months">Six Months</option>
//           <option value="Yearly">Yearly</option>
//         </Select>
//       </Flex>

//       <SimpleGrid
//         columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
//         gap="20px"
//         mb="20px"
//       >
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={
//                 <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
//               }
//             />
//           }
//           name="Total Samples"
//           value="50"
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={
//                 <Icon w="32px" h="32px" as={MdAreaChart} color={brandColor} />
//               }
//             />
//           }
//           name="Samples On Time"
//           value="30"
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={
//                 <Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />
//               }
//             />
//           }
//           name="Sample Growth"
//           value="+23%"
//         />
//       </SimpleGrid>

//       <SimpleGrid
//         columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
//         gap="20px"
//         mb="20px"
//       >
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={
//                 <Icon
//                   w="32px"
//                   h="32px"
//                   as={MdShoppingCart}
//                   color={brandColor}
//                 />
//               }
//             />
//           }
//           name="Total Production Orders"
//           value="150"
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={
//                 <Icon
//                   w="32px"
//                   h="32px"
//                   as={MdLocalShipping}
//                   color={brandColor}
//                 />
//               }
//             />
//           }
//           name="Production Orders Pending"
//           value="45"
//         />
//       </SimpleGrid>

//       <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
//         <WeeklyRevenue />
//       </SimpleGrid>
//     </Box>
//   );
// }


// import React, { useState, useEffect } from "react";
// import {
//   Avatar,
//   Box,
//   Flex,
//   FormLabel,
//   Icon,
//   Select,
//   SimpleGrid,
//   useColorModeValue,
//   Text,
// } from "@chakra-ui/react";
// import {
//   MdAddTask,
//   MdAreaChart,
//   MdAttachMoney,
//   MdBarChart,
//   MdFileCopy,
//   MdLocalShipping,
//   MdShoppingCart,
// } from "react-icons/md";
// import IconBox from "components/icons/IconBox";
// import MiniStatistics from "components/card/MiniStatistics";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import axios from "axios";
// import apiInstance from "api/api";

// export default function UserReports() {
//   const brandColor = useColorModeValue("brand.500", "white");
//   const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

//   const [timeRange, setTimeRange] = useState("Last 7 Days");
//   const [data, setData] = useState({
//     sample_orders_count: 0,
//     sample_orders_on_time_count: 0,
//     sample_orders_growth: 0,
//     production_orders_count: 0,
//     production_orders_pending_count: 0,
//     sample_orders_growth_monthly: [],
//     production_orders_growth_monthly: [],
//   });

//   useEffect(() => {
//     fetchData(timeRange);
//   }, [timeRange]);

//   const handleTimeRangeChange = (event) => {
//     setTimeRange(event.target.value);
//   };

//   const fetchData = async (duration) => {
//     const durationMapping = {
//       "Last 7 Days": "last_seven_days",
//       "Last 30 Days": "last_thirty_days",
//       "Six Months": "last_six_months",
//       "Yearly": "last_year",
//     };
  
//     try {
//       const formattedDuration = durationMapping[duration];
//       const response = await apiInstance.get(`/dashboard?duration=${formattedDuration}`);
//       if (response.data.status) {
//         setData(response.data.data);
//       } else {
//         console.error("Error fetching data:", response.data.message);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
  

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <Flex justify="space-between" align="center" mb="20px">
//         <Text fontSize="2xl" fontWeight="bold">
//           Reports
//         </Text>
//         <Select
//           w={{ base: "60%", md: "30%", lg: "20%" }}
//           bg={boxBg}
//           value={timeRange}
//           onChange={handleTimeRangeChange}
//           placeholder="Select Time Range"
//         >
//           <option value="Last 7 Days">Last 7 Days</option>
//           <option value="Last 30 Days">Last 30 Days</option>
//           <option value="Six Months">Six Months</option>
//           <option value="Yearly">Yearly</option>
//         </Select>
//       </Flex>

//       <SimpleGrid
//         columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
//         gap="20px"
//         mb="20px"
//       >
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={<Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />}
//             />
//           }
//           name="Total Samples"
//           value={data.sample_orders_count}
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={<Icon w="32px" h="32px" as={MdAreaChart} color={brandColor} />}
//             />
//           }
//           name="Samples On Time"
//           value={data.sample_orders_on_time_count}
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={<Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />}
//             />
//           }
//           name="Sample Growth"
//           value={data.sample_orders_growth ? `${data.sample_orders_growth}%` : "N/A"}
//         />
//       </SimpleGrid>

//       <SimpleGrid
//         columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
//         gap="20px"
//         mb="20px"
//       >
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={<Icon w="32px" h="32px" as={MdShoppingCart} color={brandColor} />}
//             />
//           }
//           name="Total Production Orders"
//           value={data.production_orders_count}
//         />
//         <MiniStatistics
//           startContent={
//             <IconBox
//               w="56px"
//               h="56px"
//               bg={boxBg}
//               icon={<Icon w="32px" h="32px" as={MdLocalShipping} color={brandColor} />}
//             />
//           }
//           name="Production Orders Pending"
//           value={data.production_orders_pending_count}
//         />
//       </SimpleGrid>

//       <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
//         <WeeklyRevenue />
//       </SimpleGrid>
//     </Box>
//   );
// }

import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Select,
  SimpleGrid,
  useColorModeValue,
  Text,
  Icon,
} from "@chakra-ui/react";
import {
  MdBarChart,
  MdTimer,
  MdTrendingUp,
  MdAreaChart,
  MdLocalShipping,
} from "react-icons/md"; // Importing required icons
import IconBox from "components/icons/IconBox"; // Ensure this path is correct
import MiniStatistics from "components/card/MiniStatistics";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import apiInstance from "api/api";
import ProductionSampleBarGraph from "./components/ProductionSampleBarGraph";

export default function UserReports() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [timeRange, setTimeRange] = useState("Last 7 Days");
  const [data, setData] = useState({
    sample_orders_count: 0,
    sample_orders_on_time_count: 0,
    sample_orders_growth: 0,
    production_orders_count: 0,
    production_orders_pending_count: 0,
    sample_orders_growth_monthly: [],
    production_orders_growth_monthly: [],
  });

  useEffect(() => {
    fetchData(timeRange);
  }, [timeRange]);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const fetchData = async (duration) => {
    const durationMapping = {
      "Last 7 Days": "last_seven_days",
      "Last 30 Days": "last_thirty_days",
      "Six Months": "last_six_months",
      "Yearly": "last_year",
    };

    try {
      const formattedDuration = durationMapping[duration];
      const response = await apiInstance.get(`/dashboard?duration=${formattedDuration}`);
      if (response.data.status) {
        setData(response.data.data);
      } else {
        console.error("Error fetching data:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex justify="space-between" align="center" mb="20px">
        <Text fontSize="2xl" fontWeight="bold">
          Reports
        </Text>
        <Select
          w={{ base: "60%", md: "30%", lg: "20%" }}
          bg={boxBg}
          value={timeRange}
          onChange={handleTimeRangeChange}
          placeholder="Select Time Range"
        >
          <option value="Last 7 Days">Last 7 Days</option>
          <option value="Last 30 Days">Last 30 Days</option>
          <option value="Six Months">Six Months</option>
          <option value="Yearly">Yearly</option>
        </Select>
      </Flex>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />}
            />
          }
          name="Total Samples"
          value={data.sample_orders_count}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdTimer} color={brandColor} />}
            />
          }
          name="Samples On Time"
          value={data.sample_orders_on_time_count}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdTrendingUp} color={brandColor} />}
            />
          }
          name="Sample Growth"
          value={data.sample_orders_growth ? `${data.sample_orders_growth}%` : "N/A"}
        />
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdAreaChart} color={brandColor} />}
            />
          }
          name="Total Production Orders"
          value={data.production_orders_count}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdLocalShipping} color={brandColor} />}
            />
          }
          name="Production Orders Pending"
          value={data.production_orders_pending_count}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
  <ProductionSampleBarGraph
    title="Sample Orders Growth"
    data={data.sample_orders_growth_monthly}
    isHorizontal={true}
    colors={["#FF4500"]} // Orange color for Sample Orders Growth
  />
  <ProductionSampleBarGraph
    title="Production Orders Growth"
    data={data.production_orders_growth_monthly}
    isHorizontal={false}
    colors={["#32CD32"]} // Green color for Production Orders Growth
  />
</SimpleGrid>


      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <WeeklyRevenue />
      </SimpleGrid> */}
    </Box>
  );
}
