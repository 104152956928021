// import React from "react";
// import Chart from "react-apexcharts";

// const ProductionSampleBarGraph = ({ title, data }) => {
//   const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

//   const mapDataToSeries = (data) => {
//     const seriesData = new Array(12).fill(0);
//     data.forEach(item => {
//       seriesData[item.month - 1] = item.total;
//     });
//     return seriesData;
//   };

//   const seriesData = mapDataToSeries(data);

//   const chartOptions = {
//     chart: {
//       type: 'bar',
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '55%',
//       },
//     },
//     xaxis: {
//       categories: months,
//     },
//     fill: {
//       opacity: 1,
//     },
//     title: {
//       text: title,
//       align: 'center',
//     },
//   };

//   const chartSeries = [
//     {
//       name: title,
//       data: seriesData,
//     }
//   ];

//   return (
//     <Chart
//       options={chartOptions}
//       series={chartSeries}
//       type="bar"
//       height={350}
//     />
//   );
// };

// export default ProductionSampleBarGraph;

import React from "react";
import Chart from "react-apexcharts";

const ProductionSampleBarGraph = ({ title, data, isHorizontal, colors }) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    const mapDataToSeries = (data) => {
      const seriesData = new Array(12).fill(0);
      data.forEach(item => {
        seriesData[item.month - 1] = item.total;
      });
      return seriesData;
    };
  
    const seriesData = mapDataToSeries(data);
  
    const chartOptions = {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
        //   horizontal: isHorizontal, // Adjusts the chart to be horizontal or vertical
          columnWidth: '55%',
        },
      },
      xaxis: {
        categories: months,
      },
    //   yaxis: {
    //     max: 10, // Setting the default maximum value of the y-axis to 10
    //   },
      fill: {
        colors: colors, // Use the colors passed as a prop
        opacity: 0.8, // Adjusting the opacity for better visibility
      },
      title: {
        text: title,
        align: 'center',
      },
    };
  
    const chartSeries = [
      {
        name: title,
        data: seriesData,
      }
    ];
  
    return (
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    );
  };
  
  export default ProductionSampleBarGraph;
  
  
  
  