// import axios from "axios";

// const apiInstance = axios.create({
//   baseURL: 'http://api.samshek.zipzap.in/api', // Base URL for the API
//   headers: {
//     "Access-Control-Allow-Origin": "*",

//     Accept: "application/json",
//     withCredentials: false,
//     "Content-Type": "application/json",
//   },
// });

// apiInstance.interceptors.request.use(
//   (config) => {
//     config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// apiInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     console.log({error});
//     if (error.response && error.response.status === 401) {
//       // Clear local storage
//       // alert("Please Login First");
//       localStorage.clear();

//       // Show alert

//       // Redirect to "/" route
//       window.location.href = "/auth";
//     }
//     return Promise.reject(error);
//   }
// );

// export default apiInstance;

// apiInstance.js
// apiInstance.js

// import axios from "axios";

// const apiInstance = axios.create({
//   // baseURL: 'https://api.samshek.zipzap.in/api',
//   baseURL: 'http://192.168.18.11:8010/api',
// // baseURL:'https://api.b2b.samshek.com/api',
// //baseURL:'https://api.mymanufacturinglab.com/api',
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     Accept: "application/json",
//     withCredentials: false,
//     "Content-Type": "application/json",
//   },
// });

// apiInstance.interceptors.request.use(
//   (config) => {
//     config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// apiInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       localStorage.clear();
//       window.location.href = "/#/auth/sign-in"; // Use hash routing
//     }
//     return Promise.reject(error);
//   }
// );

// export default apiInstance;


import axios from "axios";
import eventEmitter from "views/pages/eventEmitter";
// Import the event emitter

const apiInstance = axios.create({
  // baseURL: 'http://192.168.18.11:8010/api',
  baseURL:'https://api.mymanufacturinglab.com/api',
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    withCredentials: false,
    "Content-Type": "application/json",
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/#/auth/sign-in"; // Use hash routing
    }
    if (error.response && error.response.status === 403) {
      eventEmitter.emit("show403Dialog"); // Emit the show403Dialog event
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
