// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Flex,
//   Input,
//   Stack,
//   Text,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   FormControl,
//   FormLabel,
//   useToast,
// } from "@chakra-ui/react";

// const UserMaster = () => {
//   const [users, setUsers] = useState([]);
//   const [currentUser, setCurrentUser] = useState(null);
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const //toast = useToast();

//   useEffect(() => {
//     const storedUsers = JSON.parse(localStorage.getItem("users")) || [];
//     setUsers(storedUsers);
//   }, []);

//   const saveUsers = (users) => {
//     localStorage.setItem("users", JSON.stringify(users));
//     setUsers(users);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const { email,name, password, role } = e.target.elements;
//     const newUser = {
//       id: currentUser?.id || Date.now(),
//       email:email.value,
//       name: name.value,
//       password: password.value,
//       role: role.value,
//     };

//     if (currentUser) {
//       const updatedUsers = users.map((user) =>
//         user.id === currentUser.id ? newUser : user
//       );
//       saveUsers(updatedUsers);
//       //toast({ title: "User updated.", status: "success" });
//     } else {
//       saveUsers([...users, newUser]);
//       //toast({ title: "User added.", status: "success" });
//     }

//     setCurrentUser(null);
//     onClose();
//   };

//   const handleEdit = (user) => {
//     setCurrentUser(user);
//     onOpen();
//   };

//   const handleDelete = (id) => {
//     const updatedUsers = users.filter((user) => user.id !== id);
//     saveUsers(updatedUsers);
//     //toast({ title: "User deleted.", status: "success" });
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <Box p={4}>
//         <Flex justifyContent="space-between" alignItems="center" mb={4}>
//           <Text fontSize="2xl" fontWeight="bold">
//             Employee Master
//           </Text>
//           <Button colorScheme="teal" onClick={onOpen}>
//             Add Employee
//           </Button>
//         </Flex>

//         <Stack spacing={4}>
//           {users.map((user) => (
//             <Flex
//               key={user.id}
//               justifyContent="space-between"
//               alignItems="center"
//               p={4}
//               bg="gray.100"
//               borderRadius="md"
//             >
//               <Box>
//               <Text fontWeight="bold">Email: {user.email}</Text>
//                 <Text>Name: {user.name}</Text>
//                 <Text>Role: {user.role}</Text>
//               </Box>
//               <Flex>
//                 <Button colorScheme="blue" mr={2} onClick={() => handleEdit(user)}>
//                   Edit
//                 </Button>
//                 <Button colorScheme="red" onClick={() => handleDelete(user.id)}>
//                   Delete
//                 </Button>
//               </Flex>
//             </Flex>
//           ))}
//         </Stack>

//         <Modal isOpen={isOpen} onClose={onClose}>
//           <ModalOverlay />
//           <ModalContent>
//             <ModalHeader>{currentUser ? "Edit Employee" : "Add Employee"}</ModalHeader>
//             <ModalCloseButton />
//             <form onSubmit={handleSubmit}>
//               <ModalBody>
//               <FormControl isRequired mb={3}>
//                   <FormLabel>Email</FormLabel>
//                   <Input name="email" defaultValue={currentUser?.email || ""} />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Name</FormLabel>
//                   <Input name="name" defaultValue={currentUser?.name || ""} />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Password</FormLabel>
//                   <Input type="password" name="password" defaultValue={currentUser?.password || ""} />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Role</FormLabel>
//                   <Input name="role" defaultValue={currentUser?.role || ""} />
//                 </FormControl>
//               </ModalBody>
//               <ModalFooter>
//                 <Button colorScheme="blue" mr={3} type="submit">
//                   {currentUser ? "Update" : "Submit"}
//                 </Button>
//                 <Button variant="ghost" onClick={onClose}>
//                   Cancel
//                 </Button>
//               </ModalFooter>
//             </form>
//           </ModalContent>
//         </Modal>
//       </Box>
//     </Box>
//   );
// };

// export default UserMaster;

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Flex,
//   Input,
//   Stack,
//   Text,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   FormControl,
//   FormLabel,
//   AlertDialog,
//   AlertDialogBody,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogContent,
//   AlertDialogOverlay,
//   AlertDialogCloseButton,
//   Spinner,
// } from "@chakra-ui/react";
// import apiInstance from "api/api";
// import AccessDeniedDialog from "./AccessDeniedDialog";

// const UserMaster = () => {
//   const [users, setUsers] = useState([]);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const {
//     isOpen: isAlertOpen,
//     onOpen: onAlertOpen,
//     onClose: onAlertClose,
//   } = useDisclosure();
//   const [alertContent, setAlertContent] = useState("");
//   const cancelRef = React.useRef();

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = async () => {
//     setIsLoading(true);
//     try {
//       const response = await apiInstance.get("/employees");
//       const users = response.data.data.employees || [];
//       setUsers(users);
//       console.log("response", users);
//     } catch (error) {
//       console.error("Error fetching users", error.response.status);
      
//       // Only show the alert if the error is not a 403
//       if (error.response.status !== 403) {
//         showAlert("Error fetching users.");
//       }
//     } finally {
//       setIsLoading(false);
//     }
//   };
  

//   const showAlert = (content) => {
//     setAlertContent(content);
//     onAlertOpen();
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const { email, name, password, role } = e.target.elements;
//     if (password.value.length < 8) {
//       showAlert("Password must be at least 8 characters long.");
//       return;
//     }
    
//     const newUser = {
//       email: email.value,
//       name: name.value,
//       password: password.value,
//       role: role.value,
//     };
  
//     try {
//       if (currentUser) {
//         // Edit existing user
//         const response = await apiInstance.put(`/employees/${currentUser.id}`, newUser);
//         if (response.data && response.data.message) {
//           showAlert(response.data.message); // Display the success message from response
//         } else {
//           showAlert("Employee updated successfully.");
//         }
//       } else {
//         // Create new user
//         const response = await apiInstance.post("/employees", newUser);
//         if (response.data && response.data.message) {
//           showAlert(response.data.message); // Display the success message from response
//         } else {
//           showAlert("Employee added successfully.");
//         }
//       }
      
//       fetchUsers();
//       setCurrentUser(null);
//       onClose();
//     } catch (error) {
//       console.error("Error saving user", error);
//       showAlert("Error saving user.");
//     }
//   };
  

//   const handleEdit = (user) => {
//     setCurrentUser(user);
//     onOpen();
//   };

//   const handleDelete = async (id) => {
//     try {
//       const response = await apiInstance.delete(`/employees/${id}`);
//       showAlert(response.data.data.message);
//       fetchUsers();
//     } catch (error) {
//       console.error("Error deleting user", error);
//       showAlert("Error deleting user.");
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <Box p={4}>
//         <Flex justifyContent="space-between" alignItems="center" mb={4}>
//           <Text fontSize="2xl" fontWeight="bold">
//             Employee Master
//           </Text>
//           <Button colorScheme="teal" onClick={onOpen}>
//             Add Employee
//           </Button>
//         </Flex>

//         {isLoading ? (
//           <Flex justifyContent="center" alignItems="center" height="200px">
//             <Spinner size="xl" />
//           </Flex>
//         ) : (
//           <Stack spacing={4}>
//             {Array.isArray(users) &&
//               users.map((user) => (
//                 <Flex
//                   key={user.id}
//                   justifyContent="space-between"
//                   alignItems="center"
//                   p={4}
//                   bg="gray.100"
//                   borderRadius="md"
//                 >
//                   <Box>
//                     <Text fontWeight="bold">Email: {user.email}</Text>
//                     <Text>Name: {user.name}</Text>
//                     <Text>Role: {user.role}</Text>
//                   </Box>
//                   <Flex>
//                     <Button
//                       colorScheme="blue"
//                       mr={2}
//                       onClick={() => handleEdit(user)}
//                     >
//                       Edit
//                     </Button>
//                     <Button
//                       colorScheme="red"
//                       onClick={() => handleDelete(user.id)}
//                     >
//                       Delete
//                     </Button>
//                   </Flex>
//                 </Flex>
//               ))}
//           </Stack>
//         )}

//         <Modal isOpen={isOpen} onClose={onClose}>
//           <ModalOverlay />
//           <ModalContent>
//             <ModalHeader>
//               {currentUser ? "Edit Employee" : "Add Employee"}
//             </ModalHeader>
//             <ModalCloseButton />
//             <form onSubmit={handleSubmit}>
//               <ModalBody>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Email</FormLabel>
//                   <Input name="email" defaultValue={currentUser?.email || ""} />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Name</FormLabel>
//                   <Input name="name" defaultValue={currentUser?.name || ""} />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Password</FormLabel>
//                   <Input
//                     type="password"
//                     name="password"
//                     defaultValue={currentUser?.password || ""}
//                   />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Role</FormLabel>
//                   <Input name="role" defaultValue={currentUser?.role || ""} />
//                 </FormControl>
//               </ModalBody>
//               <ModalFooter>
//                 <Button colorScheme="blue" mr={3} type="submit">
//                   {currentUser ? "Update" : "Submit"}
//                 </Button>
//                 <Button variant="ghost" onClick={onClose}>
//                   Cancel
//                 </Button>
//               </ModalFooter>
//             </form>
//           </ModalContent>
//         </Modal>

//         <AlertDialog
//           isOpen={isAlertOpen}
//           leastDestructiveRef={cancelRef}
//           onClose={onAlertClose}
//         >
//           <AlertDialogOverlay>
//             <AlertDialogContent>
//               <AlertDialogHeader fontSize="lg" fontWeight="bold">
//                 Alert
//               </AlertDialogHeader>
//               <AlertDialogCloseButton />
//               <AlertDialogBody>{alertContent}</AlertDialogBody>
//               <AlertDialogFooter>
//                 <Button ref={cancelRef} onClick={onAlertClose}>
//                   OK
//                 </Button>
//               </AlertDialogFooter>
//             </AlertDialogContent>
//           </AlertDialogOverlay>
//         </AlertDialog>
//       </Box>
//     </Box>
//   );
// };

// export default UserMaster;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Spinner,
  Checkbox,
} from "@chakra-ui/react";
import apiInstance from "api/api";

const UserMaster = () => {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const {
    isOpen: isPermissionOpen,
    onOpen: onPermissionOpen,
    onClose: onPermissionClose,
  } = useDisclosure(); // Permission modal control
  const [alertContent, setAlertContent] = useState("");
  const cancelRef = React.useRef();
  const role = localStorage.getItem("role");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await apiInstance.get("/employees");
      const users = response.data.data.employees || [];
      setUsers(users);
    } catch (error) {
      console.error("Error fetching users", error);
      showAlert("Error fetching users.");
    } finally {
      setIsLoading(false);
    }
  };

  const showAlert = (content) => {
    setAlertContent(content);
    onAlertOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, name, password, role } = e.target.elements;
    if (password.value.length < 8) {
      showAlert("Password must be at least 8 characters long.");
      return;
    }
    const newUser = {
      email: email.value,
      name: name.value,
      password: password.value,
      role: role.value,
    };

    try {
      if (currentUser) {
        const response = await apiInstance.put(`/employees/${currentUser.id}`, newUser);
        showAlert(response.data.data.message);
      } else {
        await apiInstance.post("/employees", newUser);
        showAlert("Employee added successfully.");
      }
      fetchUsers();
      setCurrentUser(null);
      onClose();
    } catch (error) {
      console.error("Error saving user", error);
      showAlert("Error saving user.");
    }
  };

  const handleEdit = (user) => {
    setCurrentUser(user);
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      const response = await apiInstance.delete(`/employees/${id}`);
      showAlert(response.data.data.message);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user", error);
      showAlert("Error deleting user.");
    }
  };

  const handleAddPermission = async (userId) => {
    try {
      setCurrentUserId(userId);
      const response = await apiInstance.get(`/permissions?user_id=${userId}`);
      setPermissions(response.data.data.permissions);
      onPermissionOpen();
    } catch (error) {
      console.error("Error fetching permissions", error);
      showAlert("Error fetching permissions.");
    }
  };

  const handleSavePermissions = async () => {
    const checkedPermissions = [];
    const userId = currentUserId;

    if (!userId) {
      showAlert("User ID is missing. Cannot proceed.");
      return;
    }

    const findCheckedPermissions = (permissionsObj, parentKey = "") => {
      Object.entries(permissionsObj).forEach(([key, value]) => {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;

        if (value && typeof value.has === "boolean" && value.has) {
          checkedPermissions.push(currentKey);
        } else if (typeof value === "object") {
          findCheckedPermissions(value, currentKey);
        }
      });
    };

    findCheckedPermissions(permissions);

    const payload = {
      user_id: userId,
      permissions: checkedPermissions,
    };

    try {
      await apiInstance.post("/permissions", payload);
      showAlert("Permissions updated successfully.");
    } catch (error) {
      console.error("Error updating permissions", error);
      showAlert("Error updating permissions.");
    }

    onPermissionClose();
  };

  const handlePermissionChange = (fullKey) => {
    setPermissions((prevPermissions) => {
      const keys = fullKey.split(".");
      const updatedPermissions = { ...prevPermissions };
      let currentLevel = updatedPermissions;

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (i === keys.length - 1) {
          currentLevel[key] = {
            ...currentLevel[key],
            has: !currentLevel[key].has,
          };
        } else {
          currentLevel[key] = { ...currentLevel[key] };
          currentLevel = currentLevel[key];
        }
      }

      return updatedPermissions;
    });
  };

  const renderPermissions = (permissionsObj, parentKey = "") => {
    return Object.entries(permissionsObj).map(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;

      if (value && typeof value.has === "boolean") {
        return (
          <FormControl key={currentKey} display="flex" alignItems="center" mb={2}>
            <Checkbox
              isChecked={value.has}
              onChange={() => handlePermissionChange(currentKey)}
            >
              {value.name}
            </Checkbox>
          </FormControl>
        );
      } else if (typeof value === "object") {
        return (
          <Box key={currentKey} pl={4} mb={4}>
            <Text fontWeight="bold">{key}</Text>
            {renderPermissions(value, currentKey)}
          </Box>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="2xl" fontWeight="bold">
            Employee Master
          </Text>
          <Button colorScheme="teal" onClick={onOpen}>
            Add Employee
          </Button>
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Stack spacing={4}>
            {Array.isArray(users) &&
              users.map((user) => (
                <Flex
                  key={user.id}
                  justifyContent="space-between"
                  alignItems="center"
                  p={4}
                  bg="gray.100"
                  borderRadius="md"
                >
                  <Box>
                    <Text fontWeight="bold">Email: {user.email}</Text>
                    <Text>Name: {user.name}</Text>
                    <Text>Role: {user.role}</Text>
                  </Box>
                  <Flex>
                    <Button colorScheme="blue" mr={2} onClick={() => handleEdit(user)}>
                      Edit
                    </Button>
                    <Button colorScheme="red" mr={2} onClick={() => handleDelete(user.id)}>
                      Delete
                    </Button>
                    {role=="SUPERADMIN"&&
                    <Button colorScheme="green" onClick={() => handleAddPermission(user.id)}>
                      Add Permission
                    </Button>
}
                  </Flex>
                </Flex>
              ))}
          </Stack>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{currentUser ? "Edit Employee" : "Add Employee"}</ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <FormControl isRequired mb={3}>
                  <FormLabel>Email</FormLabel>
                  <Input name="email" defaultValue={currentUser?.email || ""} />
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel>Name</FormLabel>
                  <Input name="name" defaultValue={currentUser?.name || ""} />
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" name="password" defaultValue={currentUser?.password || ""} />
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel>Role</FormLabel>
                  <Input name="role" defaultValue={currentUser?.role || ""} />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  {currentUser ? "Update" : "Submit"}
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        {/* Permission Modal */}
        <Modal isOpen={isPermissionOpen} onClose={onPermissionClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Manage Permissions</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {permissions ? renderPermissions(permissions) : <Text>Loading...</Text>}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSavePermissions}>
                Save
              </Button>
              <Button variant="ghost" onClick={onPermissionClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Alert
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>{alertContent}</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default UserMaster;
