// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Stack,
//   Text,
//   useToast,
//   FormControl,
//   FormLabel,
//   SimpleGrid,
//   Image,
//   AspectRatio,
//   Link,
// } from "@chakra-ui/react";
// import axios from 'axios';
// import apiInstance from 'api/api';
// import { useHistory } from "react-router-dom";

// const SamshekTeckPack = () => {
//   const [teckPackDetails, setTeckPackDetails] = useState({
//     pattern: {
//       file: null,
//       fileName: '',
//       filePreview: null,
//     },
//     scaled_file_print: {
//       file: null,
//       fileName: '',
//       filePreview: null,
//     },
//     raw_material: {
//       file: null,
//       fileName: '',
//       filePreview: null,
//     },
//   });
//  // const //toast = useToast();
//   const history=useHistory()
//   const handleFileChange = (e, section) => {
//     const { files } = e.target;
//     const file = files[0];
//     const filePreview = file ? URL.createObjectURL(file) : null;
//     setTeckPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [section]: {
//         file,
//         fileName: file.name,
//         filePreview,
//       },
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();

//     for (const section in teckPackDetails) {
//       formData.append(`${section}`, teckPackDetails[section].file);
//     }
//     const orderId=localStorage.getItem("orderId")||1;
//     try {
//       await apiInstance.post(`/orders/${orderId}/samshek-tech-pack`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       // //toast({ title: "Details submitted successfully.", status: "success" });
//       // Reset form after submission
//       setTeckPackDetails({
//         pattern: {
//           file: null,
//           fileName: '',
//           filePreview: null,
//         },
//         scaled_file_print: {
//           file: null,
//           fileName: '',
//           filePreview: null,
//         },
//         raw_material: {
//           file: null,
//           fileName: '',
//           filePreview: null,
//         },
//       });
//       history.push("/admin/create-order/progress-tab");
//     } catch (error) {
//       console.error("Error submitting details", error);
//       // //toast({ title: "Error submitting details.", status: "error" });
//     }
//   };

//   const renderAttachmentPreview = (filePreview, fileName) => {
//     if (!filePreview) return null;

//     const fileExtension = fileName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={filePreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={filePreview} isExternal mt={2}>
//           Open {fileName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported file type: {fileName}</Text>;
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "100px" }}>
//       <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Samshek Teck Pack
//       </Text>
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Pattern</FormLabel>
//             <Input
//               type="file"
//               name="file"
//               accept=".pdf, .jpg, .jpeg, .png"
//               onChange={(e) => handleFileChange(e, 'pattern')}
//             />
//             {renderAttachmentPreview(teckPackDetails.pattern.filePreview, teckPackDetails.pattern.fileName)}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Scaled File Print</FormLabel>
//             <Input
//               type="file"
//               name="file"
//               accept=".pdf, .jpg, .jpeg, .png"
//               onChange={(e) => handleFileChange(e, 'scaled_file_print')}
//             />
//             {renderAttachmentPreview(teckPackDetails.scaled_file_print.filePreview, teckPackDetails.scaled_file_print.fileName)}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Raw Material Req</FormLabel>
//             <Input
//               type="file"
//               name="file"
//               accept=".pdf, .jpg, .jpeg, .png"
//               onChange={(e) => handleFileChange(e, 'raw_material')}
//             />
//             {renderAttachmentPreview(teckPackDetails.raw_material.filePreview, teckPackDetails.raw_material.fileName)}
//           </FormControl>
//         </SimpleGrid>

//         <Box textAlign="center" mt={4}>
//           <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//             Submit
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default SamshekTeckPack;

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Input,
//   Text,
//   FormControl,
//   FormLabel,
//   SimpleGrid,
//   Image,
//   Link,
//   AlertDialog,
//   AlertDialogBody,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogContent,
//   AlertDialogOverlay,
// } from "@chakra-ui/react";
// import apiInstance from 'api/api';
// import { useHistory } from "react-router-dom";

// const SamshekTeckPack = () => {
//   const [teckPackDetails, setTeckPackDetails] = useState({
//     pattern: {
//       file: null,
//       fileName: '',
//       filePreview: null,
//     },
//     scaled_file_print: {
//       file: null,
//       fileName: '',
//       filePreview: null,
//     },
//     raw_material: {
//       file: null,
//       fileName: '',
//       filePreview: null,
//     },
//   });
//   const [isEditable, setIsEditable] = useState(true);
//   const [isAlertOpen, setIsAlertOpen] = useState(false);
//   const [alertMessage, setAlertMessage] = useState("");
//   const cancelRef = React.useRef();
//   const history = useHistory();

//   useEffect(() => {
//     const currentOrderId = localStorage.getItem("currentOrderId");
//     if (currentOrderId) {
//       fetchTeckPackDetails(currentOrderId);
//     }
//   }, []);

//   const fetchTeckPackDetails = async (orderId) => {
//     try {
//       const response = await apiInstance.get(`/orders/${orderId}/samshek-tech-pack`);
//       const teckPackData = response.data.data["samshek-tech-pack"];

//       const formattedTeckPackDetails = {
//         pattern: {
//           file: { url: teckPackData[0].url },
//           fileName: teckPackData[0].url.split("/").pop(),
//           filePreview: teckPackData[0].url,
//         },
//         scaled_file_print: {
//           file: { url: teckPackData[1].url },
//           fileName: teckPackData[1].url.split("/").pop(),
//           filePreview: teckPackData[1].url,
//         },
//         raw_material: {
//           file: { url: teckPackData[2].url },
//           fileName: teckPackData[2].url.split("/").pop(),
//           filePreview: teckPackData[2].url,
//         },
//       };

//       setTeckPackDetails(formattedTeckPackDetails);
//       setIsEditable(false);
//     } catch (error) {
//       console.error("Error fetching teck pack details", error);
//      // showAlert("Error fetching teck pack details.");
//     }
//   };

//   const handleFileChange = (e, section) => {
//     const { files } = e.target;
//     const file = files[0];
//     const filePreview = file ? URL.createObjectURL(file) : null;
//     setTeckPackDetails((prevDetails) => ({
//       ...prevDetails,
//       [section]: {
//         file,
//         fileName: file.name,
//         filePreview,
//       },
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     const orderId = localStorage.getItem("currentOrderId") || 1;

//     for (const section in teckPackDetails) {
//       formData.append(`${section}`, teckPackDetails[section].file);
//     }

//     try {
//       await apiInstance.post(`/orders/${orderId}/samshek-tech-pack`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       showAlert("Details submitted successfully.");
//       // Reset form after submission
//       setTeckPackDetails({
//         pattern: {
//           file: null,
//           fileName: '',
//           filePreview: null,
//         },
//         scaled_file_print: {
//           file: null,
//           fileName: '',
//           filePreview: null,
//         },
//         raw_material: {
//           file: null,
//           fileName: '',
//           filePreview: null,
//         },
//       });
//       history.push("/admin/create-order/progress-tab");
//     } catch (error) {
//       console.error("Error submitting details", error);
//       showAlert("Error submitting details.");
//     }
//   };

//   const showAlert = (message) => {
//     setAlertMessage(message);
//     setIsAlertOpen(true);
//   };

//   const renderAttachmentPreview = (filePreview, fileName) => {
//     if (!filePreview) return null;

//     const fileExtension = fileName.split(".").pop().toLowerCase();
//     if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
//       return <Image src={filePreview} alt="Attachment Preview" mt={2} />;
//     } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
//       return (
//         <Link href={filePreview} isExternal mt={2}>
//           Open {fileName}
//         </Link>
//       );
//     } else {
//       return <Text mt={2}>Unsupported file type: {fileName}</Text>;
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "80px" }}>
//       {/* <Text fontSize="2xl" fontWeight="bold" mb={4}>
//         Samshek Teck Pack
//       </Text> */}
//       <form onSubmit={handleSubmit}>
//         <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
//           <FormControl isRequired>
//             <FormLabel>Pattern</FormLabel>
//             <Input
//               type="file"
//               name="file"
//               accept=".pdf, .jpg, .jpeg, .png"
//               onChange={(e) => handleFileChange(e, 'pattern')}
//               isDisabled={!isEditable}
//             />
//             {renderAttachmentPreview(teckPackDetails.pattern.filePreview, teckPackDetails.pattern.fileName)}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Scaled File Print</FormLabel>
//             <Input
//               type="file"
//               name="file"
//               accept=".pdf, .jpg, .jpeg, .png"
//               onChange={(e) => handleFileChange(e, 'scaled_file_print')}
//               isDisabled={!isEditable}
//             />
//             {renderAttachmentPreview(teckPackDetails.scaled_file_print.filePreview, teckPackDetails.scaled_file_print.fileName)}
//           </FormControl>

//           <FormControl isRequired>
//             <FormLabel>Raw Material Req</FormLabel>
//             <Input
//               type="file"
//               name="file"
//               accept=".pdf, .jpg, .jpeg, .png"
//               onChange={(e) => handleFileChange(e, 'raw_material')}
//               isDisabled={!isEditable}
//             />
//             {renderAttachmentPreview(teckPackDetails.raw_material.filePreview, teckPackDetails.raw_material.fileName)}
//           </FormControl>
//         </SimpleGrid>

//         {isEditable && (
//           <Box textAlign="center" mt={4}>
//             <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
//               Submit
//             </Button>
//           </Box>
//         )}
//       </form>

//       <AlertDialog
//         isOpen={isAlertOpen}
//         leastDestructiveRef={cancelRef}
//         onClose={() => setIsAlertOpen(false)}
//       >
//         <AlertDialogOverlay>
//           <AlertDialogContent>
//             <AlertDialogHeader fontSize="lg" fontWeight="bold">
//               Alert
//             </AlertDialogHeader>
//             <AlertDialogBody>
//               {alertMessage}
//             </AlertDialogBody>
//             <AlertDialogFooter>
//               <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
//                 OK
//               </Button>
//             </AlertDialogFooter>
//           </AlertDialogContent>
//         </AlertDialogOverlay>
//       </AlertDialog>
//     </Box>
//   );
// };

// export default SamshekTeckPack;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Text,
  FormControl,
  FormLabel,
  SimpleGrid,
  Image,
  Link,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import apiInstance from 'api/api';
import { useHistory } from "react-router-dom";

const SamshekTeckPack = () => {
  const [teckPackDetails, setTeckPackDetails] = useState({
    pattern: { file: null, fileName: '', filePreview: null },
    scaled_file_print: { file: null, fileName: '', filePreview: null },
    raw_material: { file: null, fileName: '', filePreview: null },
  });
  const [permissions, setPermissions] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const cancelRef = React.useRef();
  const history = useHistory();

  // Fetch permissions from localStorage
  useEffect(() => {
    const storedPermissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    setPermissions(storedPermissions);
    console.log("permissions", storedPermissions);
  }, []);

  useEffect(() => {
    const currentOrderId = localStorage.getItem("currentOrderId");
    if (currentOrderId) {
      fetchTeckPackDetails(currentOrderId);
    }
  }, []);

  const fetchTeckPackDetails = async (orderId) => {
    try {
      const response = await apiInstance.get(`/orders/${orderId}/samshek-tech-pack`);
      const teckPackData = response.data.data["samshek-tech-pack"];

      const formattedTeckPackDetails = {
        pattern: {
          file: { url: teckPackData[0].url },
          fileName: teckPackData[0].url.split("/").pop(),
          filePreview: teckPackData[0].url,
        },
        scaled_file_print: {
          file: { url: teckPackData[1].url },
          fileName: teckPackData[1].url.split("/").pop(),
          filePreview: teckPackData[1].url,
        },
        raw_material: {
          file: { url: teckPackData[2].url },
          fileName: teckPackData[2].url.split("/").pop(),
          filePreview: teckPackData[2].url,
        },
      };

      setTeckPackDetails(formattedTeckPackDetails);
    } catch (error) {
      console.error("Error fetching teck pack details", error);
    }
  };

  const handleFileChange = (e, section) => {
    const { files } = e.target;
    const file = files[0];
    const filePreview = file ? URL.createObjectURL(file) : null;
    setTeckPackDetails((prevDetails) => ({
      ...prevDetails,
      [section]: {
        file,
        fileName: file.name,
        filePreview,
      },
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   const orderId = localStorage.getItem("currentOrderId") || 1;

  //   for (const section in teckPackDetails) {
  //     if (teckPackDetails[section].file) {
  //       formData.append(`${section}`, teckPackDetails[section].file);
  //     }
  //   }

  //   try {
  //     await apiInstance.post(`/orders/${orderId}/samshek-tech-pack`, formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //     showAlert("Details submitted successfully.");
  //     setTeckPackDetails({
  //       pattern: { file: null, fileName: '', filePreview: null },
  //       scaled_file_print: { file: null, fileName: '', filePreview: null },
  //       raw_material: { file: null, fileName: '', filePreview: null },
  //     });
  //     history.push("/admin/create-order/progress-tab");
  //   } catch (error) {
  //     console.error("Error submitting details", error);
  //     showAlert("Error submitting details.");
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const orderId = localStorage.getItem("currentOrderId") || 1;

    // Check each section's edit permission and ensure valid file data is appended
    if (permissions.includes("orders.samshek-tech-pack.pattern.edit") && teckPackDetails.pattern.file instanceof File) {
        formData.append("pattern", teckPackDetails.pattern.file);
    }
    if (permissions.includes("orders.samshek-tech-pack.scaled-file-print.edit") && teckPackDetails.scaled_file_print.file instanceof File) {
        formData.append("scaled_file_print", teckPackDetails.scaled_file_print.file);
    }
    if (permissions.includes("orders.samshek-tech-pack.raw-material-requirement.edit") && teckPackDetails.raw_material.file instanceof File) {
        formData.append("raw_material", teckPackDetails.raw_material.file);
    }

    try {
        // Check if at least one file is included in the submission
        if (formData.has("pattern") || formData.has("scaled_file_print") || formData.has("raw_material")) {
            await apiInstance.post(`/orders/${orderId}/samshek-tech-pack`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            showAlert("Details submitted successfully.");
            history.push("/admin/create-order/progress-tab");
        } else {
            showAlert("No changes to submit.");
        }
    } catch (error) {
        console.error("Error submitting details", error);
        showAlert("Error submitting details.");
    }
};


  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertOpen(true);
  };

  const renderAttachmentPreview = (filePreview, fileName) => {
    if (!filePreview) return null;

    const fileExtension = fileName.split(".").pop().toLowerCase();
    const styles = {
      width: '100px',
      height: '100px',
      objectFit: 'cover',
      marginTop: '8px',
    };

    if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <Image src={filePreview} alt="Attachment Preview" style={styles} />;
    } else if (["pdf", "xls", "xlsx", "csv"].includes(fileExtension)) {
      return (
        <Link href={filePreview} isExternal style={{ marginTop: '8px', display: 'block' }}>
          Open {fileName}
        </Link>
      );
    } else {
      return <Text mt={2}>Unsupported file type: {fileName}</Text>;
    }
  };

  // Determine if any section is editable to control submit button visibility
  const isAnySectionEditable = 
    permissions.includes("orders.samshek-tech-pack.pattern.edit") ||
    permissions.includes("orders.samshek-tech-pack.scaled-file-print.edit") ||
    permissions.includes("orders.samshek-tech-pack.raw-material-requirement.edit");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} p={4} mt={{ base: "190px", md: "100px", xl: "80px" }}>
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>

          {/* Pattern Section */}
          {permissions.includes("orders.samshek-tech-pack.pattern.view") && (
            <FormControl isRequired>
              <FormLabel>Pattern</FormLabel>
              {permissions.includes("orders.samshek-tech-pack.pattern.edit") ? (
                <Input
                  type="file"
                  name="file"
                  accept=".pdf, .jpg, .jpeg, .png"
                  onChange={(e) => handleFileChange(e, 'pattern')}
                />
              ) : (
                renderAttachmentPreview(teckPackDetails.pattern.filePreview, teckPackDetails.pattern.fileName)
              )}
            </FormControl>
          )}

          {/* Scaled File Print Section */}
          {permissions.includes("orders.samshek-tech-pack.scaled-file-print.view") && (
            <FormControl isRequired>
              <FormLabel>Scaled File Print</FormLabel>
              {permissions.includes("orders.samshek-tech-pack.scaled-file-print.edit") ? (
                <Input
                  type="file"
                  name="file"
                  accept=".pdf, .jpg, .jpeg, .png"
                  onChange={(e) => handleFileChange(e, 'scaled_file_print')}
                />
              ) : (
                renderAttachmentPreview(teckPackDetails.scaled_file_print.filePreview, teckPackDetails.scaled_file_print.fileName)
              )}
            </FormControl>
          )}

          {/* Raw Material Section */}
          {permissions.includes("orders.samshek-tech-pack.raw-material-requirement.view") && (
            <FormControl isRequired>
              <FormLabel>Raw Material Req</FormLabel>
              {permissions.includes("orders.samshek-tech-pack.raw-material-requirement.edit") ? (
                <Input
                  type="file"
                  name="file"
                  accept=".pdf, .jpg, .jpeg, .png"
                  onChange={(e) => handleFileChange(e, 'raw_material')}
                />
              ) : (
                renderAttachmentPreview(teckPackDetails.raw_material.filePreview, teckPackDetails.raw_material.fileName)
              )}
            </FormControl>
          )}
        </SimpleGrid>

        {/* Show submit button only if at least one section is editable */}
        {isAnySectionEditable && (
          <Box textAlign="center" mt={4}>
            <Button colorScheme="teal" type="submit" width={{ base: "100%", md: "auto" }}>
              Submit
            </Button>
          </Box>
        )}
      </form>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">Alert</AlertDialogHeader>
            <AlertDialogBody>{alertMessage}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>OK</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default SamshekTeckPack;
