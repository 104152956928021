// import React from 'react';
// import ReactDOM from 'react-dom';
// import 'assets/css/App.css';
// import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import AuthLayout from 'layouts/auth';
// import AdminLayout from 'layouts/admin';
// import RtlLayout from 'layouts/rtl';
// import { ChakraProvider } from '@chakra-ui/react';
// import theme from 'theme/theme';
// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';

// ReactDOM.render(
// 	<ChakraProvider theme={theme}>
// 		<React.StrictMode>
// 			<ThemeEditorProvider>
// 				<HashRouter>
// 					<Switch>
// 						<Route path={`/auth`} component={AuthLayout} />
// 						<Route path={`/admin`} component={AdminLayout} />
// 						<Route path={`/rtl`} component={RtlLayout} />
// 						<Redirect from='/' to='/admin' />
// 					</Switch>
// 				</HashRouter>
// 			</ThemeEditorProvider>
// 		</React.StrictMode>
// 	</ChakraProvider>,
// 	document.getElementById('root')
// );

// import React from 'react';
// import ReactDOM from 'react-dom';
// import 'assets/css/App.css';
// import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import AuthLayout from 'layouts/auth';
// import AdminLayout from 'layouts/admin';
// import RtlLayout from 'layouts/rtl';
// import { ChakraProvider, extendTheme } from '@chakra-ui/react';
// //import theme from 'theme/theme';
// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
// import ErrorBoundary from 'views/pages/ErrorBoundary';
// import { breakpoints } from 'theme/foundations/breakpoints';

// const theme = extendTheme({
//   breakpoints,
//   // other customizations...
// });
// ReactDOM.render(
// //  <ChakraProvider></ChakraProvider>
//    <ChakraProvider theme={theme}> 
  
//     <React.StrictMode>
//       <ErrorBoundary>
//         <ThemeEditorProvider>
//           <HashRouter>
//             <Switch>
//               <Route path={`/auth`} component={AuthLayout} />
//               <Route path={`/admin`} component={AdminLayout} />
//               <Route path={`/rtl`} component={RtlLayout} />
//               {/* <Redirect from='/' to='/admin/default' /> */}
//               <Redirect from="/" to='/auth'/>
//             </Switch>
//           </HashRouter>
//         </ThemeEditorProvider>
//       </ErrorBoundary>
//     </React.StrictMode>
//   </ChakraProvider>,
//   document.getElementById('root')
// );

// import React from 'react';
// import ReactDOM from 'react-dom';
// import 'assets/css/App.css';
// import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import AuthLayout from 'layouts/auth';
// import AdminLayout from 'layouts/admin';
// import RtlLayout from 'layouts/rtl';
// import { ChakraProvider, extendTheme } from '@chakra-ui/react';
// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
// import ErrorBoundary from 'views/pages/ErrorBoundary';
// import { breakpoints } from 'theme/foundations/breakpoints';
// import PrivateRoute from 'components/PrivateRoute';

// const theme = extendTheme({
//   breakpoints,
//   // other customizations...
// });

// ReactDOM.render(
//   <ChakraProvider theme={theme}>
//     <React.StrictMode>
//       <ErrorBoundary>
//         <ThemeEditorProvider>
//           <HashRouter>
//             <Switch>
//               <Route path="/auth" component={AuthLayout} />
//               <PrivateRoute path="/admin" component={AdminLayout} />
//               <PrivateRoute path="/rtl" component={RtlLayout} />
//               <Redirect from="/" to="/auth/sign-in" />
//             </Switch>
//           </HashRouter>
//         </ThemeEditorProvider>
//       </ErrorBoundary>
//     </React.StrictMode>
//   </ChakraProvider>,
//   document.getElementById('root')
// );

import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import ErrorBoundary from 'views/pages/ErrorBoundary';
import { breakpoints } from 'theme/foundations/breakpoints';
import PrivateRoute from 'components/PrivateRoute';
import AccessDeniedDialog from 'views/pages/AccessDeniedDialog'; // Import the dialog component

const theme = extendTheme({
  breakpoints,
  // other customizations...
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ErrorBoundary>
        <ThemeEditorProvider>
          <HashRouter>
            <AccessDeniedDialog /> {/* Include AccessDeniedDialog here */}
            <Switch>
              <Route path="/auth" component={AuthLayout} />
              <PrivateRoute path="/admin" component={AdminLayout} />
              <PrivateRoute path="/rtl" component={RtlLayout} />
              <Redirect from="/" to="/auth/sign-in" />
            </Switch>
          </HashRouter>
        </ThemeEditorProvider>
      </ErrorBoundary>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);
