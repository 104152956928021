

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Flex,
//   Input,
//   Stack,
//   Text,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   FormControl,
//   FormLabel,
//   useToast,
// } from "@chakra-ui/react";
// import apiInstance from "api/api";

// const ItemMaster = () => {
//   const [items, setItems] = useState([]);
//   const [currentItem, setCurrentItem] = useState(null);
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   //const //toast = useToast();

//   useEffect(() => {
//     fetchItems();
//   }, []);

//   const fetchItems = async () => {
//     try {
//       const response = await apiInstance.get("/items");
//       const items = response.data.data.items || [];
//       setItems(items);
//       console.log("response", items);
//     } catch (error) {
//       console.error("Error fetching items", error);
//       //toast({ title: "Error fetching items.", status: "error" });
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const { name, sku } = e.target.elements;
//     const newItem = {
//       name: name.value,
//       sku: sku.value,
//     };

//     try {
//       if (currentItem) {
//         await apiInstance.put(`/items/${currentItem.id}`, newItem);
//         //toast({ title: "Item updated.", status: "success" });
//       } else {
//         await apiInstance.post("/items", newItem);
//         //toast({ title: "Item added.", status: "success" });
//       }
//       fetchItems();
//       setCurrentItem(null);
//       onClose();
//     } catch (error) {
//       console.error("Error saving item", error);
//       //toast({ title: "Error saving item.", status: "error" });
//     }
//   };

//   const handleEdit = (item) => {
//     setCurrentItem(item);
//     onOpen();
//   };

//   const handleDelete = async (id) => {
//     try {
//       await apiInstance.delete(`/items/${id}`);
//       //toast({ title: "Item deleted.", status: "success" });
//       fetchItems();
//     } catch (error) {
//       console.error("Error deleting item", error);
//       //toast({ title: "Error deleting item.", status: "error" });
//     }
//   };

//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <Box p={4}>
//         <Flex justifyContent="space-between" alignItems="center" mb={4}>
//           <Text fontSize="2xl" fontWeight="bold">
//             Item Master
//           </Text>
//           <Button colorScheme="teal" onClick={onOpen}>
//             Add Item
//           </Button>
//         </Flex>

//         <Stack spacing={4}>
//           {Array.isArray(items) && items.map((item) => (
//             <Flex
//               key={item.id}
//               justifyContent="space-between"
//               alignItems="center"
//               p={4}
//               bg="gray.100"
//               borderRadius="md"
//             >
//               <Box>
//                 <Text fontWeight="bold">Itemname: {item.name}</Text>
//                 <Text>SKU Code: {item.sku}</Text>
//               </Box>
//               <Flex>
//                 <Button colorScheme="blue" mr={2} onClick={() => handleEdit(item)}>
//                   Edit
//                 </Button>
//                 <Button colorScheme="red" onClick={() => handleDelete(item.id)}>
//                   Delete
//                 </Button>
//               </Flex>
//             </Flex>
//           ))}
//         </Stack>

//         <Modal isOpen={isOpen} onClose={onClose}>
//           <ModalOverlay />
//           <ModalContent>
//             <ModalHeader>{currentItem ? "Edit Item" : "Add Item"}</ModalHeader>
//             <ModalCloseButton />
//             <form onSubmit={handleSubmit}>
//               <ModalBody>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>Item Name</FormLabel>
//                   <Input name="name" defaultValue={currentItem?.name || ""} />
//                 </FormControl>
//                 <FormControl isRequired mb={3}>
//                   <FormLabel>SKU Code</FormLabel>
//                   <Input name="sku" defaultValue={currentItem?.sku || ""} />
//                 </FormControl>
//               </ModalBody>
//               <ModalFooter>
//                 <Button colorScheme="blue" mr={3} type="submit">
//                   {currentItem ? "Update" : "Submit"}
//                 </Button>
//                 <Button variant="ghost" onClick={onClose}>
//                   Cancel
//                 </Button>
//               </ModalFooter>
//             </form>
//           </ModalContent>
//         </Modal>
//       </Box>
//     </Box>
//   );
// };

// export default ItemMaster;


import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Spinner,
} from "@chakra-ui/react";
import apiInstance from "api/api";

const ItemMaster = () => {
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const [alertContent, setAlertContent] = useState("");
  const cancelRef = React.useRef();

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await apiInstance.get("/items");
      const items = response.data.data.items || [];
      setItems(items);
      console.log("response", items);
    } catch (error) {
      console.error("Error fetching items", error);
      showAlert("Error fetching items.");
    } finally {
      setIsLoading(false);
    }
  };

  const showAlert = (content) => {
    setAlertContent(content);
    onAlertOpen();
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      const file = files[0];
      setCurrentItem((prevItem) => ({
        ...prevItem,
        image: file,
      }));
      setImagePreview(URL.createObjectURL(file));
    } else {
      setCurrentItem((prevItem) => ({
        ...prevItem,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", currentItem.name);
    formData.append("sku", currentItem.sku);
    formData.append("description", currentItem.description);
    if (currentItem.image) {
      formData.append("image", currentItem.image);
    }

    try {
      if (currentItem.id) {
       const response=await apiInstance.put(`/items/${currentItem.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        showAlert(response.data.data.message);
      } else {
       const response= await apiInstance.post("/items", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        showAlert(response.data.message);
      }
      fetchItems();
      setCurrentItem(null);
      setImagePreview(null);
      onClose();
    } catch (error) {
      console.error("Error saving item", error,);
      showAlert(error.message);
    }
  };

  const handleAdd = () => {
    setCurrentItem(null);
    setImagePreview(null);
    onOpen();
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    if (item.image && item.image.length > 0) {
      setImagePreview(item.image[0].url);
    } else {
      setImagePreview(null);
    }
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      await apiInstance.delete(`/items/${id}`);
      showAlert("Item deleted successfully.");
      fetchItems();
    } catch (error) {
      console.error("Error deleting item", error);
      showAlert("Error deleting item.");
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="2xl" fontWeight="bold">
            Item Master
          </Text>
          <Button colorScheme="teal" onClick={handleAdd}>
            Add Item
          </Button>
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Stack spacing={4}>
            {Array.isArray(items) &&
              items.map((item) => (
                <Flex
                  key={item.id}
                  justifyContent="space-between"
                  alignItems="center"
                  p={4}
                  bg="gray.100"
                  borderRadius="md"
                >
                  <Box>
                    <Text fontWeight="bold">Item Name: {item.name}</Text>
                    <Text>SKU Code: {item.sku}</Text>
                    <Text>Description: {item.description}</Text>
                    {item.image && item.image.length > 0 && (
                      <Image
                        src={item.image[0].url}
                        alt="Item Image"
                        mt={2}
                        boxSize="100px"
                        objectFit="cover"
                      />
                    )}
                  </Box>
                  <Flex>
                    <Button
                      colorScheme="blue"
                      mr={2}
                      onClick={() => handleEdit(item)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </Button>
                  </Flex>
                </Flex>
              ))}
          </Stack>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {currentItem && currentItem.id ? "Edit Item" : "Add Item"}
            </ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <FormControl isRequired mb={3}>
                  <FormLabel>Item Name</FormLabel>
                  <Input
                    name="name"
                    value={currentItem?.name || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel>SKU Code</FormLabel>
                  <Input
                    name="sku"
                    value={currentItem?.sku || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl mb={3}>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    name="description"
                    value={currentItem?.description || ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl mb={3}>
                  <FormLabel>Image</FormLabel>
                  <Input
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                    required
                  />
                  {imagePreview && (
                    <Image
                      src={imagePreview}
                      alt="Image Preview"
                      mt={2}
                      boxSize="100px"
                      objectFit="cover"
                    />
                  )}
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  {currentItem && currentItem.id ? "Update" : "Submit"}
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Alert
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>{alertContent}</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default ItemMaster;
