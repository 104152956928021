// import React from 'react';
// import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
// import { Link, useLocation } from 'react-router-dom';

// const SecondaryNavbar = () => {
//   const location = useLocation();

//   if (!location.pathname.startsWith('/admin/create-order')) {
//     return null;
//   }

//   return (
//     <Tabs variant="enclosed" marginTop="4">
//       <TabList>
//         <Tab as={Link} to="/admin/create-order/basic-details">Basic Details</Tab>
//         <Tab as={Link} to="/admin/create-order/customer-tech-pack">Customer Tech Pack</Tab>
//         <Tab as={Link} to="/admin/create-order/samshek-teck-pack">Samshek Tech Pack</Tab>
//         <Tab as={Link} to="/admin/create-order/progress-tab">Progress Tab</Tab>
//         <Tab as={Link} to="/admin/create-order/delivery-tab">Delivery Tab</Tab>
//       </TabList>
//       <TabPanels>
//         <TabPanel>
//           <p>Basic Details Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Customer Tech Pack Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Samshek Tech Pack Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Progress Tab</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Delivery Tab</p>
//         </TabPanel>
//       </TabPanels>
//     </Tabs>
//   );
// };

// export default SecondaryNavbar;

// import React, { useEffect, useState } from 'react';
// import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
// import { Link, useLocation } from 'react-router-dom';

// const SecondaryNavbar = () => {
//   const location = useLocation();
//   const [activeTabIndex, setActiveTabIndex] = useState(0);

//   useEffect(() => {
//     switch (location.pathname) {
//       case '/admin/create-order/basic-details':
//         setActiveTabIndex(0);
//         break;
//       case '/admin/create-order/customer-tech-pack':
//         setActiveTabIndex(1);
//         break;
//       case '/admin/create-order/samshek-teck-pack':
//         setActiveTabIndex(2);
//         break;
//       case '/admin/create-order/progress-tab':
//         setActiveTabIndex(3);
//         break;
//       case '/admin/create-order/delivery-tab':
//         setActiveTabIndex(4);
//         break;
//       default:
//         setActiveTabIndex(0);
//     }
//   }, [location.pathname]);

//   if (!location.pathname.startsWith('/admin/create-order')) {
//     return null;
//   }

//   return (
//     <Tabs variant="enclosed" marginTop="4" index={activeTabIndex} onChange={index => setActiveTabIndex(index)}>
//       <TabList>
//         <Tab as={Link} to="/admin/create-order/basic-details">Basic Details</Tab>
//         <Tab as={Link} to="/admin/create-order/customer-tech-pack">Customer Tech Pack</Tab>
//         <Tab as={Link} to="/admin/create-order/samshek-teck-pack">Samshek Tech Pack</Tab>
//         <Tab as={Link} to="/admin/create-order/progress-tab">Progress Tab</Tab>
//         <Tab as={Link} to="/admin/create-order/delivery-tab">Delivery Tab</Tab>
//       </TabList>
//       <TabPanels>
//         <TabPanel>
//           <p>Basic Details Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Customer Tech Pack Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Samshek Tech Pack Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Progress Tab</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Delivery Tab</p>
//         </TabPanel>
//       </TabPanels>
//     </Tabs>
//   );
// };


// export default SecondaryNavbar;


// import React, { useEffect, useState } from 'react';
// import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
// import { Link, useLocation } from 'react-router-dom';

// const SecondaryNavbar = () => {
//   const location = useLocation();
//   const [activeTabIndex, setActiveTabIndex] = useState(0);

//   useEffect(() => {
//     const pathToTabIndex = {
//       '/admin/create-order/basic-details': 0,
//       '/admin/create-order/customer-tech-pack': 1,
//       '/admin/create-order/samshek-teck-pack': 2,
//       '/admin/create-order/progress-tab': 3,
//       '/admin/create-order/delivery-tab': 4,
//     };
//     const currentPath = location.pathname;
//     const newIndex = pathToTabIndex[currentPath] !== undefined ? pathToTabIndex[currentPath] : 0;
//     setActiveTabIndex(newIndex);
//   }, [location.pathname]);

//   if (!location.pathname.startsWith('/admin/create-order')) {
//     return null;
//   }

//   return (
//     <Tabs variant="enclosed" marginTop="4" index={activeTabIndex} onChange={index => setActiveTabIndex(index)}>
//       <TabList>
//         <Tab as={Link} to="/admin/create-order/basic-details">Basic Details</Tab>
//         <Tab as={Link} to="/admin/create-order/customer-tech-pack">Customer Tech Pack</Tab>
//         <Tab as={Link} to="/admin/create-order/samshek-teck-pack">Samshek Tech Pack</Tab>
//         <Tab as={Link} to="/admin/create-order/progress-tab">Progress Tab</Tab>
//         <Tab as={Link} to="/admin/create-order/delivery-tab">Delivery Tab</Tab>
//       </TabList>
//       {/* <TabPanels>
//         <TabPanel>
//           <p>Basic Details Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Customer Tech Pack Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Samshek Tech Pack Content</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Progress Tab</p>
//         </TabPanel>
//         <TabPanel>
//           <p>Delivery Tab</p>
//         </TabPanel>
//       </TabPanels> */}
//     </Tabs>
//   );
// };

// export default SecondaryNavbar;


import React, { useEffect, useState } from 'react';
import { Tabs, TabList, Tab } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

const SecondaryNavbar = () => {
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Retrieve permissions from local storage
  const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");

  // Define tabs with their corresponding permissions
  const tabs = [
    {
      name: "Basic Details",
      path: "/admin/create-order/basic-details",
      permission: "orders.basic-details.view"
    },
    {
      name: "Customer Tech Pack",
      path: "/admin/create-order/customer-tech-pack",
      permission: "orders.customer-tech-pack.index.view"
    },
    {
      name: "Samshek Tech Pack",
      path: "/admin/create-order/samshek-teck-pack",
      permission: "orders.samshek-tech-pack.index.view"
    },
    {
      name: "Progress Tab",
      path: "/admin/create-order/progress-tab",
      permission: "orders.progress-tab.view"
    },
    {
      name: "Delivery Tab",
      path: "/admin/create-order/delivery-tab",
      permission: "orders.delivery-tab.view"
    }
  ];

  // Filter tabs based on user permissions
  const visibleTabs = tabs.filter(tab => permissions.includes(tab.permission));

  useEffect(() => {
    const pathToTabIndex = visibleTabs.reduce((acc, tab, index) => {
      acc[tab.path] = index;
      return acc;
    }, {});

    const currentPath = location.pathname;
    const newIndex = pathToTabIndex[currentPath] !== undefined ? pathToTabIndex[currentPath] : 0;
    setActiveTabIndex(newIndex);
  }, [location.pathname, visibleTabs]);

  if (!location.pathname.startsWith('/admin/create-order')) {
    return null;
  }

  return (
    <Tabs variant="enclosed" marginTop="4" index={activeTabIndex} onChange={index => setActiveTabIndex(index)}>
      <TabList>
        {visibleTabs.map((tab, index) => (
          <Tab key={index} as={Link} to={tab.path}>
            {tab.name}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export default SecondaryNavbar;
