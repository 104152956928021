// import React from "react";

// // chakra imports
// import {
//   Box,
//   Flex,
//   Drawer,
//   DrawerBody,
//   Icon,
//   useColorModeValue,
//   DrawerOverlay,
//   useDisclosure,
//   DrawerContent,
//   DrawerCloseButton,
// } from "@chakra-ui/react";
// import Content from "components/sidebar/components/Content";
// import {
//   renderThumb,
//   renderTrack,
//   renderView,
// } from "components/scrollbar/Scrollbar";
// import { Scrollbars } from "react-custom-scrollbars-2";
// import PropTypes from "prop-types";

// // Assets
// import { IoMenuOutline } from "react-icons/io5";

// function Sidebar(props) {
//   const { routes } = props;

//   let variantChange = "0.2s linear";
//   let shadow = useColorModeValue(
//     "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
//     "unset"
//   );
//   // Chakra Color Mode
//   let sidebarBg = useColorModeValue("white", "navy.800");
//   let sidebarMargins = "0px";

//   // SIDEBAR
//   return (
//     <Box display={{ sm: "none", xl: "block" }} w="100%" position='fixed' minH='100%'>
//       <Box
//         bg={sidebarBg}
//         transition={variantChange}
//         w='300px'
//         h='100vh'
//         m={sidebarMargins}
//         minH='100%'
//         overflowX='hidden'
//         boxShadow={shadow}>
          
//         <Scrollbars
//           autoHide
//           renderTrackVertical={renderTrack}
//           renderThumbVertical={renderThumb}
//           renderView={renderView}>
//           <Content routes={routes} />
//         </Scrollbars>
//       </Box>
//     </Box>
//   );
// }

// // FUNCTIONS
// export function SidebarResponsive(props) {
//   let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
//   let menuColor = useColorModeValue("gray.400", "white");
//   // // SIDEBAR
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const btnRef = React.useRef();

//   const { routes } = props;
//   // let isWindows = navigator.platform.startsWith("Win");
//   //  BRAND

//   return (
//     <Flex display={{ sm: "flex", xl: "none" }} alignItems='center'>
//       <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
//         <Icon
//           as={IoMenuOutline}
//           color={menuColor}
//           my='auto'
//           w='20px'
//           h='20px'
//           me='10px'
//           _hover={{ cursor: "pointer" }}
//         />
//       </Flex>
//       <Drawer
//         isOpen={isOpen}
//         onClose={onClose}
//         placement={document.documentElement.dir === "rtl" ? "right" : "left"}
//         finalFocusRef={btnRef}>
//         <DrawerOverlay />
//         <DrawerContent w='285px' maxW='285px' bg={sidebarBackgroundColor}>
//           <DrawerCloseButton
//             zIndex='3'
//             onClose={onClose}
//             _focus={{ boxShadow: "none" }}
//             _hover={{ boxShadow: "none" }}
//           />
//           <DrawerBody maxW='285px' px='0rem' pb='0'>
//             <Scrollbars
//               autoHide
//               renderTrackVertical={renderTrack}
//               renderThumbVertical={renderThumb}
//               renderView={renderView}>
//               <Content routes={routes} />
//             </Scrollbars>
//           </DrawerBody>
//         </DrawerContent>
//       </Drawer>
//     </Flex>
//   );
// }
// // PROPS

// Sidebar.propTypes = {
//   logoText: PropTypes.string,
//   routes: PropTypes.arrayOf(PropTypes.object),
//   variant: PropTypes.string,
// };

// export default Sidebar;

import React from "react";
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { IoMenuOutline } from "react-icons/io5";

// Define the paths that should be excluded from the sidebar
const excludedPaths = [
  "/auth/sign-in",
  "/admin/create-order/basic-details",
  "/admin/create-order/customer-tech-pack",
  "/admin/create-order/samshek-teck-pack",
  "/admin/create-order/progress-tab",
  "/admin/create-order/delivery-tab"
];

function Sidebar(props) {
  const { routes } = props;

  // Add a "Create" route for Basic Details
  // const createRoute = {
  //   name: "Create Order",
  //   layout: "/admin",
  //   path: "/create-order/basic-details",
  //   icon: <Icon as={IoMenuOutline} width="20px" height="20px" color="inherit" />,
  // };

  // Filter out excluded paths
  const filteredRoutes = routes.filter(route => {
    return !excludedPaths.includes(route.layout + route.path);
  });

  // Add "Create" route after "Masters"
  // const newRoutes = [];
  const newRoutes = filteredRoutes.filter(route => route.path !== "/create-order/basic-details");
  // filteredRoutes.forEach(route => {
  //   newRoutes.push(route);
  //   if (route.name === "Masters") {
  //     newRoutes.push(createRoute);
  //   }
  // });

  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );

  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  return (
    <Box display={{ sm: "none", xl: "block" }} w="100%" position='fixed' minH='100%'>
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w='300px'
        h='100vh'
        m={sidebarMargins}
        minH='100%'
        overflowX='hidden'
        boxShadow={shadow}>
          
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}>
          <Content routes={newRoutes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;

export function SidebarResponsive(props) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { routes } = props;

  // Add a "Create" route for Basic Details
  // const createRoute = {
  //   name: "Create",
  //   layout: "/admin",
  //   path: "/create-order/basic-details",
  //   icon: <Icon as={IoMenuOutline} width="20px" height="20px" color="inherit" />,
  // };

  // Filter out excluded paths
  const filteredRoutes = routes.filter(route => {
    return !excludedPaths.includes(route.layout + route.path);
  });

  const newRoutes = filteredRoutes.filter(route => route.path !== "/create-order/basic-details");

  // Add "Create" route after "Masters"
  // const newRoutes = [];
  // filteredRoutes.forEach(route => {
  //   newRoutes.push(route);
  //   if (route.name === "Masters") {
  //     newRoutes.push(createRoute);
  //   }
  // });

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems='center'>
      <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent w='285px' maxW='285px' bg={sidebarBackgroundColor}>
          <DrawerCloseButton
            zIndex='3'
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW='285px' px='0rem' pb='0'>
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}>
              <Content routes={newRoutes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

SidebarResponsive.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};
